import React, { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { helperInfoIcon, convertBoolToYesNo } from '../../../../_helper';
import * as APIs from '../../../../Data/APIs';
import { useLoader } from '../../../Layout/Loader';

const CarrierTabsCarrierRequirements = ({ carrierDetails, ...props }) => {
    const { Requirements } = carrierDetails || {}
    const { openLoader, closeLoader } = useLoader();
    const [individualsGroupMinimum, setIndividualsGroupMinimum] = useState([]);

    useEffect(() => {
        openLoader();
        APIs.getCarrierIndividualsInGroupMinimum()
            .then(res => setIndividualsGroupMinimum(res?.data))
            .catch(error => console.log('Getting Carrier Individuals In Group Minimum error:', error))
            .finally(() => closeLoader())
    }, [])


    return (
        <React.Fragment>
            {/* Carrier Requirements */}
            <Accordion defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Carrier Requirements</span>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Name</h6>

                            <div className="textfield-div">
                                {carrierDetails?.CarrierName}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Carrier Platforms</h6>
                                {helperInfoIcon('Some Carriers have multiple client platforms')}
                            </div>

                            <div className="textfield-div d-block no-height word-break-all">
                                {Requirements?.CarrierPlatforms}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Has Minimum Group Size Requirements</h6>
                            <div className="textfield-div">
                                {convertBoolToYesNo(Requirements?.CarrierHasMinimumSize)}
                            </div>
                        </Grid>
                        {Requirements?.CarrierHasMinimumSize == 1 &&
                            <Grid item xs={12} sm={12} md={6}>
                                <h6 className="form-title">Group Minimum</h6>
                                <div className="textfield-div">
                                    {Requirements?.CarrierGroupMinimum}
                                </div>
                            </Grid>
                        }

                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Can the minimum be lowered?</h6>
                            <div className="textfield-div">
                                {convertBoolToYesNo(Requirements?.CarrierMinimumCanbeLowered)}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Included Individuals in Calculating the Group Minimum</h6>
                            <div className="textfield-div">
                                {individualsGroupMinimum.find(el => el?.CarrierIndividualsInGroupMinimumId == Requirements?.CarrierIndividualsInGroupMinimumID)?.CarrierIndividualsInGroupMinimumName}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Requirement Notes</h6>
                            <div className="action-item-textarea textfield-div  d-block no-height word-break-all">
                                {Requirements?.CarrierRequirementsNotes}
                            </div>
                        </Grid>


                    </Grid>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}

export default CarrierTabsCarrierRequirements
