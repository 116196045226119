import React from 'react'
import { useHistory, useParams } from "react-router-dom";
import RefreshIcon from '@material-ui/icons/Refresh';
import { useAuthentication } from "../../../../Data/Authentication";
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps";
import MultipleTabsTitles from "../../../Layout/MultipleTabsTitles";
import { Button, Zoom, } from "@material-ui/core";
import * as APIs from "../../../../Data/APIs";
import { useLoader } from "../../../Layout/Loader";
import { CustomTooltip } from "../../../Layout/Tooltip";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Moment from 'moment';
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";

const TabsRow = () => {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const connectivityTabs = ["Connectivity Request Configuration", "Action Items", "OE Requests"];

    const { connectivityRequestIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const [tabValue, setTabValue] = React.useState(2);
    const routeHistory = useHistory();
    const [connectivityReq, setConnectivityReq] = React.useState();
    const [groupReqStatus, setGroupReqStatus] = React.useState("");

    const handleTabChange = (e, newValue) => {
        openLoader();
        setTabValue(newValue);
        if (newValue === 1)
            routeHistory.push(`/ActionItems/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`);
        else if (newValue === 2)
            routeHistory.push(`/OERequests/ViewConnectivityRequest/${connectivityRequestIDURL}/Dashboard`);
        else routeHistory.push(`/ConnectivityRequests/ViewConnectivityRequest/${connectivityRequestIDURL}`);
    };

    React.useEffect(() => {
        openLoader();
        APIs.getConnectivityRequest(connectivityRequestIDURL).then((r) => { setConnectivityReq(r?.data); closeLoader(); });
    }, []);

    React.useEffect(() => {
        openLoader();
        APIs.getGroupRequestData(connectivityReq?.GroupRequestID).then((r) => {
            setGroupReqStatus(r?.data?.GroupRequest?.GroupRequestStatusID); closeLoader();
        });
    }, [connectivityReq]);
    return (
        <div style={{ position: "relative", }}>
            <div className="d-flex flex-wrap">
                {connectivityReq && (
                    <BreadcrumbSteps
                        breadcrumbTitleCurrent={`${connectivityReq?.Peo?.PeoName ? connectivityReq?.Peo?.PeoName : connectivityReq?.GroupName}, ${connectivityReq?.CarrierName === "Third Party" ? `${connectivityReq?.CarrierName} (${connectivityReq?.ThirdPartyName})` : connectivityReq?.CarrierName}`}
                        isCurrent={true}
                    />
                )}
                {groupReqStatus === 4 && <div className="warmingOERequest ml-auto">  <ErrorOutlineIcon className="mr-1" />Kindly note that this connectivity request is attached to a draft group request</div>}
            </div>

            <div className="d-flex">
                <MultipleTabsTitles tabTitle={connectivityTabs} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />
            </div>


            {(userActions?.includes("44") ||
                userActions?.includes("20")) && (
                    <div>
                        <div className="alignvh-center" style={{ position: "absolute", right: "2px", marginTop: "-58px" }} >
                        <CustomTooltip TransitionComponent={Zoom} title={connectivityReq?.ConnectivityRequestType === "Forms" ?
                             "You can not add an OE Request for forms connectivity requests" :
                              connectivityReq?.StructureTypeName === "401K" ?
                               "No OE Requests to be submitted for contribution/401k feeds" 
                               :(connectivityReq?.CancellationDate && connectivityReq?.ConnectivityRequestStatusName !=="Cancelled") ?
                                `${connectivityReq.CarrierName} connectivity request has a future cancellation effective ${Moment(connectivityReq?.CancellationDate).format('MM/DD/YYYY')}` : 
                                connectivityReq?.ConnectivityRequestStatusName==="Cancelled"?
                                `${connectivityReq?.CarrierName} connectivity request is cancelled effective ${Moment(connectivityReq?.CancellationDate).format('MM/DD/YYYY')}`:''}>
                        <span>
                                    <Button variant="contained" disabled={!connectivityReq || (connectivityReq?.ConnectivityRequestType === "Forms") || connectivityReq?.StructureTypeName === "401K" || connectivityReq?.CancellationDate} className="blue-btn" href={`/OERequests/ViewConnectivityRequest/${connectivityRequestIDURL}/CreateOERequest`}>
                                        <label style={{ cursor: "pointer" }} className="ml-2 mb-0">    <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                            <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                        </svg> OE Request</label>
                                    </Button>
                                </span>
                            </CustomTooltip>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default TabsRow
