import React, { useRef } from "react";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

function SelectSubgroups({ subgroups, setSelectedSubgroups, selectedSubgroups, subgroupSearchValue, ...props }) {

    const [checkedSubgroup, setCheckedSubgroup] = React.useState(false);
    const [value, setValue] = React.useState(0);

    const [selectAllSubgroupsChecked, setSelectAllSubgroupsChecked] = React.useState(false);


    const handleSubgroupChange = (value) => (e) => {
        setCheckedSubgroup(e.target.checked);
    }

    const handleSubgroupClick = (value) => (event) => {
        let selectedSubgroupArray = selectedSubgroups;
        if (!value || value === "") selectedSubgroupArray = [];
        else if (selectedSubgroupArray.indexOf(value) >= 0) {
            selectedSubgroupArray.splice(selectedSubgroupArray.indexOf(value), 1);
        }
        else selectedSubgroupArray.push(value);
        setSelectedSubgroups([...selectedSubgroupArray]);

        if (selectedSubgroups.length === subgroups.length) setSelectAllSubgroupsChecked(true); else setSelectAllSubgroupsChecked(false);
    }

    const handleSelectAllSubgroupChange = (value) => (e) => {
        setSelectAllSubgroupsChecked(e.target.checked);
    }

    const handleSelectAllSubgroupClick = (value) => (event) => {
        let selectedSubgroupArray = selectedSubgroups;
        if (!value || value === "") selectedSubgroupArray = [];
        else if (selectedSubgroupArray.indexOf(...value) >= 0) {
            selectedSubgroupArray.splice(selectedSubgroupArray.indexOf(...value));
        }
        else {
            selectedSubgroupArray.length = 0;
            selectedSubgroupArray.push(...value);
        }
        setSelectedSubgroups([...selectedSubgroupArray]);
    }

    return (
        <div id="select-subgroups-plans" className="row plans-list-div container-div p-4 checkbox-list">
            {subgroups?.filter(s => subgroupSearchValue ? s.ClientName?.toLowerCase().includes(subgroupSearchValue.toLowerCase()) : s).length > 0 &&
                <React.Fragment key={`subgroups-select-all`}>

                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                id={`subgroup-option-select-all`}
                                value={subgroups}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checked={selectAllSubgroupsChecked === true && selectedSubgroups.length === subgroups?.filter(s => subgroupSearchValue ? s.ClientName?.toLowerCase().includes(subgroupSearchValue.toLowerCase()) : s).length}
                                onChange={handleSelectAllSubgroupChange(subgroups)}
                                onClick={handleSelectAllSubgroupClick(subgroups?.filter(s => subgroupSearchValue ? s.ClientName?.toLowerCase().includes(subgroupSearchValue.toLowerCase()) : s))}
                                checkedIcon={<DoneOutlineIcon />}
                                color="default"
                            />
                        }
                        label="Select All"
                    />
                    <br />
                </React.Fragment>
            }
            {subgroups?.filter(s => subgroupSearchValue ? s.ClientName?.toLowerCase().includes(subgroupSearchValue.toLowerCase()) : s).map((c, ci) => (
                <React.Fragment key={`subgroups-${ci}`}>

                    <FormControlLabel
                        aria-label="Acknowledge"
                        onClick={(event) => event.stopPropagation()}
                        onFocus={(event) => event.stopPropagation()}
                        control={
                            <Checkbox
                                id={`subgroup-option-${ci}`}
                                value={c}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checked={selectedSubgroups && selectedSubgroups.indexOf(c) >= 0}
                                onChange={handleSubgroupChange(c)}
                                onClick={handleSubgroupClick(c)}
                                checkedIcon={<DoneOutlineIcon />}
                                color="default"
                            />
                        }
                        label={c?.ClientName}
                    />
                    <br />
                </React.Fragment>
            ))}
        </div>
    )
}

export default SelectSubgroups;