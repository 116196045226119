import React from 'react'
import { convertBoolToYesNo } from '../../../../../_helper'

const CarrierTabsCarrierContactTableRow = ({ carrierContact, categories, types, ...props }) => {
    return (
        <div>
            <div className="row table-row">
                <div className="col-1 table-cell first-cell d-flex flex-column word-break-all">
                    {carrierContact?.FirstName}
                </div>
                <div className="col-1 table-cell d-flex flex-column word-break-all">
                    {carrierContact?.LastName}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {carrierContact?.Email}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {carrierContact?.PhoneNumber}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {categories?.find(c => c.CarrierContactCategoryId === carrierContact?.CarrierContactCategoryID)?.CarrierContactCategoryName}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {types?.find(c => c.CarrierContactTypeId === carrierContact?.CarrierContactTypeID)?.CarrierContactTypeName}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {convertBoolToYesNo(carrierContact?.DisplayOnCRs)}
                </div>
                {/* <div className="col table-cell d-flex flex-column">
                </div> */}
            </div>
        </div>
    )
}

export default CarrierTabsCarrierContactTableRow
