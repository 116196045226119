import React, { Fragment } from 'react'
import RelationshipContactsTableRow from './RelationshipContactsTableRow'

const RelationshipContactsTable = ({ carrierRelationshipContacts, ...props }) => {
    return (
        <div>
            <div className="row table-header">
                <div className="col-2 table-cell first-cell">
                    First Name
                </div>
                <div className="col-2 table-cell">
                    Last Name
                </div>
                <div className="col-2 table-cell">
                    Job Title
                </div>
                <div className="col-2 table-cell">
                    Email
                </div>
                <div className="col-2 table-cell">
                    Phone Number
                </div>
                <div className="col-2 table-cell">
                    Notes
                </div>
                {/* <div className="col table-cell">
                    Actions
                </div> */}
            </div>
            {
                carrierRelationshipContacts?.length > 0 ?
                    carrierRelationshipContacts?.map((cc, cci) =>
                        <Fragment key={cci}>
                            <RelationshipContactsTableRow carrierRelationshipContact={cc} />
                        </Fragment>) :
                    <div className="no-data-alert">
                        <h6>No Relationship Contacts Added!</h6>
                    </div>

            }
        </div>
    )
}

export default RelationshipContactsTable
