import React from 'react'
import { Paper, } from "@material-ui/core";
import ChangePassword from './ChangePassword';
import EditProfile from './EditProfile';
import Notifications from './Notifications';

const ProfilePage = () => {

    return (
        <div id="view-action-items" className="mt-3">
            <div className="row w-100 alignvh-center ">
                <h5 className="black1 fw-700">My Profile</h5>
            </div>
            <Paper elevation={0} className="search-container" style={{ marginTop: "20px", padding: "40px" }}>
                <div className='row  '>
                    <div className='col  '>
                        <div className="   alignvh-center ">
                            <h5 className="black1 fw-700">Edit Profile </h5>
                        </div>

                    </div>
                    <div className='col '>

                        <div className=" mx-4 alignvh-center ">
                            <h5 className="black1 fw-700">Change Password</h5>
                        </div>
                    </div>

                </div>
                <div className='row mt-3 '>
                    <EditProfile />
                    <div className='col-xl-6 pl-4 '>
                        <ChangePassword />
                        <Notifications />
                    </div>

                </div>

            </Paper>
        </div>
    )
}

export default ProfilePage



