import React from "react";
import { Divider, Paper } from "@material-ui/core";
import BorderCircularProgress from "../../Charts/BorderCircularProgress";
import Chart from "react-google-charts";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PieChartIcon from '@mui/icons-material/PieChart';
import LinearProgressBar from "../../Layout/LinearProgressBar";

function ConnectivityRequestsStatistics({
  dashboardCRStatus,
  dashboardCRPhases,
  onPhaseFilter,
  onStatusFilter,
  tableRef,
  onSetOnPhaseDashboardChange,
  onSetOnStatusDashboardChange,
  onSetCreatedFromDateFilter,
  onSetCreatedToDateFilter,
  onSetStatusesFilterCard,
  setQuickFiltersClicked,
  onSetFilterCard,
  ...props
}) {
  const _statusColors = [
    "#CCCCCC",
    "#0e4c8a",
    "#5382a1",
    "#EBA400",
    "#F22525",
    "#00C305",
    "#7cd992",
  ];

  const statusSizes = [300, 275, 250, 225, 200, 175, 150];

  const _phaseColors = [
    "#CCCCCC",
    "#000000",
    "#707C97",
    "#7cd992",
    "#EBA400",
    "#5382a1",
    "#0e4c8a",
    "#088f8f",
    "#00C305",
  ];
  const [expandedAccordion, setExpandedAccordion] = React.useState(false);
  const phaseSizes = [300, 275, 250, 225, 200, 175, 150, 125];
  function handlePhase(phase) {
    onSetFilterCard(true);
    onSetOnPhaseDashboardChange(true);
    onSetOnStatusDashboardChange(false);
    onSetCreatedFromDateFilter(null);
    onSetCreatedToDateFilter(null);
    onStatusFilter("");
    onSetStatusesFilterCard([1, 2, 3, 6, 7, 8]);

    onPhaseFilter(phase);
    tableRef.current.scrollIntoView({ behavior: 'smooth' });
    setQuickFiltersClicked(true);
  }

  function handleStatus(status) {
    onSetFilterCard(true);
    onSetOnStatusDashboardChange(true);
    onSetOnPhaseDashboardChange(false);
    onSetCreatedFromDateFilter(null);
    onSetCreatedToDateFilter(null);
    onSetStatusesFilterCard([]);
    onPhaseFilter("");
    onStatusFilter(status);
    tableRef.current.scrollIntoView({ behavior: 'smooth' });
    setQuickFiltersClicked(true);

  }

  return (
    <div id="connectivity-requests-statistics">
      <div className="row w-100 alignvh-center mb-4">
        <h5 className="black1 fw-400 ml-3">Connectivity Requests Dashboard</h5>
      </div>
      <Accordion className="exchangeAccordion mb-4">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-content"
          id="panel-header"
          style={{ minHeight: "65px" }}
        >
          <div style={{ fontWeight: "500" }}>
            <PieChartIcon className="color pink-primary-color mr-3" />
            <span>Connectivity Requests Statistics</span>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ borderTop: "1px solid gainsboro" }}>
          <div className="mb-5">
            {dashboardCRStatus && dashboardCRStatus.length > 0 && dashboardCRStatus.filter(s => s.count === 0).length !== dashboardCRStatus.length && (
              <>
                <h6 className="fw-500">Statuses</h6>
                <div className="row mt-3">
                  <div className="col-xl-4">
                    <LinearProgressBar title={dashboardCRStatus[1]?.name} percentage={Math.round(dashboardCRStatus[1]?.percentage * 10) / 10} count={dashboardCRStatus[1]?.count} level="blue" onClickHandler={() => handleStatus(1)} />
                    <LinearProgressBar title={dashboardCRStatus[5]?.name} percentage={Math.round(dashboardCRStatus[5]?.percentage * 10) / 10} count={dashboardCRStatus[5]?.count} level="green" onClickHandler={() => handleStatus(7)} />
                    <LinearProgressBar title={dashboardCRStatus[0]?.name} percentage={Math.round(dashboardCRStatus[0]?.percentage * 10) / 10} count={dashboardCRStatus[0]?.count} level="red" onClickHandler={() => handleStatus(6)} />
                  </div>
                  <div className="col-xl-4">
                    <LinearProgressBar title={dashboardCRStatus[2]?.name} percentage={Math.round(dashboardCRStatus[2]?.percentage * 10) / 10} count={dashboardCRStatus[2]?.count} level="red" onClickHandler={() => handleStatus(2)} />
                    <LinearProgressBar title={dashboardCRStatus[3]?.name} percentage={Math.round(dashboardCRStatus[3]?.percentage * 10) / 10} count={dashboardCRStatus[3]?.count} level="red" onClickHandler={() => handleStatus(3)} />
                    <LinearProgressBar title={dashboardCRStatus[7]?.name} percentage={Math.round(dashboardCRStatus[7]?.percentage * 10) / 10} count={dashboardCRStatus[7]?.count} level="red" onClickHandler={() => handleStatus(9)} />
                  </div>
                  <div className="col-xl-4">
                    <LinearProgressBar title={dashboardCRStatus[4]?.name} percentage={Math.round(dashboardCRStatus[4]?.percentage * 10) / 10} count={dashboardCRStatus[4]?.count} level="red" onClickHandler={() => handleStatus(5)} />
                    <LinearProgressBar title={dashboardCRStatus[6]?.name} percentage={Math.round(dashboardCRStatus[6]?.percentage * 10) / 10} count={dashboardCRStatus[6]?.count} level="red" onClickHandler={() => handleStatus(8)} />
                  </div>
                </div>
              </>
            )}
          </div>
          <div>
            {dashboardCRPhases && dashboardCRPhases.length > 0 && dashboardCRPhases.filter(s => s.count === 0).length !== dashboardCRPhases.length && (
              <>
                <h6 className="fw-500">Phases</h6>
                <div className="row mt-3">
                  <div className="col-xl-4">
                    <LinearProgressBar title={dashboardCRPhases[1]?.name} percentage={Math.round(dashboardCRPhases[1]?.percentage * 10) / 10} count={dashboardCRPhases[1]?.count} level="blue" onClickHandler={() => handlePhase(1)} />
                    <LinearProgressBar title={dashboardCRPhases[2]?.name} percentage={Math.round(dashboardCRPhases[2]?.percentage * 10) / 10} count={dashboardCRPhases[2]?.count} level="blue" onClickHandler={() => handlePhase(2)} />
                    <LinearProgressBar title={dashboardCRPhases[3]?.name} percentage={Math.round(dashboardCRPhases[3]?.percentage * 10) / 10} count={dashboardCRPhases[3]?.count} level="blue" onClickHandler={() => handlePhase(3)} />
                  </div>
                  <div className="col-xl-4">
                    <LinearProgressBar title={dashboardCRPhases[4]?.name} percentage={Math.round(dashboardCRPhases[4]?.percentage * 10) / 10} count={dashboardCRPhases[4]?.count} level="blue" onClickHandler={() => handlePhase(4)} />
                    <LinearProgressBar title={dashboardCRPhases[5]?.name} percentage={Math.round(dashboardCRPhases[5]?.percentage * 10) / 10} count={dashboardCRPhases[5]?.count} level="blue" onClickHandler={() => handlePhase(5)} />
                    <LinearProgressBar title={dashboardCRPhases[6]?.name} percentage={Math.round(dashboardCRPhases[6]?.percentage * 10) / 10} count={dashboardCRPhases[6]?.count} level="blue" onClickHandler={() => handlePhase(6)} />
                  </div>
                  <div className="col-xl-4">
                    <LinearProgressBar title={dashboardCRPhases[7]?.name} percentage={Math.round(dashboardCRPhases[7]?.percentage * 10) / 10} count={dashboardCRPhases[7]?.count} level="green" onClickHandler={() => handlePhase(9)} />
                    <LinearProgressBar title={dashboardCRPhases[8]?.name} percentage={Math.round(dashboardCRPhases[8]?.percentage * 10) / 10} count={dashboardCRPhases[8]?.count} level="green" onClickHandler={() => handlePhase(7)} />
                    <LinearProgressBar title={dashboardCRPhases[0]?.name} percentage={Math.round(dashboardCRPhases[0]?.percentage * 10) / 10} count={dashboardCRPhases[0]?.count} level="red" onClickHandler={() => handlePhase(8)} />
                  </div>
                </div>
              </>
            )}
          </div>
          {dashboardCRStatus.filter(s => s.count === 0).length === dashboardCRStatus.length && dashboardCRPhases.filter(s => s.count === 0).length === dashboardCRPhases.length && (
            <div className="no-data-alert">
              <h5>No Data Available!</h5>
            </div>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ConnectivityRequestsStatistics;
