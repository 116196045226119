import React from 'react'
import { useAuthentication } from '../../../../Data/Authentication';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useParams } from 'react-router-dom';
import { Button } from "@material-ui/core";
import Moment from 'moment';
import GenericTable from "../../../Layout/GenericTable";
import Pagination from "../../../Layout/Pagination";


const CarrierTabsChangeLogs = ({ carrierDetails, ...props }) => {
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { carrierIDURL } = useParams();
    const [selectedPage, setSelectedPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(30);


    const baseColumnsArray = [
        {
            name: 'Carrier Name',
            sortable: false,
            id: 'CarrierName',
            selector: (c) => carrierDetails?.CarrierName
        },
        {
            name: 'Carrier Code',
            sortable: false,
            id: 'CarrierCode',
            selector: (c) => carrierDetails?.GeneralInfo?.CarrierNumber
        },
        {
            name: 'Field',
            sortable: false,
            id: 'Field',
            selector: (c) => c?.ChangedField
        },
        {
            name: 'Old Value',
            sortable: false,
            id: 'OldValue',
            selector: (c) => c?.OldValue
        },
        {
            name: 'New Value',
            sortable: false,
            id: 'NewValue',
            selector: (c) => c?.NewValue
        },
        {
            name: 'Last Modified Date',
            sortable: false,
            id: 'LastModifiedDate',
            selector: (c) => c?.Updated
        },
        {
            name: 'Last Modified By',
            sortable: false,
            id: 'LastModifiedBy',
            selector: (c) => c?.ChangedBy
        }

    ];


    return (
        <React.Fragment>
            
            <GenericTable
                columns={[...baseColumnsArray]}
                data={carrierDetails?.ChangeLogs?.ChangeLogsList}
                noDataComponent={
                    <div className="no-data-alert">
                        <h6>No Results Found!</h6>
                    </div>
                }
                onSort={() => { }}
            />

{/* <div>
                <Pagination
                    // stateLimit={true}
                    pageSize={pageSize}
                    page={selectedPage}
                    onChange={(t, v) => {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    }}
                    count={carrierDetails?.ChangeLogs?.TotalCount}
                />
            </div> */}
        </React.Fragment>
    )
}

export default CarrierTabsChangeLogs
