import React from 'react'

const RelationshipContactsTableRow = ({ carrierRelationshipContact, ...props }) => {
    return (
        <div>
            <div className="row table-row">
                <div className="col-2 table-cell first-cell d-flex flex-column word-break-all">
                    {carrierRelationshipContact?.FirstName}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {carrierRelationshipContact?.LastName}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {carrierRelationshipContact?.JobTitle}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {carrierRelationshipContact?.Email}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {carrierRelationshipContact?.PhoneNumber}
                </div>
                <div className="col-2 table-cell d-flex flex-column word-break-all">
                    {carrierRelationshipContact?.Notes}
                </div>
                {/* <div className="col table-cell d-flex flex-column">
                </div> */}
            </div>
        </div>
    )
}

export default RelationshipContactsTableRow
