import React, { useEffect, useState } from "react";
import * as APIs from '../../Data/APIs'
import GenericTable from "../Layout/GenericTable";
import { CustomTooltip } from "../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import GetAppIcon from '@material-ui/icons/GetApp';
import { saveAs } from 'file-saver'
import Truncate from "react-truncate";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import moment from "moment";
import { openModal, toESTTime } from "../../_helper";
import { useLoader } from "../Layout/Loader";
import { Button } from "@material-ui/core";
import { SuccessModal } from "../Modals/SuccessModal";
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import ReplayIcon from '@material-ui/icons/Replay';
import retryIcon from "../../Icons/rotate-left.svg";
import processEndIcon from "../../Icons/arrow-right-long-to-line.svg";
import { useAuthentication } from "../../Data/Authentication";



// Higher-Order Component to pass custom props
export const withCustomProps = (Component, customProps) => {
    return (props) => <Component {...props} {...customProps} />;
};




export const SingleFeedTransmissionNew = ({ data, refreshClicked, setRefreshClicked, ...props }) => {
    const { authUser } = useAuthentication();
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const { openLoader, closeLoader } = useLoader()
    const dialog = useModalDialog(0)
    const [transmissionLogs, setTransmissionLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const [parserArray, setParserArray] = React.useState([]);
    const [trackerArray, setTrackerArray] = React.useState([]);
    const [mapperArray, setMapperArray] = React.useState([]);
    const [serializerArray, setSerializerArray] = React.useState([]);
    const [encrypterArray, setEncrypterArray] = React.useState([]);
    const [transmitterArray, setTransmitterArray] = React.useState([]);
    const [recentComponentsArray, setRecentComponentsArray] = React.useState([]);


    React.useEffect(() => {
        if (transmissionLogs?.length > 0) {
            setParserArray(transmissionLogs?.filter(s => s.LSI === "Parser"));
            setTrackerArray(transmissionLogs?.filter(s => s.LSI === "Tracker"));
            setMapperArray(transmissionLogs?.filter(s => s.LSI === "Mapper"));
            setSerializerArray(transmissionLogs?.filter(s => s.LSI === "Serializer"));
            setEncrypterArray(transmissionLogs?.filter(s => s.LSI === "Encrypter"));
            setTransmitterArray(transmissionLogs?.filter(s => s.LSI === "Transmitter"));
        } else return;
    }, [transmissionLogs])


    React.useEffect(() => {
        setRecentComponentsArray([parserArray[0], trackerArray[0], mapperArray[0], serializerArray[0], encrypterArray[0], transmitterArray[0]]);
    }, [parserArray, trackerArray, mapperArray, serializerArray, encrypterArray, transmitterArray])


    useEffect(() => {
        APIs.getSingleFeedTranmissionLogs(data?.SK).then((r) => {
            setTransmissionLogs(r?.data ?? []);
            setRefreshClicked(false);
            setLoading(false);
        });
    }, [data?.SK]); // The dependency array ensures this effect only runs when data.SK changes.

    const retryComponent = (singleFeedTransmission) => {

        const retryBody = {
            feedID: parseInt(singleFeedTransmission?.FeedID),
            type: parseInt(singleFeedTransmission?.ComponentID),
            isRetry: true,
            executionID: singleFeedTransmission?.PK,
            fileName: singleFeedTransmission?.InputFile.split("?AWSAccessKey")[0].substring(
                singleFeedTransmission?.InputFile.split("?AWSAccessKey")[0].indexOf("Engine/"),
                singleFeedTransmission?.InputFile.split("?AWSAccessKey")[0].length
            ),
            executionStatus: singleFeedTransmission?.ExecutionStatus,
            sk: singleFeedTransmission?.SK,
        }
        APIs.retryProcessToEnd(retryBody).then(r => (
            SuccessModal(dialog, {
                title: 'Processing...',
                body: `Kindly be noted that this may take few minutes.`,
            })
        ));
    }

    const confirmProcess = (singleFeedTransmission) => {

        const confirmProcessBody = {
            feedID: singleFeedTransmission?.FeedID,
            executionID: singleFeedTransmission?.PK,
            filePath: singleFeedTransmission?.OutputFile.split("?AWSAccessKey")[0].substring(
                singleFeedTransmission?.OutputFile.split("?AWSAccessKey")[0].indexOf("Engine/"),
                singleFeedTransmission?.OutputFile.split("?AWSAccessKey")[0].length
            ),
            sk: singleFeedTransmission?.SK,
        }
        APIs.confirmProcessTransmitter(confirmProcessBody).then(r => (
            SuccessModal(dialog, {
                title: 'Success',
                body: `An email has been successfully sent to the Carrier`,
            }, () => {
                window.location.reload();
            })
        ));
    }
    const pushToColumnsBasedOnUserRole = () => {
        let checkIfIsNetworkPartnerOrClient = !(userRoles.includes("5") || userRoles.includes("6"));

        let itemsToInsert = [
            {
                name: 'Actions',
                sortable: false,
                id: 'Actions',
                selector: l => transmissionLogs.filter(s1 => recentComponentsArray.some(s2 => s2 !== undefined && s1.SK === s2.SK)).map((t, ti) => (
                    t?.SK === l?.SK && (
                        <div className="d-flex flex-column align-items-start" key={`retry-process-to-end-${ti}`}>
                            <CustomTooltip TransitionComponent={Zoom} title="Retry the current component">
                                <Button className="transmission-actions-btn mt-1" onClick={() => {
                                    openModal(dialog, 0, () => retryComponent(l), {
                                        title: "Please Confirm",
                                        question: `Are you sure you want to retry the ${l?.LSI} for feed ${data?.FeedID}?`,
                                    })
                                }}>
                                    <svg width="12" height="21" viewBox="0 0 23 21" className="mr-1" >
                                        <use xlinkHref={`${retryIcon}#rotate-left`}></use>
                                    </svg>
                                    <span>Retry</span>
                                </Button>
                            </CustomTooltip>
    
                            {(((data?.ExecutionStatus).includes("Pending Transmission") && ti !== 0) || !(data?.ExecutionStatus).includes("Pending Transmission")) && <CustomTooltip TransitionComponent={Zoom} title="Start processing from the current step until the configured component in the feed configurations">
                                <Button className="transmission-actions-btn" onClick={() => {
                                    openModal(dialog, 0, () => processToEndComponent(l), {
                                        title: "Please Confirm",
                                        question: `Are you sure you want to rerun from the ${l?.LSI} for feed ${data?.FeedID}?`,
                                    })
                                }}>
    
                                    <svg width="15" height="19" viewBox="0 0 31 19" className="mr-1" >
                                        <use xlinkHref={`${processEndIcon}#arrow-right-long-to-line`}></use>
                                    </svg>
                                    <span>Process To End</span>
                                </Button>
                            </CustomTooltip>}
                            {(data?.ExecutionStatus).includes("Pending Transmission") && ti === 0 &&
                                <>
                                    <CustomTooltip TransitionComponent={Zoom} title="Proceed transmitting confirmation email to the Carrier after sending the file manually">
                                        <Button className="transmission-actions-btn" onClick={() => {
                                            openModal(dialog, 0, () => confirmProcess(l), {
                                                title: "Please Confirm",
                                                question: `Are you sure you have sent the file manually on the Carrier's portal?`,
                                            })
                                        }}>
                                            <svg width="15" height="19" viewBox="0 0 31 19" className="mr-1" >
                                                <use xlinkHref={`${processEndIcon}#arrow-right-long-to-line`}></use>
                                            </svg>
                                            <span>Mark Transmitted</span>
                                        </Button>
                                    </CustomTooltip>
                                </>
                            }
                        </div>
                    )
                )),
                condition: checkIfIsNetworkPartnerOrClient
            },
        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumns];


        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.push(item);
        });


        return finalColumnsArray;
    };
    
    const processToEndComponent = (singleFeedTransmission) => {

        const processToEndBody = {
            feedID: parseInt(singleFeedTransmission?.FeedID),
            type: parseInt(singleFeedTransmission?.ComponentID),
            isRetry: false,
            executionID: singleFeedTransmission?.PK,
            fileName: singleFeedTransmission?.InputFile.split("?AWSAccessKey")[0].substring(
                singleFeedTransmission?.InputFile.split("?AWSAccessKey")[0].indexOf("Engine/"),
                singleFeedTransmission?.InputFile.split("?AWSAccessKey")[0].length
            ),
            executionStatus: singleFeedTransmission?.ExecutionStatus,
            sk: singleFeedTransmission?.SK,
        }
        APIs.retryProcessToEnd(processToEndBody).then(r => (
            SuccessModal(dialog, {
                title: 'Processing...',
                body: `Kindly be noted that this may take few minutes.`,
            })
        ));
    }

    const baseColumns = [
        {
            name: 'Execution Step',
            sortable: false,
            id: 'ExecutionStep',
            width: '120px',
            selector: l => l?.LSI
        },
        {
            name: 'Execution Status',
            sortable: false,
            id: 'ExecutionStatus',
            width: '120px',
            selector: l => <div className={`${(l?.ExecutionStatus).includes("Fail") ? "red background status-div" : ""}`}>
                {l?.ExecutionStatus}
            </div>
        },
        {
            name: 'Processing Log',
            sortable: false,
            id: 'ProcessingLog',
            selector: l =>
                l?.LSI === "Mapper" && l?.validationExcelFileKey !== "N/A" ? (
                    <div className="d-flex flex-column">
                        <CustomTooltip TransitionComponent={Zoom} title="Download Output File">
                            <button className="btn-like-link text-left table-cell-link " style={{ cursor: "pointer" }} onClick={() => APIs.downloadInvalidFile(data?.SK).then(blob => { saveAs(blob.data, blob.fileName) })}>
                                <span className="mr-2"><GetAppIcon fontSize="small" /></span>
                                <span>{l?.ProcessingLog}</span>
                            </button>
                        </CustomTooltip>
                        <CustomTooltip TransitionComponent={Zoom} title="Download Report">
                            <a href={l?.validationExcelFileKey}>
                                <span className="mr-2"><GetAppIcon fontSize="small" /></span>
                                <span>{l?.ProcessingLog === "Invalid Output File" ? "Invalid Output Report" : l?.ProcessingLog}</span>
                            </a>
                        </CustomTooltip>
                    </div>
                ) : (
                    <Truncate lines={3} ellipsis={<span>... <button className="fs-13 btn-like-link" style={{ color: "#2c87f0" }} onClick={() => dialog.openDialog("Processing Logs:", <div><p style={{ wordBreak: "break-word" }}>{l?.ProcessingLog}</p></div>, [])}>Read More</button></span>}>
                        {l?.ProcessingLog}
                    </Truncate>
                )
        },
        {
            name: 'Input File',
            sortable: false,
            id: 'InputFile',
            width: '120px',
            selector: l => l?.InputFile === "N/A" ? "N/A" : (
                <CustomTooltip TransitionComponent={Zoom} title="Download link expires in 10 minutes">
                    <a href={l?.InputFile}>Download</a>
                </CustomTooltip>
            )
        },
        {
            name: 'Output File',
            sortable: false,
            id: 'OutputFile',
            width: '120px',
            selector: l => l?.OutputFile === "N/A" ? "N/A" : (
                <CustomTooltip TransitionComponent={Zoom} title="Download link expires in 10 minutes">
                    <a href={l?.OutputFile}>Download</a>
                </CustomTooltip>
            )
        },
        {
            name: 'Processing Start Time',
            sortable: false,
            id: 'ProcessingStartTime',
            selector: l => moment(toESTTime(l?.ProcessingStartTime)).format("MM/DD/YYYY HH:mm:ss")
        },
        {
            name: 'Processing End Time',
            sortable: false,
            id: 'ProcessingEndTime',
            selector: l => moment(toESTTime(l?.ProcessingEndTime)).format("MM/DD/YYYY HH:mm:ss")
        },
    ]


    return (
        <div className="p-3">
            {loading ? 'Loading...' :
                <>
                    <GenericTable
                        columns={[...pushToColumnsBasedOnUserRole()]}
                        data={transmissionLogs.filter(transmissionLog => transmissionLog?.PK == data?.SK)}
                        onSort={() => { }}
                        evenAndOddStyles={false}
                    />
                </>
            }
        </div>
    );
};
