import React from 'react'
import { useAuthentication } from '../../../../Data/Authentication';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import EncryptionKeyRow from '../../EncryptionKeyRow';
import AddEncryptionKey from '../../AddEncryptionKey';
import { useParams } from 'react-router-dom';
import RelationshipContactsTable from './RelationshipContacts/RelationshipContactsTable';
import CarrierTabsCarrierContactTable from './CarrierTabsCarrierContact/CarrierTabsCarrierContactTable';
import plusIconSVG from "../../../../Icons/PlusIconWhite.svg";
import { helperInfoIcon } from '../../../../_helper';


const CarrierTabsGeneralInfo = ({ carrierDetails, ...props }) => {
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { carrierIDURL } = useParams();
    const { GeneralInfo } = carrierDetails || {};

    return (
        <React.Fragment>
            {/* Carrier Information */}
            <Accordion defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Carrier Information</span>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Name</h6>

                            <div className="textfield-div">
                                {carrierDetails?.CarrierName}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Carrier Code</h6>
                                {helperInfoIcon('The Carrier Code is the Partner ID, which comes from isolved HCM')}
                            </div>

                            <div className="textfield-div">
                                {GeneralInfo?.CarrierNumber}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Federal Tax</h6>
                            <div className="textfield-div">
                                {GeneralInfo?.CarrierFederalTaxID}
                            </div>
                        </Grid>


                    </Grid>
                </AccordionDetails>
            </Accordion>

            {/* Encryption Keys */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <div className="d-flex">
                        <span>Encryption Keys</span>
                        <span style={{ pointerEvents: 'auto' }}
                            onClick={(event) => {
                                event.stopPropagation();
                            }}>
                            {helperInfoIcon('For security purposes, the transmitted data is encrypted using certificates issued from a public trusted certificate authority')}
                        </span>
                    </div>
                    {(userActions?.includes("37") ||
                        userActions?.includes("20")) && (
                            <div className="add-record-btn ml-auto"
                                style={{ pointerEvents: 'auto' }}
                                onClick={(e) => { e.stopPropagation(); dialog.openDialog("Add Public Key", <AddEncryptionKey carrierID={carrierIDURL} />, []) }
                                }
                            >
                                <svg width="22" height="22" viewBox="0 0 22 22" className="mt-2">
                                    <use xlinkHref={`${plusIconSVG}#PlusIconWhite`}></use>
                                </svg>
                                ADD
                            </div>
                        )}
                </AccordionSummary>
                <AccordionDetails className='p-0-imp'>
                    <>
                        <div className="row text-center table-header">
                            <div className="col-3 table-cell first-cell">Key Name</div>
                            <div className="col-3 table-cell">Key ID</div>
                            <div className="col-3 table-cell">Fingerprint</div>
                            {(userActions?.includes("37") ||
                                userActions?.includes("20")) ? (
                                <>
                                    <div className="col-2 table-cell">Created Date</div>
                                    <div className="col-1 table-cell">Actions</div>
                                </>
                            ) : (
                                <div className="col-3 table-cell">Created Date</div>
                            )}

                        </div>

                        {GeneralInfo?.CarrierEncryptionKeys?.length > 0 ? (
                            GeneralInfo?.CarrierEncryptionKeys?.map((e, ei) => (
                                <EncryptionKeyRow
                                    key={`encryption-keys-${ei}`}
                                    keyItem={e}
                                    index={ei}
                                    carrierDetails={carrierDetails}
                                    carrierID={carrierIDURL}
                                />
                            ))
                        ) : (
                            <>
                                <div className="no-data-alert">
                                    <h6>No Encryption Keys Added!</h6>
                                </div>
                            </>
                        )}
                    </>
                </AccordionDetails>
            </Accordion>

            {/* Relationship Contacts */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Relationship Contacts</span>
                </AccordionSummary>
                <AccordionDetails className='p-0-imp'>
                    <RelationshipContactsTable carrierRelationshipContacts={GeneralInfo?.CarrierRelationshipContacts} />
                </AccordionDetails>
            </Accordion>

            {/* Carrier Contacts */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Carrier Contacts</span>
                </AccordionSummary>
                <AccordionDetails className='p-0-imp'>
                    <CarrierTabsCarrierContactTable carrierContacts={GeneralInfo?.CarrierContacts} />
                </AccordionDetails>
            </Accordion>

            {/* Notes */}
            <Accordion className='mt-5' defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Notes</span>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Contact Notes</h6>
                            <div className="textfield-div d-block no-height word-break-all">
                                {GeneralInfo?.CarrierContactNotes}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">General Notes</h6>

                            <div className="textfield-div d-block no-height word-break-all">
                                {GeneralInfo?.CarrierGeneralNotes}
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}

export default CarrierTabsGeneralInfo
