import React, { useEffect, useState } from 'react'
import AgreementDocumentTableRow from './AgreementDocumentTableRow';
import * as APIs from '../../../../../Data/APIs'
import { error } from 'jquery';
import { useLoader } from '../../../../Layout/Loader';
import { Zoom } from '@material-ui/core';
import { CustomTooltip } from '../../../../Layout/Tooltip';

const AgreementDocumentsTable = ({ attachments, ...props }) => {
    const { openLoader, closeLoader } = useLoader()
    const [legalDocumentTypes, setLegalDocumentTypes] = useState([])

    useEffect(() => {
        openLoader()
        APIs.getCarrierLegalDocumentTypes().then(res => {
            setLegalDocumentTypes(res?.data)
        }).catch(error => {
            console.log('Getting Carrier Legal Document Types error :', error)
        }).finally(() => closeLoader())
    }, [])

    return (
        <>
            <div className="row text-center table-header">
                <div className="col-3 table-cell first-cell">Agreement Document Type</div>
                <div className="col-3 table-cell">Attach Agreement Document</div>
                <div className="col-3 table-cell">
                    <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom}
                        title='This is the date the agreement goes into effect'>
                        <div className='mx-auto'>Agreement Document Signed Date</div>
                    </CustomTooltip>
                </div>
                <div className="col-3 table-cell">
                    <CustomTooltip style={{ marginLeft: "auto" }} TransitionComponent={Zoom}
                        title='The signor is the individual who signed the agreement'>
                        <div className='mx-auto'>Agreement Signor</div>
                    </CustomTooltip>
                </div>
                {/* <div className="col table-cell">Actions</div> */}
            </div>

            {attachments?.length > 0 ? (
                attachments?.map((a, ai) => (
                    <React.Fragment key={ai}>
                        <AgreementDocumentTableRow agreementDocument={a} legalDocumentTypes={legalDocumentTypes} />
                    </React.Fragment>
                ))
            ) : (
                <>
                    <div className="no-data-alert">
                        <h6>No Agreement Documents Added!</h6>
                    </div>
                </>
            )}
        </>
    )
}

export default AgreementDocumentsTable
