import React from "react"
import TabsTitles from "../../../Layout/TabsTitles"
import BreadcrumbSteps from "../../../Layout/BreadcrumbSteps"
import { Divider, Paper, Select, TextField, Button, Switch } from "@material-ui/core"
import { Tabs } from "@material-ui/core";
import { AppBar, Tab } from '@mui/material';
import * as APIs from "../../../../Data/APIs"
import { useLoader } from "../../../Layout/Loader"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TransformIcon from "@material-ui/icons/Transform"
import ScheduleIcon from "@material-ui/icons/Schedule"
import AnalystCreateTransmissionProtocol from "./AnalystCreateTransmissionProtocol"
import { CustomTooltip } from "../../../Layout/Tooltip"
import Zoom from "@material-ui/core/Zoom"
import Moment from "moment"
import { useHistory } from "react-router-dom"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import DoneOutlineIcon from "@material-ui/icons/DoneOutline"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import { FailureModal } from "../../../Modals/FailureModal"
import { useModalDialog } from "../../../Layout/ModalDialogCustomized"
import AnalystCreateFeedForms from "./AnalystCreateFeedForms"
import Input from "@material-ui/core/Input"
import ListItemText from "@material-ui/core/ListItemText"
import AnalystCreateFeedFormsWorkflow from "./AnalystCreateFeedFormWorkflow"
import AnalystCreateFeedFormDictionary from "./AnalystCreateFeedFormDictionary"
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import dateTimeSVG from "../../../../Images/dateTime.svg"
import { parseISO } from 'date-fns';
import CloseIcon from '@mui/icons-material/Close';
import AnalystCreateSchedulers from "../SharedComponents/AnalystCreateShedulers";
import SchedulerObject from "../SharedComponents/SchedulerObject";
import calendarIconSVG from "../../../../Icons/fal-calendar-alt.svg";
import plusIconSVG from "../../../../Icons/plusIcon.svg";
import minusIconSVG from "../../../../Icons/minus.svg";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

let currentTime = new Date().getTime()
currentTime = Moment(currentTime).format("YYYY-MM-DDTHH:mm:ss")

let currentDate = new Date()
currentDate = Moment(currentDate).format("YYYY-MM-DD")

function AnalystCreateFeed() {
  const dialog = useModalDialog(0)
  const { openLoader, closeLoader } = useLoader()
  const routeHistory = useHistory()
  const [, forceUpdate] = React.useReducer(x => !x, false)

  // Lookup Lists
  const [feedsTransmissionTypes, setFeedsTransmissionTypes] = React.useState([])
  const [feedsProtocolTypes, setFeedsProtocolTypes] = React.useState([])
  const [feedSSHKeys, setFeedSSHKeys] = React.useState([])
  const [structureTypes, setStructureTypes] = React.useState([])
  const [feedTypes, setFeedTypes] = React.useState([])
  const [carriers, setCarriers] = React.useState()
  const [carrierMaps, setCarrierMaps] = React.useState([])
  const [componentsList, setComponentsList] = React.useState([])
  const [componentsDFList, setComponentsDFList] = React.useState([])
  const [carrierKeys, setCarrierKeys] = React.useState([])
  const [DFsList, setDFsList] = React.useState([])

  const [businessRuleErrorList, setBusinessRuleErrorList] = React.useState([])

  // Feed Info
  const [feedName, setFeedName] = React.useState("")
  const [carrierID, setCarrierID] = React.useState("")
  const [feedType, setFeedType] = React.useState("")
  const [fileType, setFileType] = React.useState(null)
  const [dataType, setDataType] = React.useState("")
  const [feedMap, setFeedMap] = React.useState()
  const [feedTestFileName, setFeedTestFileName] = React.useState("")
  const [feedProductionFileName, setFeedProductionFileName] = React.useState("")

  const [feedOEFileName, setFeedOEFileName] = React.useState("")
  const [outputOEFileNameMsg, setOutputOEFileNameMsg] = React.useState("")

  const [feedDuplicateCode, setFeedDuplicateCode] = React.useState("")
  // const [groupNumber, setGroupNumber] = React.useState("")
  const [feedStructureType, setFeedStructureType] = React.useState(null)
  const [feedEncryptionKey, setFeedEncryptionKey] = React.useState("")
  const [selectedComponent, setSelectedComponent] = React.useState([])
  const [checkedComponent, setCheckedComponent] = React.useState(false)
  const [selectedDFComponent, setSelectedDFComponent] = React.useState([])
  const [checkedDFComponent, setCheckedDFComponent] = React.useState(false)
  const [selectedForms, setSelectedForms] = React.useState([])
  const [reason, setReason] = React.useState("");

  const [selectedBusinessRuleError, setSelectedBusinessRuleError] = React.useState([])
  const [checkedBusinessRuleError, setCheckedBusinessRuleError] = React.useState(false);

  const [toEmail, setToEmail] = React.useState("")
  const [ccEmail, setCcEmail] = React.useState("")
  const [bccEmail, setBccEmail] = React.useState("")
  const [emailSubject, setEmailSubject] = React.useState("")
  const [emailBody, setEmailBody] = React.useState("")

  const [invalidFeedNameMsg, setInvalidFeedNameMsg] = React.useState("")
  const [outputTestFileNameMsg, setOutputTestFileNameMsg] = React.useState("")
  const [outputProdFileNameMsg, setOutputProdFileNameMsg] = React.useState("")

  //Forms Workflow
  // const [DFWorkflowTypeForm, setDFWorkflowTypeForm] = React.useState("")
  // const [HRFirstNameForm, setHRFirstNameForm] = React.useState("")
  // const [HRLastNameForm, setHRLastNameForm] = React.useState("")
  const [employeeSignatureNeeded, setemployeeSignatureNeeded] = React.useState(false)
  const [hrSignatureNeeded, setHrSignatureNeeded] = React.useState(false)




  //Forms Dictionary
  const [dictionaryList, setDictionaryList] = React.useState([])

  const [planYearStartDate, setPlanYearStartDate] = React.useState(null)
  const [enablePlanYearStartDate, setEnablePlanYearStartDate] = React.useState(false)

  //Transmission Info
  const [addTransmissionProtocolClicked, setAddTransmissionProtocolClicked] = React.useState(false)
  const [feedTransmissionType, setFeedTransmissionType] = React.useState()
  const [feedProtocolType, setFeedProtocolType] = React.useState()
  const [feedSSHKey, setFeedSSHKey] = React.useState()
  const [feedSFTPSite, setFeedSFTPSite] = React.useState()
  const [feedSFTPPort, setFeedSFTPPort] = React.useState()
  const [feedSFTPUsername, setFeedSFTPUsername] = React.useState()
  const [feedSFTPPassword, setFeedSFTPPassword] = React.useState()
  const [feedSFTPDirectory, setFeedSFTPDirectory] = React.useState()

  //Scheduler Info
  const [feedSchedulers, setFeedSchedulers] = React.useState([new SchedulerObject({})])
  const [addSchedulerClicked, setAddSchedulerClicked] = React.useState(false)
  // const [feedSchedulerFrequency, setFeedSchedulerFrequency] = React.useState("")
  // const [feedCheckEnableSchedule, setFeedCheckEnableSchedule] = React.useState(false)
  // const [feedRecurrence, setFeedRecurrence] = React.useState(1)
  // const [feedSchedulerDate, setFeedSchedulerDate] = React.useState(currentDate)
  // const [feedSchedulerTime, setFeedSchedulerTime] = React.useState(currentTime)
  // const [feedSchedulerDaysSelected, setFeedSchedulerDaysSelected] = React.useState([])
  // const [feedSchedulerMonthlyType, setFeedSchedulerMonthlyType] = React.useState()
  // const [feedSchedulerMonthlyPart, setFeedSchedulerMonthlyPart] = React.useState("")
  // const [feedSchedulerMonthlyDayInMonth, setFeedSchedulerMonthlyDayInMonth] = React.useState("")
  // const [feedSchedulerMonthlyWeekdayInMonth, setFeedSchedulerMonthlyWeekdayInMonth] = React.useState("")

  //Validations
  const [requestSubmitted, setRequestSubmitted] = React.useState(false)
  const [feedNameValidationForm, setFeedNameValidationForm] = React.useState("")
  const [carrierNameValidationForm, setCarrierNameValidationForm] = React.useState("")
  const [feedTypeValidationForm, setFeedTypeValidationForm] = React.useState("")
  const [feedFileTypeValidationForm, setFeedFileTypeValidationForm] = React.useState("")
  // const [feedDataTypeValidationForm, setFeedDataTypeValidationForm] = React.useState("");
  const [feedStructureTypeValidationForm, setFeedStructureTypeValidationForm] = React.useState("")
  const [transmissionTypeValidationForm, setTransmissionTypeValidationForm] = React.useState("")
  const [sftpProtocolTypeValidationForm, setSFTPProtocolTypeValidationForm] = React.useState("")
  const [sftpSSHKeyValidationForm, setSFTPSSHKeyValidationForm] = React.useState("")
  const [sftpSiteValidationForm, setSFTPSiteValidationForm] = React.useState("")
  const [sftpPortValidationForm, setSFTPPortValidationForm] = React.useState("")
  const [sftpUsernameValidationForm, setSFTPUsernameValidationForm] = React.useState("")
  const [sftpPasswordValidationForm, setSFTPPasswordValidationForm] = React.useState("")
  const [sftpDirectoryValidationForm, setSFTPDirectoryValidationForm] = React.useState("")
  const [schedulersValidationForm, setSchedulersValidationForm] = React.useState("")
  // const [schedulersFrequencyValidationForm, setSchedulersFrequencyValidationForm] = React.useState("")
  // const [schedulersDaysValidationForm, setSchedulersDaysValidationForm] = React.useState("")
  // const [schedulersMonthlyTypeValidationForm, setSchedulersMonthlyTypeValidationForm] = React.useState()
  // const [schedulersMonthlyPartValidationForm, setSchedulersMonthlyPartValidationForm] = React.useState()
  // const [schedulersMonthlyDayInMonthValidationForm, setSchedulersMonthlyDayInMonthValidationForm] = React.useState("")
  // const [schedulersMonthlyWeekdayInMonthValidationForm, setSchedulersMonthlyWeekdayInMonthValidationForm] = React.useState("")
  const [selectFormMainTypesValidationForm, setSelectFormMainTypesValidationForm] = React.useState("")
  const [selectFormSubTypesValidationForm, setSelectFormSubTypesValidationForm] = React.useState("")
  const [reasonValidationForm, setReasonValidationForm] = React.useState("");
  const [DFTransmitterEmailValidationForm, setDFTransmitterEmailValidationForm] = React.useState("")
  const [productionCarrierEmailSubjectValidationForm, setProductionCarrierEmailSubjectValidationForm] = React.useState("")
  const [productionCarrierEmailBodyValidationForm, setProductionCarrierEmailBodyValidationForm] = React.useState("")


  const [carrierEmailSubjectValidationForm, setCarrierEmailSubjectValidationForm] = React.useState("")
  const [carrierEmailBodyValidationForm, setCarrierEmailBodyValidationForm] = React.useState("");
  const [carrierEmailToEmailValidationForm, setCarrierEmailToEmailValidationForm] = React.useState("")
  const emailBodyInit = "Hi,\n\nKindly note that the scheduled production file for the referenced feed is now submitted. For any weekly feedback or data discrepancy reports, please reach out directly to your client contact.\nFor technical/file transmission issues, please reach out to editeam@isolvedhcm.com \n\nThanks!\nisolved Integration Team";
  const FormsFeedEmailBodyInit = `Hi,\n\nKindly note that the attached Form for the referenced Carrier has been transmitted. Please process accordingly. For any feedback or data discrepancy reports, please reach out directly to the client using the contact information from this email.\n\nWe recommend reviewing your bill or carrier portal to ensure accurate and timely processing by the Carrier.\n\nThanks,\nisolved Integration Team`


  const [clientCopyContact, setClientCopyContact] = React.useState("")


  React.useEffect(() => {
    openLoader()
    APIs.getCarriers({}).then(r => setCarriers(r?.data?.map(c => c)))
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getStructureTypes().then(r => !(r?.data?.length) ? setStructureTypes([]) : setStructureTypes(r?.data?.map(c => c)))
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getFeedTypes().then(r => setFeedTypes(r?.data?.map(c => c)))
  }, [])

  React.useEffect(() => {
    if (carriers) closeLoader()
    else openLoader()
  }, [carriers])

  React.useEffect(() => {
    openLoader()
    APIs.getFeedTransmissionTypes().then(r => (setFeedsTransmissionTypes(r?.data), closeLoader()))
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getFeedProtocolTypes().then(r => (setFeedsProtocolTypes(r?.data), closeLoader()))
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getSSHKeys().then(r => (setFeedSSHKeys(r?.data), closeLoader()))
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getAllEngineComponents().then(r => (setComponentsList(r?.data), closeLoader()))
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getAllEngineDFComponents().then(r => (setComponentsDFList(r?.data), closeLoader()))
  }, [])

  //Get all All Business Rule  Error
  React.useEffect(() => {
    openLoader()
    APIs.getAllBusinessRuleError().then(r => (
      setBusinessRuleErrorList(r?.data),
      closeLoader()
    )
    )
  }, [])

  React.useEffect(() => {
    openLoader()
    APIs.getDigitalFormsList(carrierID).then(r => (setDFsList(r?.data), closeLoader()))
  }, [carrierID])

  React.useEffect(() => {
    openLoader()
    const getMapsBody = { CarrierID: carrierID }
    APIs.getCarrierMaps(getMapsBody).then(r => (!r?.data?.length ? setCarrierMaps([]) : setCarrierMaps(r?.data?.map(c => c))))
  }, [carrierID])

  React.useEffect(() => {
    openLoader()
    if (!carrierID) return
    APIs.getCarrierKeys(carrierID).then(r => setCarrierKeys(r?.data))
  }, [carrierID])

  React.useEffect(() => {
    if (carrierMaps && carrierKeys) closeLoader()
    else openLoader()
  }, [carrierMaps, carrierKeys])

  // Form Validations

  React.useEffect(() => {
    if (!feedName?.replaceAll(" ", "")) setFeedNameValidationForm("Feed name is missing, please set a unique name to the feed."); else setFeedNameValidationForm("")
  }, [feedName, feedNameValidationForm])

  React.useEffect(() => {
    if (!carrierID) setCarrierNameValidationForm("Carrier name is missing, please select a carrier from the list."); else setCarrierNameValidationForm("")
  }, [carrierID, carrierNameValidationForm])

  React.useEffect(() => {
    if (!feedType) setFeedTypeValidationForm("Feed type is missing, please select a feed type from the list."); else setFeedTypeValidationForm("")
  }, [feedType, feedTypeValidationForm])

  React.useEffect(() => {
    if (!fileType) setFeedFileTypeValidationForm("File type is missing, please select a file type from the list."); else setFeedFileTypeValidationForm("")
  }, [fileType, feedFileTypeValidationForm])

  // React.useEffect(() => {
  //     if (!dataType) setFeedDataTypeValidationForm("File type is missing, please select a data type from the list."); else setFeedDataTypeValidationForm("");
  // }, [dataType, feedDataTypeValidationForm])

  React.useEffect(() => {
    if (!feedStructureType) setFeedStructureTypeValidationForm("Feed structure type is missing, please select a structure type from the list."); else setFeedStructureTypeValidationForm("")
  }, [feedStructureType, feedStructureTypeValidationForm])

  React.useEffect(() => {
    if (!feedTransmissionType) setTransmissionTypeValidationForm("Transmission type is missing, please select one from the list."); else setTransmissionTypeValidationForm("")
  }, [feedTransmissionType, transmissionTypeValidationForm])

  React.useEffect(() => {
    if (!feedProtocolType) setSFTPProtocolTypeValidationForm("Protocol type is missing, please select one from the list."); else setSFTPProtocolTypeValidationForm("")
  }, [feedProtocolType, sftpProtocolTypeValidationForm])

  React.useEffect(() => {
    if (!feedSSHKey) setSFTPSSHKeyValidationForm("SSH key is missing, please select one from the list."); else setSFTPSSHKeyValidationForm("")
  }, [feedSSHKey, sftpSSHKeyValidationForm])

  React.useEffect(() => {
    if (!feedSFTPSite?.replaceAll(" ", "")) setSFTPSiteValidationForm("SFTP site is missing, please fill it before submitting."); else setSFTPSiteValidationForm("")
  }, [feedSFTPSite, sftpSiteValidationForm])

  React.useEffect(() => {
    if (!feedSFTPPort) setSFTPPortValidationForm("SFTP port is missing, please fill it before submitting."); else setSFTPPortValidationForm("")
  }, [feedSFTPPort, sftpPortValidationForm])

  React.useEffect(() => {
    if (!feedSFTPUsername?.replaceAll(" ", "")) setSFTPUsernameValidationForm("SFTP username is missing, please fill it before submitting."); else setSFTPUsernameValidationForm("")
  }, [feedSFTPUsername, sftpUsernameValidationForm])

  React.useEffect(() => {
    if (!feedSFTPPassword?.replaceAll(" ", "")) setSFTPPasswordValidationForm("SFTP password is missing, please fill it before submitting."); else setSFTPPasswordValidationForm("")
  }, [feedSFTPPassword, sftpPasswordValidationForm])

  React.useEffect(() => {
    if (!feedSFTPDirectory?.replaceAll(" ", "")) setSFTPDirectoryValidationForm("SFTP remote directory is missing, please fill it before submitting."); else setSFTPDirectoryValidationForm("")
  }, [feedSFTPDirectory, sftpDirectoryValidationForm])


  React.useEffect(() => {
    if (!reason?.replaceAll(" ", "").replaceAll("\n", "") && (selectedBusinessRuleError?.length > 0)) setReasonValidationForm("Ignore business rules reason is missing,  please fill it before submitting."); else setReasonValidationForm("");
  }, [reason, reasonValidationForm, selectedBusinessRuleError])

  // React.useEffect(() => {
  //   if (feedSchedulers?.filter((c) => c.Type === null)) setSchedulersFrequencyValidationForm("Please fill all Scheduler frequency fields for all Schedulers."); else setSchedulersFrequencyValidationForm("")
  // }, [feedSchedulerFrequency, schedulersFrequencyValidationForm])


  // React.useEffect(() => {
  //   if (feedSchedulerFrequency === "WeeklyScheduler" && feedSchedulerDaysSelected.length <= 0) setSchedulersDaysValidationForm("No days were selected, please select at least one day."); else setSchedulersDaysValidationForm("")
  // }, [feedSchedulerFrequency, feedSchedulerDaysSelected, schedulersDaysValidationForm])

  // React.useEffect(() => {
  //   if (feedSchedulerFrequency === "MonthlyScheduler" && !feedSchedulerMonthlyType) setSchedulersMonthlyTypeValidationForm("No monthly recurrence was selected, please select one."); else setSchedulersMonthlyTypeValidationForm("")
  // }, [feedSchedulerFrequency, feedSchedulerMonthlyType, schedulersMonthlyTypeValidationForm])

  // React.useEffect(() => {
  //   if (feedSchedulerFrequency === "MonthlyScheduler" && feedSchedulerMonthlyType === 1 && !feedSchedulerMonthlyDayInMonth) setSchedulersMonthlyDayInMonthValidationForm("Day is missing, please enter a specific day."); else setSchedulersMonthlyDayInMonthValidationForm("")
  // }, [feedSchedulerFrequency, feedSchedulerMonthlyType, feedSchedulerMonthlyDayInMonth, schedulersMonthlyDayInMonthValidationForm])

  // React.useEffect(() => {
  //   if (feedSchedulerFrequency === "MonthlyScheduler" && feedSchedulerMonthlyType === 2 && !feedSchedulerMonthlyWeekdayInMonth) setSchedulersMonthlyWeekdayInMonthValidationForm("Weekday is missing, please select one."); else setSchedulersMonthlyWeekdayInMonthValidationForm("")
  // }, [feedSchedulerFrequency, feedSchedulerMonthlyType, feedSchedulerMonthlyWeekdayInMonth, schedulersMonthlyWeekdayInMonthValidationForm])

  // React.useEffect(() => {
  //   if (feedSchedulerFrequency === "MonthlyScheduler" && feedSchedulerMonthlyType === 2 && !feedSchedulerMonthlyPart) setSchedulersMonthlyPartValidationForm("Week part is missing, please select one."); else setSchedulersMonthlyPartValidationForm("")
  // }, [feedSchedulerFrequency, feedSchedulerMonthlyType, feedSchedulerMonthlyPart, schedulersMonthlyPartValidationForm])

  React.useEffect(() => {
    if (selectedForms?.length > 0 && selectedForms?.any(s => !s.MainTypes || s.MainTypes?.length <= 0)) setSelectFormMainTypesValidationForm("Missing form main type, please select at least one"); else setSelectFormMainTypesValidationForm("")
  }, [JSON.stringify(selectedForms), selectFormMainTypesValidationForm])

  React.useEffect(() => {
    if (selectedForms?.length > 0 && selectedForms?.any(s => !s.SubTypes || s.SubTypes?.length <= 0)) setSelectFormSubTypesValidationForm("Missing form sub type, please select at least one"); else setSelectFormSubTypesValidationForm("")
  }, [JSON.stringify(selectedForms), selectFormSubTypesValidationForm])

  React.useEffect(() => {
    if (selectedDFComponent?.filter(f => f.ComponentID === 5).length > 0 && (toEmail === "" && ccEmail === "" && bccEmail === ""))
      setDFTransmitterEmailValidationForm("Please add at least one Carrier contact because Transmitter component is checked"); else setDFTransmitterEmailValidationForm("");
  }, [selectedDFComponent, toEmail, ccEmail, bccEmail, DFTransmitterEmailValidationForm])

  React.useEffect(() => {
    if ((toEmail !== "" || ccEmail !== "" || bccEmail !== "") && !emailSubject?.replaceAll(" ", ""))
      setProductionCarrierEmailSubjectValidationForm("Please add a subject to this email notification"); else setProductionCarrierEmailSubjectValidationForm("");
  }, [toEmail, ccEmail, bccEmail, emailSubject, productionCarrierEmailSubjectValidationForm])

  React.useEffect(() => {
    if ((toEmail !== "" || ccEmail !== "" || bccEmail !== "") && !emailBody?.replaceAll(" ", "").replaceAll("\n", ""))
      setProductionCarrierEmailBodyValidationForm("Please add a body to this email notification"); else setProductionCarrierEmailBodyValidationForm("");
  }, [toEmail, ccEmail, bccEmail, emailBody, productionCarrierEmailBodyValidationForm])

  // for transsimion 
  React.useEffect(() => {
    if ((toEmail !== "" || ccEmail !== "" || bccEmail !== "") && !emailSubject?.replaceAll(" ", ""))
      setCarrierEmailSubjectValidationForm("Please add a subject to this email notification"); else setCarrierEmailSubjectValidationForm("");
  }, [toEmail, ccEmail, bccEmail, emailSubject, carrierEmailSubjectValidationForm])

  React.useEffect(() => {
    if ((toEmail !== "" || ccEmail !== "" || bccEmail !== "") && !emailBody?.replaceAll(" ", "").replaceAll("\n", ""))
      setCarrierEmailBodyValidationForm("Please add a body to this email notification"); else setCarrierEmailBodyValidationForm("");
  }, [toEmail, ccEmail, bccEmail, emailBody, carrierEmailBodyValidationForm])

  React.useEffect(() => {
    if (feedTransmissionType && feedTransmissionType == 1 && toEmail === "")
      setCarrierEmailToEmailValidationForm("Please add a valid email address"); else setCarrierEmailToEmailValidationForm("");
  }, [toEmail, carrierEmailToEmailValidationForm, feedTransmissionType])


  const handleFeedNameChange = e => {
    setFeedName(e.target.value)
  }

  const handleCarrierNameChange = e => {
    setCarrierID(e.target.value)
  }

  const handleFeedTypeChange = e => {
    setFeedType(e.target.value)
  }

  const handleFileTypeChange = e => {
    setFileType(e.target.value)
  }

  const handleDataTypeChange = e => {
    setDataType(e.target.value)
  }

  const handleStructureTypeChange = e => {
    setFeedStructureType(e.target.value)
  }

  const handleTestFileNameChange = e => {
    setFeedTestFileName(e.target.value)
  }

  const handleProductionFileNameChange = e => {
    setFeedProductionFileName(e.target.value)
  }
  const handleOEFileNameChange = e => {
    setFeedOEFileName(e.target.value)
  }
  const handleToEmailChange = e => {
    setToEmail(e.target.value)
  }

  const handleCcEmailChange = e => {
    setCcEmail(e.target.value)
  }

  const handleBccEmailChange = e => {
    setBccEmail(e.target.value)
  }

  const handleEmailSubjectChange = e => {
    setEmailSubject(e.target.value)
  }

  const handleDuplicateCodeChange = e => {
    setFeedDuplicateCode(e.target.value)
  }

  // const handleGroupNumberChange = (e) => {
  //   setGroupNumber(e.target.value);
  // }

  const handleEncryptionKeyChange = e => {
    setFeedEncryptionKey(e.target.value)
  }

  const handleComponentChange = value => e => {
    setCheckedComponent(e.target.checked)
  }
  const handleClientCopyContact = e => {
    setClientCopyContact(e.target.value)
  }

  const handleBusinessRuleErrorClick = (value) => (event) => {
    let selectedBusinessRuleErrorArray = selectedBusinessRuleError;
    if (!value || value === "") { selectedBusinessRuleErrorArray = []; }
    else if (selectedBusinessRuleErrorArray.indexOf(value) >= 0) {
      selectedBusinessRuleErrorArray.splice(selectedBusinessRuleErrorArray.indexOf(value), 1);
    }
    else {
      selectedBusinessRuleErrorArray.push(value);
    }
    setSelectedBusinessRuleError([...selectedBusinessRuleErrorArray]);
  }

  const handleBusinessRuleErrorChange = (value) => (e) => {
    setCheckedBusinessRuleError(e.target.checked);
  }
  const handleComponentClick = value => event => {
    let componentsListArray = componentsList
    let selectedComponentArray = selectedComponent
    let selectedIndex = componentsListArray.indexOf(value)
    if (selectedIndex > -1) {
      if (!value || value === "") selectedComponentArray = []
      else if (selectedComponentArray.indexOf(value) >= 0) selectedComponentArray.splice(selectedComponentArray.indexOf(value), selectedIndex + 6)
      else selectedComponentArray = componentsListArray.slice(0, selectedIndex + 1)
    }
    setSelectedComponent([...selectedComponentArray])
  }

  React.useEffect(() => {
    if (componentsList[0] !== undefined) selectedComponent?.push(componentsList[0])
  }, [componentsList])

  const handleComponentDFChange = value => e => {
    setCheckedDFComponent(e.target.checked)
  }

  const handleComponentDFClick = value => event => {
    let componentsListDFArray = componentsDFList
    let selectedComponentDFArray = selectedDFComponent
    let selectedIndex = componentsListDFArray.indexOf(value)
    if (selectedIndex > -1) {
      if (!value || value === "") selectedComponentDFArray = []
      else if (selectedComponentDFArray.indexOf(value) >= 0) selectedComponentDFArray.splice(selectedComponentDFArray.indexOf(value), selectedIndex + 6)
      else selectedComponentDFArray = componentsListDFArray.slice(0, selectedIndex + 1)
    }
    setSelectedDFComponent([...selectedComponentDFArray])
  }

  React.useEffect(() => {
    if (componentsDFList[0] !== undefined) selectedDFComponent?.push(componentsDFList[0])
  }, [componentsDFList])

  const handleSelectFormsClick = value => e => {
    let selectedFormsArray = selectedForms
    if (!value || value === "") selectedFormsArray = []
    else if (selectedFormsArray.indexOf(value) >= 0) {
      selectedFormsArray.splice(selectedFormsArray.indexOf(value), 1)
    } else {
      selectedFormsArray.push(value)
    }
    setSelectedForms([...selectedFormsArray])
  }

  const handleFeedNameCharRestriction = e => {
    if (e.key === "'" || e.key === "/" || e.key === "\\") {
      e.preventDefault()
      setInvalidFeedNameMsg(`Invalid feed name, feed name should not contain ${e.key}`)
    } else {
      setInvalidFeedNameMsg("")
    }
  }

  const handleOutputTestFileNameCharRestriction = e => {
    let invalidChar = ["/", "\\", "}", "{", "^", "%", "'", "]", "[", "~", "#", "|", '"', "`"]
    if (invalidChar.any(s => s === e.key)) {
      e.preventDefault()
      setOutputTestFileNameMsg(`Invalid output file name, output file name should not contain ${e.key}`)
    } else {
      setOutputTestFileNameMsg("")
    }
  }

  const handleOutputProdFileNameCharRestriction = e => {
    let invalidChar = ["/", "\\", "}", "{", "^", "%", "'", "]", "[", "~", "#", "|", '"', "`"]
    if (invalidChar.any(s => s === e.key)) {
      e.preventDefault()
      setOutputProdFileNameMsg(`Invalid output file name, output file name should not contain ${e.key}`)
    } else {
      setOutputProdFileNameMsg("")
    }
  }

  React.useEffect(() => {
    if (feedType === 2) setAddTransmissionProtocolClicked(false);
  }, [feedType])

  const handleOutputOEFileNameCharRestriction = e => {
    let invalidChar = ["/", "\\", "}", "{", "^", "%", "'", "]", "[", "~", "#", "|", '"', "`"]
    if (invalidChar.any(s => s === e.key)) {
      e.preventDefault();
      setOutputOEFileNameMsg(`Invalid output file name, output file name should not contain ${e.key}`);
    } else {
      setOutputOEFileNameMsg("");
    }
  }

  const handlePlanYearStartDateChange = date => {
    setPlanYearStartDate(Moment(date).format("YYYY-MM-DD"));
  }

  const handleEnablePlanYearStartDateChange = e => {
    setEnablePlanYearStartDate(e.target.checked);
  }

  const submitCreateFeed = e => {
    e.preventDefault();
    setRequestSubmitted(true);
    if (addSchedulerClicked) {
      // conditon for all errors 
      if (schedulersValidationForm) return;
    }
    if (reasonValidationForm) return;
    if (productionCarrierEmailSubjectValidationForm || productionCarrierEmailBodyValidationForm) return;
    let createFeed = {}
    if (feedType === 2) {
      //Forms Feeds

      //no scheduler
      if (selectedForms?.length > 0) {
        //forms attached
        if (feedNameValidationForm || carrierNameValidationForm || feedTypeValidationForm || DFTransmitterEmailValidationForm || selectFormMainTypesValidationForm || selectFormSubTypesValidationForm) return
      } else {
        if (feedNameValidationForm || carrierNameValidationForm || feedTypeValidationForm || DFTransmitterEmailValidationForm) return
      }
      openLoader()
      createFeed = {
        FeedName: feedName,
        CarrierID: carrierID,
        FeedMapTypeID: feedType,
        FeedTypeID: fileType,
        DataType: dataType,
        TransmissionTypeID: feedTransmissionType,
        TestOutputFileName: feedTestFileName,
        ProdOutputFileName: feedProductionFileName,
        ProductionClientCopyEmail: {
          To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")

        },
        EmailObject: {
          To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
          Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
          Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
          EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
          EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
        },
        DuplicatePlanCode: feedDuplicateCode,
        // CarrierClientNumber: groupNumber,
        EncryptionKeyID: feedEncryptionKey,
        EngineComponents: selectedDFComponent.map(c => c.ComponentID),
        DigitalForms: selectedForms?.map(form => ({
          DigitalFormID: form?.Id,
          MainTypesIDs: form?.MainTypes?.map(s => s.Id),
          SubTypesIDs: form?.SubTypes?.map(s => s.Id),
        })),
        // WorkflowConfigurations: {
        //   WorkflowConfigurationObject:
        //     DFWorkflowTypeForm === "1" ? //Fully Automated
        //       {
        //         TypeID: DFWorkflowTypeForm,
        //       }
        //       : DFWorkflowTypeForm === "2" ? //Approval Required
        //         {
        //           TypeID: DFWorkflowTypeForm,
        //           HRFirstName: HRFirstNameForm,
        //           HRLastName: HRLastNameForm,
        //         }
        //         : DFWorkflowTypeForm === "3" ? //Employee Signature Required
        //           {
        //             TypeID: DFWorkflowTypeForm,
        //           }
        //           : {},
        // },
        WorkflowConfigurations: {
          WorkflowConfigurationObject:
            { TypeID: employeeSignatureNeeded ? 3 : 1 }
        },
        HrSignatureRequired: hrSignatureNeeded ? 1 : 0,
        Dictionaries: dictionaryList,
      }
      // }
    } else {
      //EDI

      if (addTransmissionProtocolClicked) {
        //add transmission protocol only
        if (feedTransmissionType == 2) {
          //SFTP transmission protocol
          if (feedProtocolType === "SCP SFTP (SSH)") {
            //no pw needed and has ssh key
            if (feedNameValidationForm || carrierNameValidationForm || feedTypeValidationForm || feedFileTypeValidationForm || feedStructureTypeValidationForm || transmissionTypeValidationForm || sftpProtocolTypeValidationForm || sftpSiteValidationForm || sftpPortValidationForm || sftpUsernameValidationForm || sftpDirectoryValidationForm || sftpSSHKeyValidationForm) return
          } else {
            //pw needed
            if (feedNameValidationForm || carrierNameValidationForm || feedTypeValidationForm || feedFileTypeValidationForm || feedStructureTypeValidationForm || transmissionTypeValidationForm || sftpProtocolTypeValidationForm || sftpSiteValidationForm || sftpPortValidationForm || sftpUsernameValidationForm || sftpPasswordValidationForm || sftpDirectoryValidationForm) return
          }
          openLoader()
          createFeed = {
            FeedName: feedName,
            CarrierID: carrierID,
            FeedMapTypeID: feedType,
            FeedTypeID: fileType,
            DataType: dataType,
            TransmissionTypeID: feedTransmissionType,
            TestOutputFileName: feedTestFileName,
            ProdOutputFileName: feedProductionFileName,
            OEOutputFileName: feedOEFileName,
            EmailObject: {
              To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
              Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
              Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
              EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
              EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
            },
            ProductionClientCopyEmail: {
              To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(","),
            },
            DuplicatePlanCode: feedDuplicateCode,
            // CarrierClientNumber: groupNumber,
            MapID: feedMap?.Id,
            EncryptionKeyID: feedEncryptionKey,
            EngineComponents: selectedComponent.map(c => c.ComponentID),
            BusinessRuleErrorIDs: selectedBusinessRuleError.map(c => c.Id),
            DisableBusinessRulesReason: reason,
            PlanYearStartDate: planYearStartDate,
            EnablePlanYearStartDate: enablePlanYearStartDate,
            StructureTypeID: feedStructureType,
            ConnectionObject: {
              Type: feedProtocolType,
              Site: feedSFTPSite,
              Port: feedSFTPPort,
              UserName: feedSFTPUsername,
              Password: feedSFTPPassword,
              FeedKeyId: feedSSHKey,
              RemoteDirectory: feedSFTPDirectory,
            },
          }
        }
        // email
        else if (feedTransmissionType == 1) {
          if (feedNameValidationForm || carrierNameValidationForm || feedTypeValidationForm || feedFileTypeValidationForm || feedStructureTypeValidationForm || transmissionTypeValidationForm || carrierEmailSubjectValidationForm || carrierEmailBodyValidationForm || carrierEmailToEmailValidationForm) return
          openLoader()
          createFeed = {
            FeedName: feedName,
            CarrierID: carrierID,
            FeedMapTypeID: feedType,
            FeedTypeID: fileType,
            DataType: dataType,
            TransmissionTypeID: feedTransmissionType,
            TestOutputFileName: feedTestFileName,
            ProdOutputFileName: feedProductionFileName,
            OEOutputFileName: feedOEFileName,
            ProductionClientCopyEmail: {
              To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
            },
            ConnectionObject: {
              EmailObject: {
                To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
                EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
                EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
              },
              Type: "Email"
            },
            DuplicatePlanCode: feedDuplicateCode,
            // CarrierClientNumber: groupNumber,
            MapID: feedMap?.Id,
            EncryptionKeyID: feedEncryptionKey,
            EngineComponents: selectedComponent.map(c => c.ComponentID),
            BusinessRuleErrorIDs: selectedBusinessRuleError.map(c => c.Id),
            DisableBusinessRulesReason: reason,
            PlanYearStartDate: planYearStartDate,
            EnablePlanYearStartDate: enablePlanYearStartDate,
            StructureTypeID: feedStructureType,
          }
        }
        else {
          //manual 
          if (feedNameValidationForm || carrierNameValidationForm || feedTypeValidationForm || feedFileTypeValidationForm || feedStructureTypeValidationForm || transmissionTypeValidationForm) return
          openLoader()
          createFeed = {
            FeedName: feedName,
            CarrierID: carrierID,
            FeedMapTypeID: feedType,
            FeedTypeID: fileType,
            DataType: dataType,
            TransmissionTypeID: feedTransmissionType,
            TestOutputFileName: feedTestFileName,
            ProdOutputFileName: feedProductionFileName,
            OEOutputFileName: feedOEFileName,
            ProductionClientCopyEmail: {
              To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
            },
            EmailObject: {
              To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
              Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
              Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
              EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
              EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
            },
            DuplicatePlanCode: feedDuplicateCode,
            // CarrierClientNumber: groupNumber,
            MapID: feedMap?.Id,
            EncryptionKeyID: feedEncryptionKey,
            EngineComponents: selectedComponent.map(c => c.ComponentID),
            BusinessRuleErrorIDs: selectedBusinessRuleError.map(c => c.Id),
            DisableBusinessRulesReason: reason,
            PlanYearStartDate: planYearStartDate,
            EnablePlanYearStartDate: enablePlanYearStartDate,
            StructureTypeID: feedStructureType,
          }
        }
      } else {
        //no add scheduler and no add transmission
        if (feedNameValidationForm || carrierNameValidationForm || feedTypeValidationForm || feedFileTypeValidationForm || feedStructureTypeValidationForm) return

        openLoader()
        createFeed = {
          FeedName: feedName,
          CarrierID: carrierID,
          FeedMapTypeID: feedType,
          FeedTypeID: fileType,
          DataType: dataType,
          TestOutputFileName: feedTestFileName,
          ProdOutputFileName: feedProductionFileName,
          OEOutputFileName: feedOEFileName,
          ProductionClientCopyEmail: {
            To: clientCopyContact && clientCopyContact.replaceAll(";", ",").replaceAll(" ", "").split(",")
          },
          EmailObject: {
            To: toEmail && toEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
            Cc: ccEmail && ccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
            Bcc: bccEmail && bccEmail.replaceAll(";", ",").replaceAll(" ", "").split(","),
            EmailSubject: (toEmail || ccEmail || bccEmail) ? emailSubject : "",
            EmailBody: (toEmail || ccEmail || bccEmail) ? emailBody.replaceAll("\n", "<br/>") : "",
          },
          DuplicatePlanCode: feedDuplicateCode,
          // CarrierClientNumber: groupNumber,
          MapID: feedMap?.Id,
          EncryptionKeyID: feedEncryptionKey,
          EngineComponents: selectedComponent.map(c => c.ComponentID),
          BusinessRuleErrorIDs: selectedBusinessRuleError.map(c => c.Id),
          DisableBusinessRulesReason: reason,
          PlanYearStartDate: planYearStartDate,
          EnablePlanYearStartDate: enablePlanYearStartDate,
          StructureTypeID: feedStructureType,
        }
      }
    }

    if (addSchedulerClicked) {

      let dataScheduler = feedSchedulers.map((s, si) => {
        if (s.Type === "WeeklyScheduler") {
          return {
            Type: s.Type,
            Enabled: s.Enabled,
            Recurrence: s.Recurrence,
            StartDate: s.StartDate,
            EndDate: null,
            DayFrequency: 0,
            DayFrequencyRecurrence: null,
            StartTime: s.StartTime,
            EndTime: null,
            WeekDay: s.WeekDay,
            StartDateTime: s.StartDateTime,
          }
        }
        if (s.Type === "MonthlyScheduler") {
          return {
            Type: s.Type,
            Enabled: s.Enabled,
            Recurrence: s.Recurrence,
            StartDate: s.StartDate,
            EndDate: null,
            DayFrequency: 0,
            DayFrequencyRecurrence: null,
            StartTime: s.StartTime,
            EndTime: null,
            WeekDay: s.WeekDay,
            DayOfMonth: s.DayOfMonth,
            MonthlyType: s.MonthlyType,
            PartType: s.PartType,
            StartDateTime: s.StartDateTime,
          }
        }

      });
      // make arrays of scheduler
      createFeed.SchedulesObject = [...dataScheduler];

    }



    APIs.createFeed(createFeed)
      .then(r => {
        if (r?.success === "0") {
          FailureModal(dialog, {
            title: "Failure",
            body: r?.msg,
          })
        } else {
          routeHistory.push(`/Feeds/ViewFeed/${r?.FeedID}`)
        }
      })
      .catch(s =>
        FailureModal(dialog, {
          title: "Failure",
          body: "Error processing request",
        })
      )
      .finally(t => closeLoader())
  }

  React.useEffect(() => {

    if (feedType == 1) {
      setEmailBody(emailBodyInit);
    } else if (feedType == 2) {
      setEmailBody(FormsFeedEmailBodyInit);
    } else {
      setEmailBody("");
    }
  }, [feedType])


  return (
    <div id="create-feed">
      <BreadcrumbSteps breadcrumbTitleCurrent="Create Feed" isCurrent={true} />
      <TabsTitles tabTitle={["Create Feed"]} />
      <Paper elevation={0} className="p-5">
        <div className="row w-100 alignvh-center">
          {/* <h5 className="black1 fw-700">Feed information</h5> */}
          <div className="ml-auto">
            <CustomTooltip TransitionComponent={Zoom} title={addSchedulerClicked ? "Please remove scheduler configuration first" : feedType === 2 ? "This option is not applicable with Forms feeds" : ""}>
              <span>
                <Button variant="contained" className="blue-outlined-btn mr-2 mb-2" onClick={() => setAddTransmissionProtocolClicked(!addTransmissionProtocolClicked)} disabled={addSchedulerClicked || feedType === 2}>
                  {addTransmissionProtocolClicked === false ?

                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt--2">
                      <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                    </svg> :
                    <svg width="20" height="20" viewBox="0 0 20 20" className="mt--2" >
                      <use xlinkHref={`${minusIconSVG}#minus`}></use>
                    </svg>
                  }


                  <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                    {addTransmissionProtocolClicked === false ? "Add" : "Remove"} Transmission Protocol
                  </label>
                </Button>
              </span>
            </CustomTooltip>
            {/* <CustomTooltip TransitionComponent={Zoom} title={addTransmissionProtocolClicked === false && feedType !== 2 ? "Please add transmission protocol first" : feedStructureType === 3 ? "Scheduler is not applicable as it is a 401K feed" : ""}>
              <span>
                <Button variant="contained" className="black-btn mr-2" onClick={() => setAddSchedulerClicked(!addSchedulerClicked)} disabled={(addTransmissionProtocolClicked === false && feedType !== 2) || feedStructureType === 3}>
                  <ScheduleIcon />
                  <label style={{ cursor: "pointer" }} className="ml-2 mb-0">
                    {addSchedulerClicked === false ? "Add" : "Remove"} Scheduler Configuration
                  </label>
                </Button>
              </span>
            </CustomTooltip> */}
          </div>
        </div>
        {/* <Divider className="mt-3 mb-5" /> */}
        <form onSubmit={submitCreateFeed}>
          {/* <div className="row"> */}
          <Accordion defaultExpanded>
            <AccordionSummary
              aria-controls="panel2-content"
              id="panel-header-cr-config"
              onClick={(event) => {
                event.stopPropagation();
              }}
              style={{ pointerEvents: 'none' }}
            >
              <span>Feed information</span>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <h6 className="form-title">Feed Name
                    <span className="text-danger mb-2 ml-2">*</span></h6>

                  <input onKeyDown={handleFeedNameCharRestriction} type="text" className="text-input w-100" value={feedName} onChange={handleFeedNameChange} />
                  <div className="mt-2 text-danger">{invalidFeedNameMsg}</div>
                  <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                    {feedNameValidationForm}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <h6 className="form-title">Carrier Name
                    <span className="text-danger mb-2 ml-2">*</span></h6>

                  <Autocomplete
                    options={carriers ?? []}
                    autoHighlight
                    getOptionLabel={option => option?.CarrierName}
                    onChange={(e, value) => {
                      setCarrierID(value?.CarrierID ?? "")
                    }}
                    renderOption={option => <React.Fragment>{option?.CarrierName}</React.Fragment>}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "off", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                    {carrierNameValidationForm}
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <h6 className="form-title">Feed Type
                    <span className="text-danger mb-2 ml-2">*</span></h6>

                  <FormControl label="Select feed type" focused={false} variant="outlined" className="w-100">
                    <Select className="text-dropdown" value={feedType} onChange={handleFeedTypeChange}>
                      {feedTypes?.map((c, ci) => (
                        <MenuItem key={`feed-type-${ci}`} value={c?.Id}>
                          {c?.Name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                    {feedTypeValidationForm}
                  </div>
                </Grid>

                {feedType === 1 && (
                  <React.Fragment>
                    <Grid item xs={6}>
                      <h6 className="form-title">File Type
                        <span className="text-danger mb-2 ml-2">*</span></h6>

                      <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                        <Select className="text-dropdown" value={fileType} onChange={handleFileTypeChange}>
                          <MenuItem value="1">Single Tenant</MenuItem>
                          <MenuItem value="2">Multi Tenant</MenuItem>
                        </Select>
                      </FormControl>
                      <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                        {feedFileTypeValidationForm}
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                      <h6 className="form-title">Data Type</h6>

                      <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                        <Select className="text-dropdown" value={dataType} onChange={handleDataTypeChange}>
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="Changes Only File">Changes Only File</MenuItem>
                          <MenuItem value="Full File">Full File</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <div className="mt-2 text-danger" hidden={!requestSubmitted}>{feedDataTypeValidationForm}</div> */}
                    </Grid>

                    <Grid item xs={6}>
                      <h6 className="form-title">Structure Type
                        <span className="text-danger mb-2 ml-2">*</span> </h6>

                      <FormControl label="Select Phase" focused={false} variant="outlined" className="w-100">
                        <Select className="text-dropdown" value={feedStructureType} onChange={handleStructureTypeChange}>
                          {structureTypes?.map((c, ci) => (
                            <MenuItem key={`structure-type-${ci}`} value={c?.StructureTypeID}>
                              {c?.StructureTypeName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                        {feedStructureTypeValidationForm}
                      </div>
                    </Grid>
                  </React.Fragment>
                )}

                <Grid item xs={6}>
                  <h6 className="form-title">Output Test File Name</h6>

                  <input onKeyDown={handleOutputTestFileNameCharRestriction} type="text" className="text-input w-100" value={feedTestFileName} onChange={handleTestFileNameChange} />
                  <div className="mt-2 text-danger">{outputTestFileNameMsg}</div>
                </Grid>

                <Grid item xs={6}>
                  <h6 className="form-title">Output Production File Name</h6>

                  <input onKeyDown={handleOutputProdFileNameCharRestriction} type="text" className="text-input w-100" value={feedProductionFileName} onChange={handleProductionFileNameChange} />
                  <div className="mt-2 text-danger">{outputProdFileNameMsg}</div>
                </Grid>

                {feedType === 1 && (
                  <>
                    {/* Output OE File  */}
                    <Grid item xs={6}>
                      <h6 className="form-title">Output OE File Name</h6>

                      <input onKeyDown={handleOutputOEFileNameCharRestriction} type="text" className="text-input w-100" value={feedOEFileName} onChange={handleOEFileNameChange} />
                      <div className="mt-2 text-danger">{outputOEFileNameMsg}</div>
                    </Grid>
                  </>
                )}
                {feedTransmissionType != 1 && (
                  <>
                    <Grid item xs={6}>
                      <h6 className="form-title">Production Carrier Contact</h6>

                      <div className="components-div pb-3">
                        <div className="mt-3 mb-2">
                          <span>You can enter multiple emails here separated by commas</span>
                        </div>
                        <div className="row">
                          <div className="col-xl-2 mt-3 alignvh-center">
                            <h6 className="form-title">To:</h6>
                          </div>
                          <div className="col-xl-10 mt-3">
                            <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={toEmail} onChange={handleToEmailChange} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-2 mt-3 alignvh-center">
                            <h6 className="form-title">Cc:</h6>
                          </div>
                          <div className="col-xl-10 mt-3">
                            <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={ccEmail} onChange={handleCcEmailChange} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-2 mt-3 alignvh-center">
                            <h6 className="form-title">Bcc:</h6>
                          </div>
                          <div className="col-xl-10 mt-3">
                            <input type="email" autoComplete="new-password" multiple className="text-input w-100" value={bccEmail} onChange={handleBccEmailChange} />
                          </div>
                        </div>
                        {(toEmail !== "" || ccEmail !== "" || bccEmail !== "") && (
                          <>
                            <div className="row">
                              <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Subject:</h6>
                              </div>
                              <div className="col-xl-10 mt-3">
                                <input autoComplete="new-password" className="text-input w-100" value={emailSubject} onChange={handleEmailSubjectChange} />
                              </div>
                              <div className="col-xl-2 mt-1 alignvh-center"></div>
                              <div className="col-xl-10 mt-1">
                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{productionCarrierEmailSubjectValidationForm}</div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-2 mt-3 alignvh-center">
                                <h6 className="form-title">Body:</h6>
                              </div>
                              <div className="col-xl-10 mt-3">
                                <textarea type="text" className="action-item-textarea w-100" value={emailBody} onChange={(e) => { setEmailBody(e.target.value) }} rows={4} />
                              </div>
                              <div className="col-xl-2 mt-1 alignvh-center"></div>
                              <div className="col-xl-10 mt-1">
                                <div className="mt-2 text-danger" hidden={!requestSubmitted}>{productionCarrierEmailBodyValidationForm}</div>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                          {DFTransmitterEmailValidationForm}
                        </div>
                      </div>
                    </Grid>
                  </>)}



                <Grid item xs={6}>
                  <h6 className="form-title">Production Client Copy Contact</h6>

                  <input type="email" placeholder="You can enter multiple emails here separated by commas" autoComplete="new-password" multiple className="text-input w-100" value={clientCopyContact} onChange={handleClientCopyContact} />
                </Grid>

                {feedType === 1 && (
                  <>
                    <Grid item xs={6}>
                      <h6 className="form-title">Map</h6>

                      <Autocomplete
                        options={carrierMaps}
                        autoHighlight
                        getOptionLabel={option => option.Name}
                        onChange={(e, newValue) => {
                          setFeedMap(newValue)
                        }}
                        renderOption={option => <React.Fragment>{option.Name}</React.Fragment>}
                        renderInput={params => (
                          <TextField
                            className="text-dropdown"
                            {...params}
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "off", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={6}>
                  <h6 className="form-title">Duplicate Plan Code</h6>

                  <textarea type="text" className="action-item-textarea w-100" value={feedDuplicateCode} onChange={handleDuplicateCodeChange} rows={4} />
                </Grid>

                {/* <div className="col-xl-2 mt-3 alignvh-center">
              <h6 className="form-title">Carrier Client Number</h6>
            </div>
            <div className="col-xl-10 mt-3">
              <input type="text" className="text-input w-100" value={groupNumber} onChange={handleGroupNumberChange} />
            </div> */}

                {feedType === 1 && (
                  <>
                    <Grid item xs={6}>
                      <h6 className="form-title">Encryption Key</h6>

                      <FormControl label="Select key" focused={false} variant="outlined" className="w-100">
                        <Select className="text-dropdown" value={feedEncryptionKey} onChange={handleEncryptionKeyChange}>
                          <MenuItem value="" className="color grey4">
                            Reset...
                          </MenuItem>
                          {carrierKeys?.map((c, ci) => (
                            <MenuItem key={`carrier-key-${ci}`} value={c?.Id}>{`${c?.Name} || ${c?.KeyID} || ${c?.Fingerprint}`}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </>
                )}

                <Grid item xs={6}>
                  <h6 className="form-title">Components</h6>

                  <div className="components-div">
                    {feedType === 2
                      ? componentsDFList?.map((c, ci) => (
                        <React.Fragment key={`components-DF-list-${ci}`}>
                          <FormControlLabel
                            aria-label="Acknowledge"
                            onClick={event => event.stopPropagation()}
                            onFocus={event => event.stopPropagation()}
                            control={
                              <Checkbox
                                id={`component-DF-option-${ci}`}
                                value={c}
                                icon={<CheckBoxOutlineBlankIcon />}
                                checked={selectedDFComponent && selectedDFComponent.indexOf(c) >= 0}
                                disabled={selectedDFComponent.indexOf(c) == 0 || ci == 3 || ci == 4}
                                onChange={handleComponentDFChange(c)}
                                onClick={handleComponentDFClick(c)}
                                checkedIcon={<DoneOutlineIcon />}
                                color="default"
                              />
                            }
                            label={c?.ComponentName}
                          />
                          <br />
                        </React.Fragment>
                      ))
                      : componentsList?.map((c, ci) => (
                        <React.Fragment key={`components-list-${ci}`}>
                          <FormControlLabel aria-label="Acknowledge" onClick={event => event.stopPropagation()} onFocus={event => event.stopPropagation()} control={<Checkbox id={`component-option-${ci}`} value={c} icon={<CheckBoxOutlineBlankIcon />} checked={selectedComponent && selectedComponent.indexOf(c) >= 0} disabled={selectedComponent.indexOf(c) == 0} onChange={handleComponentChange(c)} onClick={handleComponentClick(c)} checkedIcon={<DoneOutlineIcon />} color="default" />} label={c?.ComponentName} />
                          <br />
                        </React.Fragment>
                      ))}
                  </div>
                </Grid>

                {feedType === 1 && (
                  <>
                    <Grid item xs={6}>
                      <h6 className="form-title">Ignore Business Rules</h6>

                      <div className="components-div">
                        {businessRuleErrorList?.map((c, ci) => (
                          <React.Fragment key={`businessRuleError-list-${ci}`}>
                            <FormControlLabel aria-label="Acknowledge"
                              onClick={event => event.stopPropagation()}
                              onFocus={event => event.stopPropagation()}
                              control={<Checkbox id={`businessRuleError-option-${ci}`}
                                value={c} icon={<CheckBoxOutlineBlankIcon />}
                                checked={(selectedBusinessRuleError && selectedBusinessRuleError.indexOf(c) >= 0)}
                                onChange={handleBusinessRuleErrorChange(c)}
                                onClick={handleBusinessRuleErrorClick(c)}
                                checkedIcon={<DoneOutlineIcon />}
                                color="default" />} label={c?.Name} />
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    </Grid>

                    {selectedBusinessRuleError.length > 0 &&
                      <>
                        <Grid item xs={6}>
                          <h6 className="form-title">Ignore Business Rules Reason
                            <span className="text-danger mb-2 ml-2">*</span>
                          </h6>


                          <textarea
                            type="text"
                            className="action-item-textarea w-100"
                            value={reason}
                            onChange={(e) => {
                              setReason(e.target.value);
                            }}
                            rows={4}
                          />

                          <div className="mt-2 text-danger" hidden={!requestSubmitted}>
                            {reasonValidationForm}
                          </div>
                        </Grid>
                      </>
                    }
                  </>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>

          {feedType === 2 && (
            <>
              <Accordion defaultExpanded className="mt-5">
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel-header-cr-config"

                // style={{ pointerEvents: 'none' }}
                >
                  <span>Digitized Form</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      {/* <h6 className="form-title">Forms</h6> */}

                      <div style={{ display: "inline-grid", width: "100%" }}>
                        <FormControl className="multiple-select-dropdown" label="Select Forms" focused={false}>
                          <Select
                            className="w-100"
                            value={selectedForms}
                            onChange={e => {
                              setSelectedForms([...e.target.value])
                              forceUpdate()
                            }}
                            variant="outlined"
                            multiple
                            input={<Input />}
                            renderValue={selected => selected.map(s => s?.Name).join(", ")}
                          >
                            {DFsList?.map((c, ci) => (
                              <MenuItem key={`DFs-${ci}`} value={c}>
                                <Checkbox checked={selectedForms.indexOf(c) > -1} />
                                <ListItemText primary={c.Name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                    {selectedForms?.length > 0 && (
                      <>
                        <Grid item xs={12}>
                          <div className="col-xl-2 mt-3 alignvh-center"></div>
                          <div className=" mt-3">
                            <div className="components-div">
                              {selectedForms?.map((f, fi) => (
                                <div key={`selected-forms-${fi}`} className="forms-list m-3">
                                  <AnalystCreateFeedForms selectedForm={f} index={fi} onSelectFormMainTypesValidationForm={s => setSelectFormMainTypesValidationForm(s)} onSelectFormSubTypesValidationForm={s => setSelectFormSubTypesValidationForm(s)} requestSubmitted={requestSubmitted} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
          {/* </div> */}

          {feedType === 2 && (
            <>
              <Accordion defaultExpanded className="mt-5">
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel-header-cr-config"

                // style={{ pointerEvents: 'none' }}
                >
                  <span>Workflow Configuration</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      {/* Forms Workflow Configurations */}
                      <div id="transmission-config" >
                        {/* <div className="row w-100 alignvh-center">
                  <h5 className="black1 fw-700">Workflow Configuration</h5>
                </div> */}

                        {/* <Divider className="mt-3 mb-5" /> */}
                        <FormControlLabel
                          className="dependents-switch"
                          control={
                            <Switch
                              checked={employeeSignatureNeeded}
                              onChange={() => setemployeeSignatureNeeded(!employeeSignatureNeeded)}
                            />
                          }
                          label="Employee Signature Required"
                        />
                        {/* <AnalystCreateFeedFormsWorkflow
                  onDFWorkflowTypeForm={s => setDFWorkflowTypeForm(s)}
                  onHRFirstNameForm={s => setHRFirstNameForm(s)}
                  onHRLastNameForm={s => setHRLastNameForm(s)}
                /> */}
                        <br></br>
                        <FormControlLabel
                          className="dependents-switch"
                          control={
                            <Switch
                              checked={hrSignatureNeeded}
                              onChange={() => setHrSignatureNeeded(!hrSignatureNeeded)}
                            />
                          }
                          label="HR Signature Required"
                        />
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion defaultExpanded className="mt-5">
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel-header-cr-config"

                // style={{ pointerEvents: 'none' }}
                >
                  <span>Dictionary</span>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={12}>
                      {/* Forms Dictionary */}
                      <div id="transmission-config" >
                        {/* <div className="row w-100 alignvh-center">
                  <h5 className="black1 fw-700">Dictionary</h5>
                </div> */}
                        {/* <Divider className="mt-3 mb-5" /> */}
                        <AnalystCreateFeedFormDictionary dictionaryList={dictionaryList} onDictionaryList={d => setDictionaryList(d)} parentForceUpdate={forceUpdate} />
                      </div>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}

          {feedType !== 2 && (
            <div id="open-enrollment" hidden style={{ marginTop: "50px" }}>
              <div className="row w-100 alignvh-center">
                <h5 className="black1 fw-700">Open Enrollment</h5>
              </div>
              <Divider className="mt-3 mb-5" />
              <div className="row alignvh-center">
                <div className="col-xl-2 mt-3 alignvh-center">
                  <h6 className="form-title">Plan Year Start Date</h6>
                </div>
                <div className="col-xl-10 mt-3 text-input w-100" style={{ padding: "0 20px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      className="w-100"
                      autoOk={true}
                      disableToolbar
                      variant="inline"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="frequency-date"
                      value={planYearStartDate !== "Invalid date" ? planYearStartDate === null ? planYearStartDate : parseISO(planYearStartDate) : null}
                      onChange={handlePlanYearStartDateChange}
                      keyboardIcon={
                        <svg width="14" height="16" viewBox="0 0 14 16">
                          <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                        </svg>
                      }
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="col-xl-2 mt-3">
                  <h6 className="form-title">Hold Plan Year Start Date</h6>
                </div>
                <div className="col-xl-10 mt-3">
                  <FormControlLabel aria-label="Acknowledge" onClick={event => event.stopPropagation()} onFocus={event => event.stopPropagation()} control={<Checkbox value={enablePlanYearStartDate} icon={<CheckBoxOutlineBlankIcon />} checked={enablePlanYearStartDate} disabled={!planYearStartDate || planYearStartDate === "Invalid date"} onClick={handleEnablePlanYearStartDateChange} checkedIcon={<DoneOutlineIcon />} color="default" />} />
                </div>
              </div>
            </div>
          )}


          {addTransmissionProtocolClicked && (
            <Accordion defaultExpanded className="mt-5">
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel-header-cr-config"

              // style={{ pointerEvents: 'none' }}
              >
                <span>Transmission Protocol</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <div id="transmission-config" >
                      {/* <div className="row w-100 alignvh-center">
                <h5 className="black1 fw-700">Transmission Protocol</h5>
              </div> */}
                      {/* <Divider className="mt-3 mb-5" /> */}
                      <AnalystCreateTransmissionProtocol onTransmissionTypeChange={f => setFeedTransmissionType(f)} onProtocolTypeChange={f => setFeedProtocolType(f)} onSSHKeyChange={f => setFeedSSHKey(f)} onSFTPSiteChange={f => setFeedSFTPSite(f)} onSFTPPortChange={f => setFeedSFTPPort(f)} onSFTPUsernameChange={f => setFeedSFTPUsername(f)} onSFTPPasswordChange={f => setFeedSFTPPassword(f)} onSFTPDirectoryChange={f => setFeedSFTPDirectory(f)} transmissionTypeValidationForm={transmissionTypeValidationForm} sftpProtocolTypeValidationForm={sftpProtocolTypeValidationForm} sftpSiteValidationForm={sftpSiteValidationForm} sftpPortValidationForm={sftpPortValidationForm} sftpUsernameValidationForm={sftpUsernameValidationForm} sftpPasswordValidationForm={sftpPasswordValidationForm} sftpDirectoryValidationForm={sftpDirectoryValidationForm} sftpSSHKeyValidationForm={sftpSSHKeyValidationForm} requestSubmitted={requestSubmitted} feedsTransmissionTypes={feedsTransmissionTypes} feedsProtocolTypes={feedsProtocolTypes} feedSSHKeys={feedSSHKeys}
                        toEmail={toEmail} setToEmail={setToEmail} ccEmail={ccEmail} setCcEmail={setCcEmail} bccEmail={bccEmail} setBccEmail={setBccEmail} emailSubject={emailSubject} setEmailSubject={setEmailSubject} carrierEmailSubjectValidationForm={carrierEmailSubjectValidationForm} emailBody={emailBody} setEmailBody={setEmailBody} carrierEmailBodyValidationForm={carrierEmailBodyValidationForm} carrierEmailToEmailValidationForm={carrierEmailToEmailValidationForm} />
                    </div>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          {addSchedulerClicked && (
            <Accordion defaultExpanded className="mt-5">
              <AccordionSummary
                aria-controls="panel2-content"
                id="panel-header-cr-config"

              // style={{ pointerEvents: 'none' }}
              >
                <span>Scheduler</span>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <AnalystCreateSchedulers
                      feedSchedulers={feedSchedulers}
                      onSetFeedSchedulers={setFeedSchedulers}
                      schedulersValidationForm={schedulersValidationForm}
                      onSetSchedulersValidationForm={setSchedulersValidationForm}
                      requestSubmitted={requestSubmitted}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}

          <div className="w-100 text-right">
            <Button type="submit" variant="contained" className="blue-btn mt-5">
              Submit Feed
            </Button>
          </div>

        </form>
      </Paper>
    </div >
  )
}

export default AnalystCreateFeed
