import React from "react";
import { Divider, Paper } from "@material-ui/core";
import Moment from 'moment';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Pagination from "../../Layout/Pagination";
import GenericTable from "../../Layout/GenericTable";

function UsersTable({
    usersList,
    setUsersList,
    selectedPage,
    pageSize,
    setSelectedPage,
    setPageSize,
    totalCount,
    onSortFieldFilter,
    onSortOrderFilter,
    ...props
}) {

    const [sortOrder, setSortOrder] = React.useState("ASC");

    // -------------------------------- NEW TABLE ---------------------------------
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        onSortFieldFilter(column?.sortingkey);
        onSortOrderFilter(sortDirection?.toUpperCase());

    };

    const baseColumnsArray = [
        {
            name: 'First Name',
            sortable: true,
            sortingkey: 'FirstName',
            id: 'FirstName',
            selector: (c) => <a href={`/Users/ViewUser/${c.UserID}`}>{c?.FirstName}</a>
        },
        {
            name: 'Last Name',
            sortable: true,
            sortingkey: 'LastName',
            id: 'LastName',
            selector: (c) => c?.LastName
        },
        {
            name: 'Email',
            sortable: true,
            sortingkey: 'Email',
            id: 'Email',
            selector: (c) => <span style={{ wordWrap: "anywhere" }}>{c?.Email}</span>
        },
        {
            name: 'Role',
            sortable: true,
            sortingkey: 'Name',
            id: 'Role',
            selector: (c) => //split the roles and make sure the process feed and connectivity request roles is hidden and join other roles
                c?.Roles.split(", ").map((role, roleIndex) => {
                    if (role.toLowerCase() != 'feed processor' && role.toLowerCase() != 'connectivity request approver' )
                        return (roleIndex != 0 ? ', ' : '') + role
                }).join('')
        },
        {
            name: 'Status',
            sortable: true,
            sortingkey: 'isEnabled',
            id: 'isEnabled',
            selector: (c) => c?.IsEnabled === 1 ? `Enabled` : `Disabled`
        },
        {
            name: 'Created Date',
            sortable: true,
            sortingkey: 'Created',
            id: 'Created',
            selector: (c) => c?.Created ? Moment(c?.Created).format('MM/DD/YYYY') : null
        }

    ];

    return (
        <div id="users-table">
            <Paper elevation={0}>
                <div className="mt-5">
                    <GenericTable
                        columns={[...baseColumnsArray]}
                        data={usersList}
                        onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection) : () => { }}
                    />

                    <div>
                        <Pagination
                            stateLimit={true}
                            pageSize={pageSize}
                            page={selectedPage}
                            onChange={(t, v) => {
                                if (t === 1) setPageSize(v);
                                else if (t === 2) setSelectedPage(v);
                            }}
                            count={totalCount}
                        />
                    </div>
                </div>
            </Paper>
        </div>
    )
}

export default UsersTable;
