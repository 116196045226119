import React from 'react'
import { useAuthentication } from '../../../../Data/Authentication';
import { useModalDialog } from '../../../Layout/ModalDialogCustomized';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useParams } from 'react-router-dom';
import { Button, Zoom } from "@material-ui/core";
import { CustomTooltip } from "../../../Layout/Tooltip";
import { helperInfoIcon, yesOrNo } from '../../../../_helper';

const CarrierTabsOE = ({ carrierDetails, ...props }) => {
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { carrierIDURL } = useParams();


    return (
        <React.Fragment>
            <Accordion defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>OE Information</span>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Name</h6>

                            <div className="textfield-div">
                                {carrierDetails?.CarrierName}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                            <h6 className="form-title">Blackout Period</h6>
                            {helperInfoIcon('Some carriers have a blackout period after open enrollment where EDI feeds are paused and changes for the current year won’t go through until the end of the blackout period')}
                            </div>
                            <div className="textfield-div">
                                {carrierDetails?.OE?.BlackoutPeriod === 1 ? "Yes" : carrierDetails?.OE?.BlackoutPeriod === 0 ? "No" : "Unknown"}
                                
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">Allow for Dual Plan Years</h6>
                            {helperInfoIcon('Can prior year enrollments be sent once the new plan year enrollments have been sent?')}
                        </div>
                            <div className="textfield-div">
                                {carrierDetails?.OE?.CarrierDualPlanYearName}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">Separate OE Files</h6>
                            {helperInfoIcon('Does the carrier need a separate OE file or prefer the OE elections are sent via the regular production file?')}
                            </div>

                            <div className="textfield-div">
                                {carrierDetails?.OE?.CarrierSeparateOEFileName}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                        <div className="d-flex">
                            <h6 className="form-title">Are New Plan Year Effective Dates Required?</h6>
                            {helperInfoIcon('Does the carrier expect to have the plan renewal date as the coverage date for all existing employees?')}
                            </div>

                            <div className="textfield-div">
                                {carrierDetails?.OE?.CarrierNewPYEDRequiredName}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Critical OE File Deadline</h6>

                            <div className="textfield-div">
                                {carrierDetails?.OE?.CriticalOEFileDeadline}
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">OE Notes</h6>

                            <div className="textfield-div max-height">
                                {carrierDetails?.OE?.Notes}
                            </div>
                        </Grid>

                        
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}

export default CarrierTabsOE
