import React from "react";
import { Divider, Paper, FormControl, Select, MenuItem, Button, Zoom, FormControlLabel, Switch } from "@material-ui/core";
import * as APIs from "../../Data/APIs";
import { useLoader } from "../Layout/Loader";
import { FailureModal } from "../Modals/FailureModal";
import { useModalDialog } from "../Layout/ModalDialogCustomized";
import { useHistory } from "react-router-dom";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { useAuthentication } from "../../Data/Authentication";
import { CustomTooltip } from "../Layout/Tooltip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import infoCircleSVG from "../../Icons/info-circle.svg";
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';


function CreateUser() {
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const routeHistory = useHistory();
    const { openLoader, closeLoader } = useLoader();
    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");
    const [validPhone, setValidPhone] = React.useState(false);
    const [rolesList, setRolesList] = React.useState([]);
    const [roleEntitiesList, setRoleEntitiesList] = React.useState([]);
    const [role, setRole] = React.useState("");
    const [entity, setEntity] = React.useState("");
    const [processFeedAccess, setProcessFeedAccess] = React.useState(false);
    const [submitClicked, setSubmitClicked] = React.useState(false);
    const [connectivityRequestApprove, setConnectivityRequestApprove] = React.useState(false);

    // Validations
    const [firstNameValidation, setFirstNameValidation] = React.useState("");
    const [lastNameValidation, setLastNameValidation] = React.useState("");
    const [emailValidation, setEmailValidation] = React.useState("");
    const [phoneNumberValidation, setPhoneNumberValidation] = React.useState("");
    const [roleValidation, setRoleValidation] = React.useState("");
    const [entityValidation, setEntityValidation] = React.useState("");

    React.useEffect(() => {
        if (!firstName) setFirstNameValidation("First name is missing, please write the User's first name."); else setFirstNameValidation("");
    }, [firstName, firstNameValidation])
    React.useEffect(() => {
        if (!lastName) setLastNameValidation("Last name is missing, please write the User's last name."); else setLastNameValidation("");
    }, [lastName, lastNameValidation])
    React.useEffect(() => {
        if (!email) setEmailValidation("Email is missing, please write the User's email."); else setEmailValidation("");
    }, [email, emailValidation])
    React.useEffect(() => {
        if (!phone) setPhoneNumberValidation("Phone number is missing, please write the User's phone number."); else if (phone && !validPhone) setPhoneNumberValidation("Invalid phone number, please enter a valid phone number"); else setPhoneNumberValidation("");
    }, [phone, phoneNumberValidation])
    React.useEffect(() => {
        if (!role) setRoleValidation("User's role is missing, please select a role for the User."); else setRoleValidation("");
    }, [role, roleValidation])
    //  if (iSolvedSuperAmin or superAdmin) connectivityRequestApprove=true 
    React.useEffect(()=>{
        (role === 7 || role === 2) && setConnectivityRequestApprove(true);
    },[role])
    React.useEffect(() => {
        if (!entity && (role === 5 || role === 6)) setEntityValidation("User's role entity is missing, please select an entity for the User's role."); else setEntityValidation("");
    }, [entity, role, entityValidation])

    React.useEffect(() => {
        openLoader();
        APIs.getAllRoles().then((r) => (
            setRolesList([...r?.data]),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getRoleEntities(role).then((r) => (
            setRoleEntitiesList(r?.data),
            setEntity(""),
            closeLoader()
        ));
    }, [role])

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const submitUser = (e) => {
        e.preventDefault();
        let createUserObject = {};
        if (submitClicked) {
            if (firstNameValidation || lastNameValidation || emailValidation || phoneNumberValidation || roleValidation || entityValidation) return;
            if (entity) {
                createUserObject = {
                    Email: email,
                    FirstName: firstName,
                    LastName: lastName,
                    PhoneNumber: phone?.replaceAll(" ", "").replaceAll("-", ""),
                    RolesEntities: [
                        {
                            RoleID: role,
                            EntityIDs: [entity]
                        }
                    ]
                }
            } else {
                createUserObject = {
                    Email: email,
                    FirstName: firstName,
                    LastName: lastName,
                    PhoneNumber: phone?.replaceAll(" ", "").replaceAll("-", ""),
                    RolesEntities: []
                }
                // 99 >> Admin Direct , Admin Network (new Role)
                if (role !== 99) {
                    createUserObject?.RolesEntities.push({ RoleID: role })
                } else {
                    createUserObject?.RolesEntities.push({ RoleID: 3 }, { RoleID: 4 })
                }
            }
            {/* who can edit this column super admin (7) and isolved super admin (2) */ }
            if ((userRoles?.includes("2") || userRoles?.includes("7"))) {
                if (processFeedAccess) {
                    // Role 9 >> hidden Role for Process Feed
                    createUserObject?.RolesEntities.push({ RoleID: 9 })
                }
                if (connectivityRequestApprove) {
                    createUserObject?.RolesEntities.push({ RoleID: 11 })
                }
            }
        }

        APIs.createUser(createUserObject).then((r) => {
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg?.includes("UsernameExistsException") ? "Username already exists, please try a different one" : r?.msg,
                });
            } else {
                routeHistory.push(`/Users/ViewUser/${r?.UserID}`)
            }
        }).catch((s) => FailureModal(dialog, {
            title: 'Failure',
            body: 'Error processing request',
        })).finally((t) => closeLoader());

    }

    return (
        <div id="connect-req-config">
            <Paper elevation={0} className="p-5">
                {/* <div className="row w-100 alignvh-center">
                    <h5 className="black1 fw-700">Create New User</h5>
                </div> */}
                {/* <Divider className="mt-3 mb-5" /> */}
                <form onSubmit={submitUser}>
                    {/* <div className="row"> */}
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            aria-controls="panel2-content"
                            id="panel-header-cr-config"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                            style={{ pointerEvents: 'none' }}
                        >
                            <span>Create New User</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">First Name
                                        <span className="text-danger mb-2 ml-2">*</span> </h6>

                                    <input type="text" className="text-input w-100" value={firstName} onChange={handleFirstNameChange} />
                                    <div className="col-xl-2 alignvh-center" hidden={!submitClicked}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!submitClicked}>{firstNameValidation}</div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Last Name
                                        <span className="text-danger mb-2 ml-2">*</span></h6>

                                    <input type="text" className="text-input w-100" value={lastName} onChange={handleLastNameChange} />
                                    <div className="col-xl-2 alignvh-center" hidden={!submitClicked}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!submitClicked}>{lastNameValidation}</div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Email
                                        <span className="text-danger mb-2 ml-2">*</span> </h6>

                                    <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" className="text-input w-100" value={email} onChange={handleEmailChange} />
                                    <div className="col-xl-2 alignvh-center" hidden={!submitClicked}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!submitClicked}>{emailValidation}</div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Phone Number
                                        <span className="text-danger mb-2 ml-2">*</span> </h6>

                                    <div className="text-input w-100">
                                        <IntlTelInput
                                            defaultValue={phone ?? ""}
                                            autoPlaceholder={false}
                                            preferredCountries={["us", "eg"]}
                                            onPhoneNumberChange={(checkValid, n, c, number) => {
                                                setValidPhone(checkValid);
                                                setPhone(number);
                                            }}
                                            format={false}
                                        />
                                    </div>
                                    <div className="col-xl-2 alignvh-center" hidden={!submitClicked}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!submitClicked}>{phoneNumberValidation}</div>
                                </Grid>

                                <Grid item xs={12} sm={12} md={6}>
                                    <h6 className="form-title">Role
                                        <span className="text-danger mb-2 ml-2">*</span> </h6>

                                    <FormControl label="Select Role" focused={false} variant="outlined" className="w-100 ml-0-imp">
                                        <Select
                                            className="text-dropdown"
                                            value={role ?? ""}
                                            onChange={(e) => {
                                                setRole(e.target.value);
                                                setConnectivityRequestApprove(false);
                                            }}
                                        >
                                            {rolesList?.map((s, si) => (
                                                <MenuItem key={`roles-${si}`} value={s?.Id}>{s?.Name}</MenuItem>
                                            ))}
                                            {(userActions?.includes("20") || userActions?.includes("61")) &&
                                                (<MenuItem value={99}>Admin Direct, Admin Network</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                    <div className="col-xl-2 alignvh-center" hidden={!submitClicked}></div>
                                    <div className="col-xl-10 mt-2 text-danger" hidden={!submitClicked}>{roleValidation}</div>
                                </Grid>

                                {(role === 5 || role === 6) && (
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">{role === 5 ? "Partner" : "Client"}
                                                <span className="text-danger mb-2 ml-2">*</span></h6>

                                            <FormControl label="Select Role" focused={false} variant="outlined" className="w-100">
                                                <Select
                                                    className="text-dropdown"
                                                    value={entity ?? ""}
                                                    onChange={(e) => setEntity(e.target.value)}
                                                >
                                                    {roleEntitiesList?.map((s, si) => (
                                                        <MenuItem key={`roles-${si}`} value={s?.EntityID}>{s?.EntityName}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <div className="col-xl-2 alignvh-center" hidden={!submitClicked}></div>
                                            <div className="col-xl-10 mt-2 text-danger" hidden={!submitClicked}>{entityValidation}</div>
                                        </Grid>
                                    </>
                                )}

                                {/* who can edit this column super admin (7) and isolved super admin (2) */}
                                {(userRoles?.includes("2") || userRoles?.includes("7")) &&
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Process Feed Access
                                                <span className="text-danger mb-2 ml-2">*</span>
                                                <CustomTooltip style={{ marginLeft: "10px", marginTop: "-5px" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please note by enabling this feature the user will be able to process production feeds and send production files to the carriers</div>}>
                                                    {/* <InfoOutlinedIcon sx={{ color: "#f7008c" }} /> */}
                                                    <svg width="20" height="24" viewBox="0 0 25 24" >
                                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                                    </svg>
                                                </CustomTooltip>
                                            </h6>


                                            <FormControlLabel
                                                className="mt--2"
                                                control={
                                                    <Switch
                                                        checked={processFeedAccess}
                                                        onChange={(e) => setProcessFeedAccess(e.target.checked)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </>}
                                {(userRoles?.includes("2") || userRoles?.includes("7")) &&
                                    (role === 2 || role === 7 || role === 4 || role == 99) &&
                                    <>
                                        <Grid item xs={12} sm={12} md={6}>
                                            <h6 className="form-title">Connectivity Request Approver
                                                <span className="text-danger mb-2 ml-2">*</span>
                                                <CustomTooltip style={{ marginLeft: "10px", marginTop: "-5px" }} TransitionComponent={Zoom} title={<div style={{ fontWeight: "400" }}>Please note by enabling this feature the user will be able to approve connectivity requests</div>}>
                                                    <svg width="20" height="24" viewBox="0 0 25 24" >
                                                        <use xlinkHref={`${infoCircleSVG}#info-circle`}></use>
                                                    </svg>
                                                </CustomTooltip>
                                            </h6>


                                            <FormControlLabel
                                                className="mt--2"
                                                control={
                                                    <Switch
                                                        checked={connectivityRequestApprove}
                                                        disabled={role===7 || role===2}
                                                        onChange={(e) => setConnectivityRequestApprove(e.target.checked)}
                                                    />
                                                }
                                            />
                                        </Grid>
                                    </>
                                }
                                {/* </div> */}
                                <div className="w-100 mt-5 text-right">
                                    <Button type="submit" variant="contained" className="blue-btn" onClick={() => setSubmitClicked(true)}>Submit</Button>
                                </div>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </form>
            </Paper>
        </div >
    )
}

export default CreateUser;
