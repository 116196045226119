import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useLoader } from '../../../Layout/Loader';
import { helperInfoIcon, convertBoolToYesNo } from '../../../../_helper';
import * as APIs from '../../../../Data/APIs';
import { useState } from 'react';


const CarrierTabsTechnicalInfo = ({ carrierDetails, ...props }) => {
    const { TechnicalInfo } = carrierDetails || {}
    const { openLoader, closeLoader } = useLoader();
    const [templateChangeAccepted, setTemplateChangeAccepted] = useState([])
    const [spreadsheetProviders, setSpreadsheetProviders] = useState([])
    const [carrierCoveragesOnSingleFile, setCarrierCoveragesOnSingleFile] = useState([])
    const [carrierTerminateByOmission, setCarrierTerminateByOmission] = useState([])
    const [carrierGenderOptions, setCarrierGenderOptions] = useState([])
    const [carrierGenericFTPTypes, setCarrierGenericFTPTypes] = useState([])


    useEffect(() => {
        openLoader();

        Promise.all([
            APIs.getCarrierTemplateChangeAccepted(),
            APIs.getCarrierSpreadsheetProvider(),
            APIs.getCarrierCoveragesOnSingleFile(),
            APIs.getCarrierTerminateByOmission(),
            APIs.getCarrierGenderOptions(),
            APIs.getCarrierGenericFTPTypes(),
        ])
            .then(([
                templateChangeAcceptedResponse,
                spreadsheetProvidersResponse,
                carrierCoveragesOnSingleFileResponse,
                carrierTerminateByOmissionResponse,
                carrierGenderOptionsResponse,
                carrierGenericFTPTypesResponse,
            ]) => {
                setTemplateChangeAccepted(templateChangeAcceptedResponse?.data);
                setSpreadsheetProviders(spreadsheetProvidersResponse?.data);
                setCarrierCoveragesOnSingleFile(carrierCoveragesOnSingleFileResponse?.data);
                setCarrierTerminateByOmission(carrierTerminateByOmissionResponse?.data);
                setCarrierGenderOptions(carrierGenderOptionsResponse?.data);
                setCarrierGenericFTPTypes(carrierGenericFTPTypesResponse?.data);
            })
            .catch((error) => {
                console.log('Error fetching data:', error);
            })
            .finally(() => closeLoader());
    }, []);



    return (
        <React.Fragment>
            {/* Technical Information */}
            <Accordion defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Technical Information</span>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {/* Carrier Name */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Name</h6>
                            <div className="textfield-div">{carrierDetails?.CarrierName}</div>
                        </Grid>

                        {/* Will Carrier Accept the Change Spreadsheet or Template */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">
                                    Will Carrier Accept the Change Spreadsheet or Template
                                </h6>
                                {helperInfoIcon('For clients who are below the carrier’s minimum group size requirement, can we change the spreadsheet or template?')}
                            </div>
                            <div className="textfield-div">
                                {templateChangeAccepted.find(el => el?.CarrierTemplateChangeAcceptedId == TechnicalInfo?.CarrierAcceptChangeTemplateID)?.CarrierTemplateChangeAcceptedName}
                            </div>
                        </Grid>

                        {/* Carrier Provided Spreadsheet or isolved Created Spreadsheet */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">
                                    Carrier Provided Spreadsheet or isolved Created Spreadsheet
                                </h6>

                                {helperInfoIcon('Does the carrier provide a spreadsheet or want isolved to create one? (for cases where change spreadsheets are applicable)')}

                            </div>
                            <div className="textfield-div">
                                {spreadsheetProviders.find(el => el?.CarrierSpreadsheetProviderId == TechnicalInfo?.CarrierSpreadsheetProviderID)?.CarrierSpreadsheetProviderName}
                            </div>
                        </Grid>

                        {/* All Lines of Coverage on a Single File */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">All Lines of Coverage on a Single File</h6>
                                {helperInfoIcon('Can all lines of coverage be on a single file feed?')}
                            </div>
                            <div className="textfield-div">
                                {carrierCoveragesOnSingleFile.find(el => el?.CarrierCoveragesOnSingleFileId == TechnicalInfo?.CarrierCoveragesOnSingleFileID)?.CarrierCoveragesOnSingleFileName}
                            </div>
                        </Grid>

                        {/* Production Notification/Discrepancy Reporting */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">
                                Production Notification/Discrepancy Reporting
                            </h6>
                            <div className="textfield-div">
                                {convertBoolToYesNo(TechnicalInfo?.CarrierProductionNotification)}
                            </div>
                        </Grid>

                        {/* Term By Omission */}
                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Term By Omission</h6>
                                {helperInfoIcon('Does the carrier terminate an employee if they are omitted from the file feed?')}
                            </div>
                            <div className="textfield-div">
                                {carrierTerminateByOmission.find(el => el?.CarrierTerminateByOmissionId == TechnicalInfo?.CarrierTerminateByOmissionID)?.CarrierTerminateByOmissionName}
                            </div>
                        </Grid>

                        {/* Gender Options */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Gender Options</h6>
                            <div className="textfield-div">
                                {carrierGenderOptions.find(el => el?.CarrierGenderOptionId == TechnicalInfo?.CarrierGenderOptionID)?.CarrierGenderOptionName}
                            </div>
                        </Grid>

                        {/* Technical Notes */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Technical Notes</h6>
                            <div className="textfield-div d-block no-height word-break-all">
                                {TechnicalInfo?.Notes}
                            </div>
                        </Grid>

                        {/* Standard Carrier Response Time Per Test File */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">
                                Standard Carrier Response Time Per Test File
                            </h6>
                            <div className="textfield-div">
                                {TechnicalInfo?.CarrierResponseTime}
                            </div>
                        </Grid>

                        {/* Generic FTP Type */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Generic FTP Type</h6>
                            <div className="textfield-div">
                                {carrierGenericFTPTypes.find(el => el?.CarrierGenericFTPTypeId == TechnicalInfo?.CarrierGenericFTPTypeID)?.CarrierGenericFTPTypeName}
                            </div>
                        </Grid>

                        {/* Generic FTP */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Generic FTP</h6>
                            <div className="textfield-div">
                                {TechnicalInfo?.CarrierGenericFTP}
                            </div>
                        </Grid>

                        {/* Requires Test Scenarios */}
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Requires Test Scenarios</h6>
                            <div className="textfield-div">
                                {convertBoolToYesNo(TechnicalInfo?.CarrierRequireTestScenarios)}
                            </div>
                        </Grid>


                    </Grid>
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}

export default CarrierTabsTechnicalInfo
