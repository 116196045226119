import React from "react";
import { Paper } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { useLoader } from "../Layout/Loader";
import * as APIs from "../../Data/APIs";
import BreadcrumbSteps from "../Layout/BreadcrumbSteps";
import MultipleTabsTitles from "../../Components/Layout/MultipleTabsTitles";
import CarrierTabsGeneralInfo from './CarrierTabs/GenralInfoTab/CarrierTabsGeneralInfo';
import CarrierTabsLegalInfo from './CarrierTabs/LegalInfoTab/CarrierTabsLegalInfo';
import CarrierTabsCarrierRequirements from './CarrierTabs/CarrierRequirementsTab/CarrierTabsCarrierRequirements';
import CarrierTabsTechnicalInfo from './CarrierTabs/TechnicalInfoTab/CarrierTabsTechnicalInfo';



import CarrierTabsOE from "./CarrierTabs/OETab/CarrierTabsOE";
import CarrierTabsConnectivityRequests from "./CarrierTabs/ConnectivityRequestsTab/CarrierTabsConnectivityRequests";
import CarrierTabsChangeLogs from "./CarrierTabs/ChangeLogsTab/CarrierTabsChangeLogs";


function CarrierConfig() {
    const { carrierIDURL } = useParams();
    const { openLoader, closeLoader } = useLoader();
    const [carrierDetails, setCarrierDetails] = React.useState();
    const [tabValue, setTabValue] = React.useState(0);

    const carrierTabsList = [" General Info", "Legal Info", "Carrier Requirements", "Technical Info", "OE", "Connectivity Requests", "Change Logs"];
    const carrierTabsListComponents = [
        <CarrierTabsGeneralInfo carrierDetails={carrierDetails} />,
        <CarrierTabsLegalInfo carrierDetails={carrierDetails} />,
        <CarrierTabsCarrierRequirements carrierDetails={carrierDetails} />,
        <CarrierTabsTechnicalInfo carrierDetails={carrierDetails} />,
        <CarrierTabsOE carrierDetails={carrierDetails} />,
        <CarrierTabsConnectivityRequests carrierDetails={carrierDetails} />,
        <CarrierTabsChangeLogs carrierDetails={carrierDetails} />,
    ]

    const handleTabChange = (e, newValue) => {
        setTabValue(newValue)
    };


    React.useEffect(() => {
        openLoader();
        APIs.getSingleCarrierDetails(carrierIDURL).then((r) => setCarrierDetails(r?.data));
    }, [])

    React.useEffect(() => {
        if (carrierDetails) closeLoader();
        else openLoader();
    }, [carrierDetails])

    return (
        <div id="connect-req-config">
            {carrierDetails && (
                <BreadcrumbSteps
                    breadcrumbTitleCurrent={`${carrierDetails?.CarrierName}`}
                    isCurrent={true}
                />
            )}
            <MultipleTabsTitles tabTitle={carrierTabsList} value={tabValue} setValue={setTabValue} onChange={handleTabChange} />

            <Paper elevation={0} className="p-5">
                {carrierTabsListComponents[tabValue]}
            </Paper>
        </div >
    )
}

export default CarrierConfig;