import React from "react";
import MultipleTabsTitles from "../../Layout/MultipleTabsTitles";
import { Divider, Paper } from "@material-ui/core";
import GroupRequestsTable from "./GroupRequestsTable";
import { Button, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import { useAuthentication } from "../../../Data/Authentication";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GroupRequestsFilterModalBody from "./GroupRequestsFilterModalBody";
import Chip from '@mui/material/Chip';
import Moment from 'moment';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import TruncatedChip from "../../Layout/TruncatedChip";
import refreshSVG from "../../../Icons/refresh.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../Icons/advancedFilter.svg";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import plusIconSVG from "../../../Icons/plusIcon.svg";
import { Zoom, } from "@material-ui/core";
import { CustomTooltip } from "../../Layout/Tooltip";

const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0)

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);

function GroupRequestsListView({
    groupRequestsList,
    setGroupRequestsList,
    selectedPage,
    onSelectedPage,
    pageSize,
    onPageSize,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    totalCount,
    setTotalCount,
    onGroupNameFilter,
    groupNameFilter,
    onPEONameFilter,
    peoNameFilter,
    onGroupRequestIDFilter,
    groupRequestIDFilter,
    onGroupRequestIDAdvFilter,
    groupRequestIDAdvFilter,
    onMigrationFilter,
    migrationFilter,
    onMigrationAdvFilter,
    migrationAdvFilter,
    onGroupIDFilter,
    groupIDFilter,
    onGroupIDAdvFilter,
    groupIDAdvFilter,
    onClientNumberAdvFilter,
    clientNumberAdvFilter,
    onPEOIDFilter,
    peoIDFilter,
    onPEOIDAdvFilter,
    peoIDAdvFilter,
    onFromDateFilter,
    fromDateFilter,
    onToDateFilter,
    toDateFilter,
    onFromDateAdvFilter,
    fromDateAdvFilter,
    onToDateAdvFilter,
    toDateAdvFilter,
    onStatusFilter,
    statusFilter,
    onPhaseFilter,
    phaseFilter,
    groupRequestsStatus,
    groupRequestsPhases,
    onSortFieldFilter,
    onSortOrderFilter,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    GRStatus,
    onSelectedGroups,
    selectedGroupsFilter,
    onSelectedPEOs,
    selectedPEOsFilter,
    onSelectedStatuses,
    selectedStatusesFilter,
    onSelectedPhases,
    selectedPhasesFilter,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    tableRef,
    onMigrationStartFromDateAdvFilter,
    onMigrationStartToDateAdvFilter,
    onPlannedFirstProductionFromDateAdvFilter,
    onPlannedFirstProductionToDateAdvFilter,
    onSelectedMigrationPhasesAdvFilter,
    migrationStartDateFromDateAdvFilter,
    migrationStartDateToDateAdvFilter,
    plannedFirstProductionDateFromDateAdvFilter,
    plannedFirstProductionDateToDateAdvFilter,
    migrationPhasesAdvFilter,
    createdByAdvFilter,
    onSelectedCreatedByAdvFilter,
    onPhaseDashboardChange,
    onStatusDashboardChange,
    onSetOnPhaseDashboardChange,
    onSetOnStatusDashboardChange,
    onsetFilterCard,
    setRefreshClicked,
    isPEOFilter,
    onSetIsPEOFilter,
    isPEOAdvFilter,
    onSetIsPEOAdvFilter,
    onClientNumberFilter,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [value, setValue] = React.useState(0);
    const [grpName, setGrpName] = React.useState();
    const [grpNameAutoValue, setGrpNameAutoValue] = React.useState("");
    const [peoName, setPEOName] = React.useState();
    const [peoNameAutoValue, setPEONameAutoValue] = React.useState("");
    const [grpRequestID, setGrpRequestID] = React.useState();
    const [grpID, setGrpID] = React.useState();
    const [peoID, setPEOID] = React.useState();
    const [selectedFromDate, setSelectedFromDate] = React.useState(null);
    const [selectedToDate, setSelectedToDate] = React.useState(null);
    const [grpRequestStatus, setGrpRequestStatus] = React.useState("");
    const [grpRequestPhase, setGrpRequestPhase] = React.useState("");
    const [groupRequestsStatuses, setGroupRequestsStatuses] = React.useState([]);
    const [groupsList, setGroupsList] = React.useState([]);
    const [peosList, setPEOsList] = React.useState([]);
    const [migrationType, setMigrationType] = React.useState("");
    const [partnerStatus, setPartnerStatus] = React.useState("");
    
    const [openDialog, setOpenDialog] = React.useState(false);

    const [clientNumber, setClientNumber] = React.useState();

    

    

    const handleClickOpenDialog = () => {
        onFromDateFilter(null);
        onToDateFilter(null);
        onStatusFilter("");
        onPhaseFilter("");
        onSetIsPEOFilter("");
        onGroupNameFilter("");
        onPEONameFilter("");
        onGroupRequestIDFilter("");
        onClientNumberFilter("");
        onGroupIDFilter("");
        onPEOIDFilter("");
        onMigrationFilter("");
        setGrpNameAutoValue("");
        setPEONameAutoValue("");
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
        setClientNumber("");

    };

    React.useEffect(() => {
        const list = ["All"]
        list.push(...groupRequestsStatus?.map(s => s.GroupRequestStatusName));
        setGroupRequestsStatuses(list);
    }, [groupRequestsStatus])

    const handelIsPEOFilter = (e, newValue) => {

        onSetIsPEOFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }
    const handleTabChange = (e, newValue) => {
        setValue(newValue);
        setGrpRequestStatus(newValue);
        onStatusFilter(newValue === 0 ? "" : newValue);
        onSelectedPage(1);
        onPageSize(30);
    };

    React.useEffect(() => {
        if (GRStatus === "Active") { setValue(1); setGrpRequestStatus(1); }
        else if (GRStatus === "Cancelled") { setValue(2); setGrpRequestStatus(2); }
        else if (GRStatus === "Change Requested") { setValue(3); setGrpRequestStatus(3); }
        else if (GRStatus === "Draft") { setValue(4); setGrpRequestStatus(4); }
    }, [GRStatus])
    React.useEffect(() => {
        if (statusFilter === 1) { setValue(1); setGrpRequestStatus(1); }
        else if (statusFilter === 2) { setValue(2); setGrpRequestStatus(2); }
        else if (statusFilter === 3) { setValue(3); setGrpRequestStatus(3); }
        else if (statusFilter === 4) { setValue(4); setGrpRequestStatus(4); }
    }, [statusFilter])
    React.useEffect(() => {
        if (GRStatus !== undefined) {
            setSelectedFromDate(todayDate);
            setSelectedToDate(todayDate);
        }
    }, [GRStatus])


    const handleDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedFromDate(date);
            onFromDateFilter(date);
        }
        else {
            setSelectedToDate(date);
            onToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleGroupRequestIDFilter = (e) => {
        setGrpRequestID(e.target.value);
        onGroupRequestIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleClientNumberFilter = (e) => {
        setClientNumber(e.target.value);
        onClientNumberFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleGroupIDFilter = (e) => {
        setGrpID(e.target.value);
        onGroupIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handlePEOIDFilter = (e) => {
        setPEOID(e.target.value);
        onPEOIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleGroupRequestPhaseFilter = (e, newValue) => {
        setGrpRequestPhase(e.target.value);
        onPhaseFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleMigrationTypeFilter = (e) => {
        setMigrationType(e.target.value);
        onMigrationFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    React.useEffect(() => {
        APIs.getGroupsListByName({ GroupName: grpName ?? "" }).then((r) => !(r?.data?.length) ? setGroupsList([]) : setGroupsList(r?.data));
    }, [])

    
    React.useEffect(() => {
        APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
    }, [peoName])

    React.useEffect(() => {
        setGrpNameAutoValue(grpName);
    }, [grpName])
    React.useEffect(() => {
        setPEONameAutoValue(peoName);
    }, [peoName])

    React.useEffect(() => {
        APIs.getPartnerStatus().then((r) => setPartnerStatus(r));
    }, [])

    const handleClearSearch = () => {
        setValue(0);
        setGrpRequestStatus("");
        setGrpRequestPhase("");
        setClientNumber("");
        onClientNumberFilter("")
        onStatusFilter("");
        onPhaseFilter("");
        setGrpName("");
        onGroupNameFilter("");
        setPEOName("");
        onPEONameFilter("");
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        setMigrationType("");
        onMigrationFilter("");
        setGrpID("");
        onGroupIDFilter("");
        onGroupIDAdvFilter("");
        onClientNumberAdvFilter("");
        setPEOID("");
        onPEOIDFilter("");
        onPEOIDAdvFilter("");
        setSelectedFromDate(null);
        onFromDateFilter(null);
        setSelectedToDate(null);
        onSetIsPEOFilter("");
        onSetIsPEOAdvFilter("");
        onToDateFilter(null);
        onSelectedPage(1);
    }

    const handleClearSearchReset = () => {
        setValue(0);
        setGrpRequestStatus("");
        setGrpRequestPhase("");
        setClientNumber("");
        onStatusFilter("");
        onPhaseFilter("");
        setGrpName("");
        setPEOName("");
        onGroupNameFilter("");
        onPEONameFilter("");
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        onGroupRequestIDAdvFilter("");
        setMigrationType("");
        onMigrationFilter("");
        onMigrationAdvFilter([]);
        setGrpID("");
        onGroupIDFilter("");
        onGroupIDAdvFilter("");
        onClientNumberAdvFilter("");
        setPEOID("");
        onPEOIDFilter("");
        onPEOIDAdvFilter("");
        onSelectedGroups([]);
        onSelectedPEOs([]);
        onSelectedStatuses([]);
        onSelectedPhases([]);
        setSelectedFromDate(null);
        onFromDateAdvFilter(null);
        setSelectedToDate(null);
        onToDateAdvFilter(null);
        onSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
        onMigrationStartFromDateAdvFilter(null);
        onMigrationStartToDateAdvFilter(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        onSetIsPEOFilter("");
        onSetIsPEOAdvFilter("");
        onSelectedMigrationPhasesAdvFilter([]);
        onSelectedCreatedByAdvFilter([]);
    }
    React.useEffect(() => {
        if (onPhaseDashboardChange) {
            onStatusFilter("");
            setGrpRequestStatus("");
            setValue(0);
            handleClearDashBordReset();
            onsetFilterCard(false);
        }
    }, [phaseFilter, onPhaseDashboardChange])
    React.useEffect(() => {
        if (onStatusDashboardChange) {
            onPhaseFilter("");
            setGrpRequestPhase("");
            handleClearDashBordReset();
            onsetFilterCard(false);
        }
    }, [statusFilter, onStatusDashboardChange])
    const handleClearDashBordReset = () => {
        onFromDateFilter(null);
        onToDateFilter(null);
        setGrpName("");
        setPEOName("");
        onGroupNameFilter("");
        onPEONameFilter("");
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        onGroupRequestIDAdvFilter("");
        setMigrationType("");
        onMigrationFilter("");
        onMigrationAdvFilter([]);
        setGrpID("");
        onGroupIDFilter("");
        setClientNumber("");
        onClientNumberFilter("");
        onGroupIDAdvFilter("");
        onClientNumberAdvFilter("");
        setPEOID("");
        onPEOIDFilter("");
        onPEOIDAdvFilter("");
        onSelectedGroups([]);
        onSelectedPEOs([]);
        onSelectedStatuses([]);
        onSelectedPhases([]);
        setSelectedFromDate(null);
        onFromDateAdvFilter(null);
        setSelectedToDate(null);
        onToDateAdvFilter(null);
        onSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersClicked(false);
        onMigrationStartFromDateAdvFilter(null);
        onMigrationStartToDateAdvFilter(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        onSelectedMigrationPhasesAdvFilter([]);
        onSelectedCreatedByAdvFilter([]);
        onSetOnStatusDashboardChange(false);
        onSetOnPhaseDashboardChange(false);
        onSetIsPEOFilter("");
        onSetIsPEOAdvFilter("");
    }
    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    return (
        <div>
            <div style={{ position: "relative", }}>
                
                        <>
                            <div className="alignvh-center" style={{ position: "relative", justifyContent: "flex-end" }}>
                            
                            {((userActions?.includes("6") || userActions?.includes("20")) || userRoles?.includes("5")) && (
                                <CustomTooltip TransitionComponent={Zoom} title={(userRoles?.includes("5") && !partnerStatus) ? <div>Requests have been suspended</div> : ""}>
                                    <span>
                                <Button className="add-request MuiButton-btnWithText blue-outlined-btn " style={{ right: "0px" }} href={`/GroupRequests/CreateGroupRequest`} disabled={userRoles?.includes("5") && !partnerStatus}>
                                    <svg width="22" height="22" viewBox="0 0 22 22" className="mt--1">
                                        <use xlinkHref={`${plusIconSVG}#plusIcon`}></use>
                                    </svg>
                                    Group Request
                                </Button>
                                    </span>
                                    </CustomTooltip>
                            )}
                            </div>
                        </>
                    
                {quickFiltersClicked && (
                    <MultipleTabsTitles tabTitle={groupRequestsStatuses} value={value} setValue={setValue} onChange={handleTabChange} />
                )}
            </div>
            <Paper className="content-card-paper search-container">
                <div className="row alignvh-center">
                    <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                    <Button
                        className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                        onClick={handleClickOpenDialog}
                        style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1">
                            <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                        </svg>Advanced Filters
                    </Button>
                    <React.Fragment>
                        {groupRequestIDFilter && <TruncatedChip body={`Group Request ID: ${groupRequestIDFilter}`} />}
                        {groupRequestIDAdvFilter && <TruncatedChip body={`Group Request ID: ${groupRequestIDAdvFilter}`} />}
                        {clientNumber && <TruncatedChip body={`Client Number: ${clientNumber}`} />}
                        {clientNumberAdvFilter && <TruncatedChip body={`Client Number: ${clientNumberAdvFilter}`} />}
                        {groupIDFilter && <TruncatedChip body={`ID: ${groupIDFilter}`} />}
                        {peoIDFilter && <TruncatedChip body={`PEO ID: ${peoIDFilter}`} />}
                        {isPEOFilter && <TruncatedChip body={`Include PEO:  ${isPEOFilter == 1 ? "Yes" : "No"}`} />}
                        {isPEOAdvFilter && <TruncatedChip body={`Include PEO:  ${isPEOAdvFilter == 1 ? "Yes" : "No"}`} />}
                        {groupIDAdvFilter && <TruncatedChip body={`ID: ${groupIDAdvFilter}`} />}
                        {groupNameFilter && <TruncatedChip body={`Client: ${groupNameFilter}`} />}
                        {peoIDAdvFilter && <TruncatedChip body={`PEO ID: ${peoIDAdvFilter}`} />}
                        {peoNameFilter && <TruncatedChip body={`PEO: ${peoNameFilter}`} />}
                        {statusFilter && statusFilter !== 0 && <TruncatedChip body={`Group Request Status: ${groupRequestsStatus?.filter(s => s.GroupRequestStatusID == statusFilter)[0].GroupRequestStatusName}`} />}
                        {phaseFilter && phaseFilter !== 0 && <TruncatedChip body={`Group Request Phase: ${groupRequestsPhases?.filter(s => s.Id == phaseFilter)[0].Name}`} />}
                        {selectedGroupsFilter && selectedGroupsFilter?.length > 0 && <TruncatedChip body={`Clients: ${selectedGroupsFilter?.map(s => s.GroupName).join(" - ")}`} />}
                        {selectedPEOsFilter && selectedPEOsFilter?.length > 0 && <TruncatedChip body={`PEOs: ${selectedPEOsFilter?.map(s => s.PeoName).join(" - ")}`} />}
                        {selectedStatusesFilter && selectedStatusesFilter?.length > 0 && <TruncatedChip body={`Group Request Status: ${selectedStatusesFilter?.map(s => s.GroupRequestStatusName).join(" - ")}`} />}
                        {selectedPhasesFilter && selectedPhasesFilter?.length > 0 && <TruncatedChip body={`Group Request Phase: ${selectedPhasesFilter?.map(s => s.Name).join(" - ")}`} />}
                        {quickFiltersClicked && fromDateFilter && toDateFilter && <TruncatedChip body={`Submission Date: From: ${Moment(fromDateFilter).format("MM/DD/YYYY")} To: ${Moment(toDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && fromDateFilter && !toDateFilter && <TruncatedChip body={`Submission Date: From: ${Moment(fromDateFilter).format("MM/DD/YYYY")}`} />}
                        {quickFiltersClicked && !fromDateFilter && toDateFilter && <TruncatedChip body={`Submission Date: To: ${Moment(toDateFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && fromDateAdvFilter && toDateAdvFilter && <TruncatedChip body={`Submission Date: From: ${Moment(fromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(toDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && fromDateAdvFilter && !toDateAdvFilter && <TruncatedChip body={`Submission Date: From: ${Moment(fromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !fromDateAdvFilter && toDateAdvFilter && <TruncatedChip body={`Submission Date: To: ${Moment(toDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {migrationFilter && <TruncatedChip body={`Migration Type: ${migrationFilter}`} />}
                        {migrationAdvFilter && migrationAdvFilter.length > 0 && <TruncatedChip body={`Migration Type: ${migrationAdvFilter.map(s => s).join(" - ")}`} />}
                        {advancedFiltersClicked && migrationStartDateFromDateAdvFilter && migrationStartDateToDateAdvFilter && <TruncatedChip body={`Migration Start Date: From: ${Moment(migrationStartDateFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(migrationStartDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && migrationStartDateFromDateAdvFilter && !migrationStartDateToDateAdvFilter && <TruncatedChip body={`Migration Start Date: From: ${Moment(migrationStartDateFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !migrationStartDateFromDateAdvFilter && migrationStartDateToDateAdvFilter && <TruncatedChip body={`Migration Start Date: To: ${Moment(migrationStartDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && plannedFirstProductionDateFromDateAdvFilter && plannedFirstProductionDateToDateAdvFilter && <TruncatedChip body={`Planned First Production Date: From: ${Moment(plannedFirstProductionDateFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(plannedFirstProductionDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && plannedFirstProductionDateFromDateAdvFilter && !plannedFirstProductionDateToDateAdvFilter && <TruncatedChip body={`Planned First Production Date: From: ${Moment(plannedFirstProductionDateFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && !plannedFirstProductionDateFromDateAdvFilter && plannedFirstProductionDateToDateAdvFilter && <TruncatedChip body={`Planned First Production Date: To: ${Moment(plannedFirstProductionDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                        {advancedFiltersClicked && migrationPhasesAdvFilter && migrationPhasesAdvFilter.length > 0 && <TruncatedChip body={`Migration Phase: ${migrationPhasesAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                        {createdByAdvFilter && createdByAdvFilter?.length > 0 && <TruncatedChip body={`Created By: ${createdByAdvFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}
                    </React.Fragment>
                </div>
                {quickFiltersClicked && (
                    (userActions?.includes("43") || userActions?.includes("20")) ? (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>Group Request ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={grpRequestID ?? ""} onChange={handleGroupRequestIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Client Number:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={clientNumber ?? ""} onChange={handleClientNumberFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Client Name:</span>
                                <br />
                                <Autocomplete
                                    options={groupsList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={grpNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.GroupName}
                                    onChange={(e, value) => {
                                        setGrpName(value?.GroupName ?? "");
                                        onGroupNameFilter(value?.GroupName ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.GroupName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setGrpNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>PEO Name:</span>
                                <br />
                                <Autocomplete
                                    options={peosList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={peoNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.PeoName}
                                    onChange={(e, value) => {
                                        setPEOName(value?.PeoName ?? "");
                                        onPEONameFilter(value?.PeoName ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.PeoName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setPEONameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Submission Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedFromDate}
                                            onChange={handleDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedToDate}
                                            onChange={handleDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Phase:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={phaseFilter}
                                        onChange={handleGroupRequestPhaseFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {groupRequestsPhases?.map((p, pi) => (
                                            <option key={`group-request-phase-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Migration:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={migrationType}
                                        onChange={handleMigrationTypeFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="No">No</option>
                                        <option value="eBenefits Network">eBenefits Network</option>
                                        <option value="Everything Benefits">Everything Benefits</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Include PEO:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={isPEOFilter ?? ""}
                                        onChange={handelIsPEOFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </NativeSelect >
                                </FormControl >
                            </div >
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-xl-3 mt-3">
                                <span>ID:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={grpID ?? ""} onChange={handleGroupIDFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Client Number:</span>
                                <br />
                                <input type="text" className="search-input w-100" value={clientNumber ?? ""} onChange={handleClientNumberFilter} />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Client Name:</span>
                                <br />
                                <Autocomplete
                                    options={groupsList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={grpNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.GroupName}
                                    onChange={(e, value) => {
                                        setGrpName(value?.GroupName ?? "");
                                        onGroupNameFilter(value?.GroupName ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.GroupName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setGrpNameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>PEO Name:</span>
                                <br />
                                <Autocomplete
                                    options={peosList}
                                    autoHighlight
                                    className="search-dropdown w-100"
                                    inputValue={peoNameAutoValue ?? ""}
                                    getOptionLabel={(option) => option?.PeoName}
                                    onChange={(e, value) => {
                                        setPEOName(value?.PeoName ?? "");
                                        onPEONameFilter(value?.PeoName ?? "");
                                        onSelectedPage(1);
                                        onPageSize(30);
                                    }}
                                    renderOption={(option) => (
                                        <React.Fragment>
                                            {option?.PeoName}
                                        </React.Fragment>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            onChange={e => setPEONameAutoValue(e.target.value)}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off', // disable autocomplete and autofill
                                            }}
                                        />
                                    )}
                                />
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Submission Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedFromDate}
                                            onChange={handleDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedToDate}
                                            onChange={handleDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Phase:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={phaseFilter}
                                        onChange={handleGroupRequestPhaseFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        {groupRequestsPhases?.map((p, pi) => (
                                            <option key={`group-request-phase-${pi}`} value={p.Id}>{p.Name}</option>
                                        ))}
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <span>Migration:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={migrationType}
                                        onChange={handleMigrationTypeFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="No">No</option>
                                        <option value="eBenefits Network">eBenefits Network</option>
                                        <option value="Everything Benefits">Everything Benefits</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                            <div className="col-xl-3 mt-4">
                                <span>Include PEO:</span>
                                <br />
                                <FormControl className="search-dropdown w-100">
                                    <NativeSelect
                                        value={isPEOFilter ?? ""}
                                        onChange={handelIsPEOFilter}
                                        input={<BootstrapInput />}
                                    >
                                        <option aria-label="None" value="" />
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </NativeSelect>
                                </FormControl>
                            </div>
                        </div>
                    )
                )}
                <div className="col-xl-12 mt-4" >
                    <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                        <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                            <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                        </svg>
                        <span className="ml-1">Refresh List</span>
                    </Button>
                    <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                        <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                            <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                        </svg> Clear Search
                    </Button>
                </div>

                <GroupRequestsTable tableRef={tableRef} advancedFiltersClicked={advancedFiltersClicked} quickFiltersClicked={quickFiltersClicked} groupRequestsList={groupRequestsList} setGroupRequestsList={setGroupRequestsList} selectedPage={selectedPage} onSelectedPage={onSelectedPage} pageSize={pageSize} onPageSize={onPageSize} selectedPageAdv={selectedPageAdv} onSelectedPageAdv={onSelectedPageAdv} pageSizeAdv={pageSizeAdv} onPageSizeAdv={onPageSizeAdv} totalCount={totalCount} onSortFieldFilter={onSortFieldFilter} onSortOrderFilter={onSortOrderFilter} onSortFieldAdvFilter={onSortFieldAdvFilter} onSortOrderAdvFilter={onSortOrderAdvFilter} />

            </Paper>

            <GroupRequestsFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                onPageSizeAdv={onPageSizeAdv}
                onSelectedPageAdv={onSelectedPageAdv}
                onSelectedGroups={onSelectedGroups}
                onSelectedPEOs={onSelectedPEOs}
                onSelectedStatuses={onSelectedStatuses}
                onSelectedPhases={onSelectedPhases}
                onGroupRequestIDFilter={onGroupRequestIDFilter}
                onGroupRequestIDAdvFilter={onGroupRequestIDAdvFilter}
                onGroupIDFilter={onGroupIDFilter}
                onGroupIDAdvFilter={onGroupIDAdvFilter}
                onClientNumberAdvFilter={onClientNumberAdvFilter}
                onPEOIDFilter={onPEOIDFilter}
                onPEOIDAdvFilter={onPEOIDAdvFilter}
                onMigrationAdvFilter={onMigrationAdvFilter}
                onFromDateAdvFilter={onFromDateAdvFilter}
                onToDateAdvFilter={onToDateAdvFilter}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                onMigrationStartFromDateAdvFilter={onMigrationStartFromDateAdvFilter}
                onMigrationStartToDateAdvFilter={onMigrationStartToDateAdvFilter}
                onPlannedFirstProductionFromDateAdvFilter={onPlannedFirstProductionFromDateAdvFilter}
                onPlannedFirstProductionToDateAdvFilter={onPlannedFirstProductionToDateAdvFilter}
                onSelectedMigrationPhasesAdvFilter={onSelectedMigrationPhasesAdvFilter}
                onSelectedCreatedByAdvFilter={onSelectedCreatedByAdvFilter}
                onCloseClearReset={handleClearSearchReset}
                onSetIsPEOAdvFilter={onSetIsPEOAdvFilter}
                onClientNumberFilter={onClientNumberFilter}

            />

        </div>
    )
}

export default GroupRequestsListView;
