import React from "react";
import Moment from 'moment';
import ViewSVG from "../../../../Images/view.svg";
import Pagination from "../../../Layout/Pagination";
import TransformIcon from '@material-ui/icons/Transform';
import HistorySVG from "../../../../Images/history.svg";
import { useAuthentication } from "../../../../Data/Authentication";
import GenericTable from "../../../Layout/GenericTable";

function FeedsTable({
    advancedFiltersClicked,
    quickFiltersClicked,
    feedsList,
    setFeedsList,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSizeAdv,
    onPageSizeAdv,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    ...props
}) {


    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const [sortOrder, setSortOrder] = React.useState("ASC");
    // ================================================NEW UI
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey);
            onSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey);
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };
    const baseColumnsArray = [

        {
            name: 'Feed Name',
            sortable: true,
            sortingkey: 'FeedName',
            id: 'FeedName',
            selector: c => <a href={`/Feeds/ViewFeed/${c?.FeedID}`} style={{ wordBreak: "break-word" }}>{c?.FeedName}</a>
        },
        {
            name: 'Carrier Name',
            sortable: true,
            sortingkey: 'CarrierName',
            id: 'CarrierName',
            selector: c => c?.CarrierName,
        },
        {
            name: 'Feed Type',
            sortable: true,
            id: 'FeedMapTypeName',
            sortingkey: 'FeedMapTypeName',
            selector: c => c?.FeedMapTypeName
        },
        {
            name: 'File Type',
            sortable: true,
            id: 'FeedTypeName',
            sortingkey: 'FeedTypeName',
            selector: c => c.FeedTypeName,
        },
        {
            name: 'Data Type',
            sortable: true,
            sortingkey: 'DataType',
            id: 'DataType',
            selector: c => c?.DataType,
        },
        {
            name: 'Structure Type',
            sortable: true,
            sortingkey: 'StructureTypeName',
            id: 'StructureTypeName',
            selector: c => c?.StructureTypeName,
        },
        {
            name: 'Created Date',
            sortable: true,
            sortingkey: 'Created',
            id: 'Created',
            selector: c => Moment(c?.Created).format('MM/DD/YYYY'),
        },
        {
            name: 'Status',
            sortable: true,
            sortingkey: 'FeedStatusName',
            id: 'FeedStatusName',
            selector: c => <div className={`status-div 
                ${c?.FeedStatusName === "In Progress" ? `inProgress` :
                    c?.FeedStatusName === "On Hold" ? `onHold` :
                        c?.FeedStatusName === "Blocked" ? `blocked` :
                            c?.FeedStatusName === "Cancelled" ? `cancelled` :
                                c?.FeedStatusName === "Not Started" ? `notStarted` :
                                    c?.FeedStatusName === "Production" ? `production` : `productionDisabled`}`}>
                {c?.FeedStatusName}
            </div>,
        },
        {
            name: 'Phase',
            sortable: true,
            sortingkey: 'FeedPhaseName',
            id: 'FeedPhaseName',
            selector: c => <div className={`phase-div 
                ${c?.FeedPhaseName === "Not Started" ? `notStarted` :
                    c?.FeedPhaseName === "Requirements Gathering" ? `requirementGathering` :
                        c?.FeedPhaseName === "Obtain Customer Dataset" ? `obtainCustomerDataset` :
                            c?.FeedPhaseName === "Resource Assignment" ? `resourceAssignment` :
                                c?.FeedPhaseName === "Dataset Validation" ? `datasetValidation` :
                                    c?.FeedPhaseName === "Mapping" ? `mapping` :
                                        c?.FeedPhaseName === "Testing" ? `testing` : `production`}`}>
                {c?.FeedPhaseName}
            </div>,
        }
    ];

    const pushToColumnsBasedOnUserAction = () => {
        let checkif43or20 = (userActions?.includes("43") || userActions?.includes("20"))

        let itemsToInsert = [
            {
                index: 0,
                value: {
                    name: 'Feed ID',
                    sortable: true,
                    width: '100px',
                    sortingkey: 'FeedID',
                    id: 'FeedID',
                    selector: c => <a href={`/Feeds/ViewFeed/${c?.FeedID}`}>{c?.FeedID}</a>
                },
                condition: checkif43or20
            },

        ];

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];


        itemsToInsert.forEach(item => {
            if (item?.condition)
                finalColumnsArray.splice(item.index, 0, item.value);
        });


        return finalColumnsArray;
    };


    return (
        <div className="mt-5">
            <GenericTable
                columns={[...pushToColumnsBasedOnUserAction()]}
                data={feedsList}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
            />
            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                    page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                    onChange={(t, v) => {
                        if (quickFiltersClicked) {
                            if (t === 1) setPageSize(v);
                            else if (t === 2) setSelectedPage(v);
                        } else if (advancedFiltersClicked) {
                            if (t === 1) onPageSizeAdv(v);
                            else if (t === 2) onSelectedPageAdv(v);
                        }

                    }}
                    count={totalCount}
                />
            </div>
        </div>
    )
}

export default FeedsTable;