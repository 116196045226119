import React from "react";
import Moment from 'moment';
import ViewSVG from "../../Images/view.svg";
import Pagination from "../Layout/Pagination";
import GenericTable from "../Layout/GenericTable";

function PartnersTable({
    partnersList,
    partnerStatus,
    selectedPage,
    pageSize,
    totalCount,
    setSelectedPage,
    setPageSize,
    onSortFieldFilter,
    onSortOrderFilter,
    ...props
}) {

    const [sortOrder, setSortOrder] = React.useState("ASC");

    // -------------------------------- NEW TABLE ---------------------------------
    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        onSortFieldFilter(column?.sortingkey);
        onSortOrderFilter(sortDirection?.toUpperCase());

    };

    const baseColumnsArray = [
        {
            name: 'Partner URL',
            sortable: false,
            sortingkey: 'InstanceURL',
            id: 'InstanceURL',
            selector: (c) => c?.InstanceURL
        },
        {
            name: 'Partner Name',
            sortable: false,
            sortingkey: 'PartnerName',
            id: 'PartnerName',
            selector: (c) => c?.PartnerName
        },
        {
            name: 'Status',
            sortable: false,
            sortingkey: 'CreatedBy',
            id: 'CreatedBy',
            selector: (c) => partnerStatus === true ? "Enabled" : "Disabled"
        }

    ];

    return (
        <div className="mt-5" id="connectivity-group-requests-table">
            <GenericTable
                columns={[...baseColumnsArray]}
                data={partnersList}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection) : () => { }}
            />            

            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={pageSize}
                    page={selectedPage}
                    onChange={(t, v) => {
                        if (t === 1) setPageSize(v);
                        else if (t === 2) setSelectedPage(v);
                    }}
                    count={totalCount}
                />
            </div>
        </div>
    )
}

export default PartnersTable;