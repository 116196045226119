import React from 'react';
// import * as APIs from '../../../../../Data/APIs'
import moment from 'moment';


const AgreementDocumentTableRow = ({ agreementDocument, legalDocumentTypes, ...props }) => {
    // we will comment Download untill next sprint
    // const [decodedString, setDecodedString] = React.useState("");
    // const handleDownloadFile = () => {
    //     const submitDownloadFile = {
    //         guid: agreementDocument.GUID,
    //         attachmentExtension: agreementDocument.Extension,
    //     }
    //     APIs.getSingleFileAttachment(submitDownloadFile).then((r) => {
    //         setDecodedString(r?.data);
    //     });
    // }

    // React.useEffect(() => {
    //     handleDownloadFile();
    // }, [])
    return (
        <div className="row table-row">
            <div className="col-3 table-cell first-cell d-flex flex-column word-break-all">
                {legalDocumentTypes?.find(el => el.CarrierLegalDocumentTypeId === agreementDocument?.CarrierLegalDocumentTypeId)?.CarrierLegalDocumentTypeName}
            </div>
            <div className="col-3 table-cell d-flex flex-column word-break-all">
                {agreementDocument?.Name}
            </div>
            <div className="col-3 table-cell d-flex flex-column word-break-all">
                {agreementDocument?.LegalDocumentSignDate && moment(agreementDocument?.LegalDocumentSignDate).format('MM/DD/YYYY')}
            </div>
            <div className="col-3 table-cell d-flex flex-column word-break-all">
                {agreementDocument?.LegalDocumentSignor}
            </div>
            {/* <div className="col table-cell d-flex flex-column">
                <DownloadLink
                    label={
                        <svg width="20" height="20" viewBox="0 0 20 20" onClick={handleDownloadFile} style={{ cursor: "pointer" }}>
                            <use xlinkHref={`${downloadIconSVG}#downloadIcon`}></use>
                        </svg>

                    }
                    filename={agreementDocument.Name}
                    exportFile={() => {
                        let binaryString = window.atob(decodedString);
                        let binaryLen = binaryString.length;
                        let ab = new ArrayBuffer(binaryLen);
                        let ia = new Uint8Array(ab);
                        for (let i = 0; i < binaryLen; i++) {
                            ia[i] = binaryString.charCodeAt(i);
                        }
                        let bb = new Blob([ab]);
                        return bb;
                    }}
                    style={{ marginTop: "-2px", marginRight: "2px" }}
                />
            </div> */}

        </div>
    )
}

export default AgreementDocumentTableRow
