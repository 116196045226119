import React from 'react'
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AgreementDocumentsTable from './AgreementDocuments/AgreementDocumentsTable';
import { helperInfoIcon, convertBoolToYesNo } from '../../../../_helper';


const CarrierTabsLegalInfo = ({ carrierDetails, ...props }) => {
    const { LegalInfo } = carrierDetails || {}
    return (
        <React.Fragment>
            <Accordion defaultExpanded>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Legal Information</span>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} sm={12} md={6}>
                            <h6 className="form-title">Carrier Name</h6>

                            <div className="textfield-div">
                                {carrierDetails?.CarrierName}
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6}>
                            <div className="d-flex">
                                <h6 className="form-title">Carrier Requires BAA or Legal Document</h6>
                                {helperInfoIcon('Does the carrier require a BAA or legal document?')}
                            </div>

                            <div className="textfield-div">
                                {convertBoolToYesNo(LegalInfo?.LegalDocRequired)}
                            </div>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {/* Agreement Documents */}
            <Accordion defaultExpanded className='mt-5'>
                <AccordionSummary
                    aria-controls="panel2-content"
                    id="panel-header-cr-config"
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ pointerEvents: 'none' }}
                >
                    <span>Agreement Documents</span>
                </AccordionSummary>
                <AccordionDetails className='p-0-imp'>
                    <AgreementDocumentsTable attachments={LegalInfo?.Attachments} />
                </AccordionDetails>
            </Accordion>
        </React.Fragment>
    )
}

export default CarrierTabsLegalInfo
