import React from 'react'
import { Paper, } from "@material-ui/core";
import Notifications from './Notifications';

const SettingsPage = () => {

    return (
        <div id="view-action-items" className="mt-3">
            <div className="row w-100 alignvh-center ">
                <h5 className="black1 fw-700">Settings</h5>
            </div>
            <Paper elevation={0} className="search-container" style={{ marginTop: "20px", padding: "40px" }}>
                <Notifications />
            </Paper>
        </div>
    )
}

export default SettingsPage



