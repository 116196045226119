import React from "react";
import MultipleTabsTitles from "../../Layout/MultipleTabsTitles";
import { ButtonBase, Divider, FormControl, Paper } from "@material-ui/core";
import ConnectivityRequestsTable from "./ConnectivityRequestsTable";
import { Button, TextField } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import ClearIcon from '@material-ui/icons/Clear';
import * as APIs from "../../../Data/APIs";
import { useLoader } from "../../Layout/Loader";
import GetAppIcon from '@material-ui/icons/GetApp';
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FailureModal } from "../../Modals/FailureModal";
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import TruncatedChip from "../../Layout/TruncatedChip";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Chip from '@mui/material/Chip';
import Moment from 'moment';
import ConnectivityRequestsFilterModalBody from "./ConnectivityRequestsFilterModalBody";
import { useAuthentication } from "../../../Data/Authentication";
import { styled } from '@mui/system';
import refreshSVG from "../../../Icons/refresh.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../Icons/advancedFilter.svg";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import exportIconSVG from "../../../Icons/exportArrow.svg";
import { ConfirmModal } from "../../Modals/ConfirmModal";
import { SuccessModal } from "../../Modals/SuccessModal";
import verifyCarrierSVG from "../../../Icons/shield-check.svg";



const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function ConnectivityRequestsListView({
    connectivityRequestsList,
    setConnectivityRequestsList,
    selectedPage,
    onSelectedPage,
    selectedPageAdv,
    onSelectedPageAdv,
    pageSize,
    onPageSize,
    pageSizeAdv,
    onPageSizeAdv,
    onGroupNameFilter,
    groupNameFilter,
    onPEONameFilter,
    peoNameFilter,
    onAssignedToFilter,
    assignedToFilter,
    onCarrierNameFilter,
    carrierNameFilter,
    onPartnerFilter,
    partnerFilter,
    onFeedNameFilter,
    feedNameFilter,
    onFeedIDFilter,
    feedIDFilter,
    onFeedStructureTypeFilter,
    feedStructureTypeFilter,
    onSelectedFeedStructureTypesFilter,
    selectedFeedStructureTypesFilter,
    onFeedDataTypeFilter,
    feedDataTypeFilter,
    onSelectedFeedDataTypesFilter,
    selectedFeedDataTypesFilter,
    onFeedIDAdvFilter,
    feedIDAdvFilter,
    onGroupRequestIDFilter,
    groupRequestIDFilter,
    onConnectivityRequestIDFilter,
    connectivityRequestIDFilter,
    onConnectivityRequestIDAdvFilter,
    connectivityRequestIDAdvFilter,
    onCreatedFromDateFilter,
    createdFromDateFilter,
    onCreatedToDateFilter,
    createdToDateFilter,
    onCreatedFromDateAdvFilter,
    createdFromDateAdvFilter,
    onCreatedToDateAdvFilter,
    createdToDateAdvFilter,
    totalCount,
    setTotalCount,
    onStatusFilter,
    statusFilter,
    onPhaseFilter,
    phaseFilter,
    onIncludePMFFilter,
    includePMFFilter,
    onIncludePMFAdvFilter,
    includePMFAdvFilter,
    onSortFieldFilter,
    onSortOrderFilter,
    onSortFieldAdvFilter,
    onSortOrderAdvFilter,
    connectivityRequestsStatus,
    connectivityRequestsPhase,
    CRStatus,
    CRPhase,
    subTypeList,
    onSelectedGroupsFilter,
    onSelectedPEOsFilter,
    selectedGroupsFilter,
    selectedPEOsFilter,
    onSelectedStatusesFilter,
    selectedStatusesFilter,
    onSelectedCarriersFilter,
    selectedCarriersFilter,
    onSelectedPartnerFilter,
    selectedPartnerFilter,
    onSelectedFeedsFilter,
    selectedFeedsFilter,
    onSelectedAssignedToFilter,
    selectedAssignedToFilter,
    onSelectedPhasesFilter,
    selectedPhasesFilter,
    onMigrationFilter,
    migrationFilter,
    onMigrationAdvFilter,
    migrationAdvFilter,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    tableRef,
    onMigrationStartFromDateAdvFilter,
    onMigrationStartToDateAdvFilter,
    onPlannedFirstProductionFromDateAdvFilter,
    onPlannedFirstProductionToDateAdvFilter,
    onSelectedMigrationPhasesAdvFilter,
    migrationStartDateFromDateAdvFilter,
    migrationStartDateToDateAdvFilter,
    plannedFirstProductionDateFromDateAdvFilter,
    plannedFirstProductionDateToDateAdvFilter,
    migrationPhasesAdvFilter,
    onPhaseDashboardChange,
    onStatusDashboardChange,
    onSetOnPhaseDashboardChange,
    onSetOnStatusDashboardChange,
    onSetStatusesFilterCard,
    statusesFilterCard,
    onsetFilterCard,
    connectivityRequestTypeFilter,
    connectivityRequestSubTypeFilter,
    selectedConnectivityRequestTypesFilter,
    selectedConnectivityRequestSubTypesFilter,
    onConnectivityRequestTypeFilter,
    onConnectivityRequestSubTypeFilter,
    onSelectedConnectivityRequestTypesFilter,
    onSelectedConnectivityRequestSubTypesFilter,
    agingConnectivityFilter,
    onSetAgingConnectivityFilter,
    agingConnectivityAdvFilter,
    onSetAgingConnectivityAdvFilter,
    onSetResponsiblePartyFilter,
    responsiblePartyFilter,
    onSetResponsiblePartyAdvFilter,
    responsiblePartyAdvFilter,
    setRefreshClicked,
    clientEffectiveDateFromFilter,
    onSetClientEffectiveDateFromFilter,
    clientEffectiveDateToFilter,
    onSetClientEffectiveDateToFilter,
    clientEffectiveDateFromAdvFilter,
    onSetClientEffectiveDateFromAdvFilter,
    clientEffectiveDateToAdvFilter,
    onSetClientEffectiveDateToAdvFilter,
    isPEOFilter,
    onSetIsPEOFilter,
    isPEOAdvFilter,
    onSetIsPEOAdvFilter,
    setSelectedCRs,
    selectedCRs,
    ...props
}) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const dialog = useModalDialog(0);
    const { openLoader, closeLoader } = useLoader();
    const [value, setValue] = React.useState(0);
    const routeHistory = useHistory();
    const [grpName, setGrpName] = React.useState();
    const [grpNameAutoValue, setGrpNameAutoValue] = React.useState("");
    const [peoName, setPEOName] = React.useState();
    const [peoNameAutoValue, setPEONameAutoValue] = React.useState("");
    const [assignedTo, setAssignedTo] = React.useState("");
    const [assignedToAutoValue, setAssignedToAutoValue] = React.useState("");
    const [carName, setCarName] = React.useState();
    const [partnerNames, setPartnerNames] = React.useState([]);
    const [carNameAutoValue, setCarNameAutoValue] = React.useState("");
    const [partner, setPartner] = React.useState("");
    const [feedName, setFeedName] = React.useState();
    const [feedNameAutoValue, setFeedNameAutoValue] = React.useState("");
    const [structureTypes, setStructureTypes] = React.useState([]);
    const [feedStructureType, setFeedStructureType] = React.useState("");
    const [feedDataType, setFeedDataType] = React.useState("");
    const [feedID, setFeedID] = React.useState();
    const [grpRequestID, setGrpRequestID] = React.useState();
    const [connectRequestID, setConnectRequestID] = React.useState();

    const [createdFromDate, setCreatedFromDate] = React.useState(null);
    const [createdToDate, setCreatedToDate] = React.useState(null);

    const [clientEffectiveDateFrom, setClientEffectiveDateFrom] = React.useState(null);
    const [clientEffectiveDateTo, setClientEffectiveDateTo] = React.useState(null);

    const [connectivityRequestType, setConnectivityRequestType] = React.useState("");
    const [connectivityRequestSubType, setConnectivityRequestSubType] = React.useState("");
    const [connectRequestStatus, setConnectRequestStatus] = React.useState("");
    const [connectRequestPhase, setConnectRequestPhase] = React.useState("");
    const [connectivityRequestsStatuses, setConnectivityRequestsStatuses] = React.useState([]);
    const [connectivityRequestsPhases, setConnectivityRequestsPhases] = React.useState([]);
    const [usersList, setUsersList] = React.useState([]);
    const [groupsList, setGroupsList] = React.useState([]);
    const [peosList, setPEOsList] = React.useState([]);
    const [carriersList, setCarriersList] = React.useState([]);
    const [feedsListFilter, setFeedsListFilter] = React.useState([]);
    const [includePMF, setIncludePMF] = React.useState("");
    const [migrationType, setMigrationType] = React.useState("");
    const [openDialog, setOpenDialog] = React.useState(false);

    const [responsiblePartiesList, setResponsiblePartiesList] = React.useState([]);

    function openModal(modalType, handleFunction, content) {
        switch (modalType) {
            case 0:
                return ConfirmModal(dialog, handleFunction, content);
            case 1:
                return SuccessModal(dialog, content);
            case 2:
                return FailureModal(dialog, content);
            default:
                break;
        }
    }

    const handleClickOpenDialog = () => {
        onCreatedFromDateFilter(null);
        onCreatedToDateFilter(null);
        onSetClientEffectiveDateFromFilter(null);
        onSetClientEffectiveDateToFilter(null);
        onStatusFilter("");
        onSetIsPEOFilter("");
        onConnectivityRequestIDFilter("");
        onGroupNameFilter("");
        onPEONameFilter("");
        onCarrierNameFilter("");
        onPartnerFilter("");
        onFeedIDFilter("");
        onFeedNameFilter("");
        onFeedStructureTypeFilter("");
        onFeedDataTypeFilter("");
        onConnectivityRequestTypeFilter("");
        onConnectivityRequestSubTypeFilter("");
        onPhaseFilter("");
        onIncludePMFFilter("");
        onMigrationFilter("");
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
        onSetStatusesFilterCard([]);
    };


    React.useEffect(() => {
        const list = ["All"]
        list.push(...connectivityRequestsStatus.map(s => s.ConnectivityRequestStatusName));
        setConnectivityRequestsStatuses(list);
    }, [connectivityRequestsStatus])

    React.useEffect(() => {
        setConnectivityRequestsPhases(connectivityRequestsPhase);
    }, [connectivityRequestsPhase])

    React.useEffect(() => {
        openLoader();
        APIs.getAllUsers().then((r) => (
            setUsersList(r?.data),
            closeLoader()
        ));
    }, [])

    React.useEffect(() => {
        openLoader();
        APIs.getStructureTypes().then((r) => !(r?.data?.length) ? setStructureTypes([]) : setStructureTypes(r?.data?.map((c) => (c)))).catch((e) => console.log("error", e));
    }, []);

    React.useEffect(() => {
        setAssignedToAutoValue(`${assignedTo?.FirstName ?? ""} ${assignedTo?.LastName ?? ""}`);
    }, [assignedTo])

    React.useEffect(() => {
        openLoader();
        APIs.getFeedsListByName({ FeedName: feedName ?? "" }).then((r) => !(r?.data?.length) ? setFeedsListFilter([]) : setFeedsListFilter(r?.data));
        APIs.getGroupsListByName({ GroupName: grpName ?? "" }).then((r) => !(r?.data?.length) ? setGroupsList([]) : setGroupsList(r?.data));
        APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
        APIs.getCarriersListByName({ CarrierName: carName ?? "" }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
        APIs.getPartnerNames().then((r) => setPartnerNames(r?.data));
    }, [])
    React.useEffect(() => {
        setGrpNameAutoValue(grpName);
    }, [grpName])
    React.useEffect(() => {
        setPEONameAutoValue(peoName);
    }, [peoName])
    React.useEffect(() => {
        setCarNameAutoValue(carName);
    }, [carName])
    React.useEffect(() => {
        setFeedNameAutoValue(feedName);
    }, [feedName])
    React.useEffect(() => {
        APIs.getResponsiblePartiesList().then(r => !(r?.data?.length) ? setResponsiblePartiesList([]) : setResponsiblePartiesList(r?.data));
    }, [])

    const handelIsPEOFilter = (e, newValue) => {
        onSetIsPEOFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleTabChange = (e, newValue) => {
        onSetStatusesFilterCard([]);
        setValue(newValue);
        const selectedStatus = connectivityRequestsStatus[newValue - 1]?.ConnectivityRequestStatusID;
        setConnectRequestStatus(selectedStatus);
        onStatusFilter(selectedStatus);
        onSelectedPage(1);
        onPageSize(30);
    };

    React.useEffect(() => {

        if (statusFilter === 1) { setValue(3); setConnectRequestStatus(1); } //In Progress
        else if (statusFilter === 2) { setValue(5); setConnectRequestStatus(2); }//On Hold
        else if (statusFilter === 3) { setValue(1); setConnectRequestStatus(3); } //Blocked
        else if (statusFilter === 8) { setValue(8); setConnectRequestStatus(8); } //Production Disabled
        else if (statusFilter === 5) { setValue(2); setConnectRequestStatus(5); } //Cancelled
        else if (statusFilter === 6) { setValue(4); setConnectRequestStatus(6); } //Not Started
        else if (statusFilter === 7) { setValue(7); setConnectRequestStatus(7); } //Production
        else if (statusFilter === 9) { setValue(6); setConnectRequestStatus(9); } //Pending
    }, [statusFilter])



    React.useEffect(() => {
        if (CRStatus === "Not Started") { setValue(1); setConnectRequestStatus(1); }
        else if (CRStatus === "In Progress") { setValue(2); setConnectRequestStatus(2); }
        else if (CRStatus === "On Hold") { setValue(3); setConnectRequestStatus(3); }
        else if (CRStatus === "Blocked") { setValue(4); setConnectRequestStatus(4); }
        else if (CRStatus === "Cancelled") { setValue(5); setConnectRequestStatus(5); }
        else if (CRStatus === "Production") { setValue(6); setConnectRequestStatus(6); }
        else if (CRStatus === "Production Disabled") { setValue(7); setConnectRequestStatus(7); }
    }, [CRStatus])

    React.useEffect(() => {
        if (CRPhase === "Requirements Gathering") { setConnectRequestPhase(1); onPhaseFilter(1); }
        else if (CRPhase === "Obtain Customer Dataset") { setConnectRequestPhase(2); onPhaseFilter(2); }
        else if (CRPhase === "Resource Assignment") { setConnectRequestPhase(3); onPhaseFilter(3); }
        else if (CRPhase === "Dataset Validation") { setConnectRequestPhase(4); onPhaseFilter(4); }
        else if (CRPhase === "Mapping") { setConnectRequestPhase(5); onPhaseFilter(5); }
        else if (CRPhase === "Testing") { setConnectRequestPhase(6); onPhaseFilter(6); }
        else if (CRPhase === "Production") { setConnectRequestPhase(7); onPhaseFilter(7); }
        else if (CRPhase === "Not Started") { setConnectRequestPhase(8); onPhaseFilter(8); }
    }, [CRPhase])

    React.useEffect(() => {
        if (CRStatus !== undefined || CRPhase !== undefined) {
            setCreatedFromDate(todayDate);
            setCreatedToDate(todayDate);
        }
    }, [CRStatus, CRPhase])

    const handleClientEffectiveDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setClientEffectiveDateFrom(date);
            onSetClientEffectiveDateFromFilter(date);
        }
        else {
            setClientEffectiveDateTo(date);
            onSetClientEffectiveDateToFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleCreatedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setCreatedFromDate(date);
            onCreatedFromDateFilter(date);
        }
        else {
            setCreatedToDate(date);
            onCreatedToDateFilter(date);
        }
        onSelectedPage(1);
        onPageSize(30);
    };

    const handleFeedIDFilter = (e) => {
        setFeedID(e.target.value);
        onFeedIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleConnectivityRequestTypeFilter = (e) => {
        setConnectivityRequestType(e.target.value);
        onConnectivityRequestTypeFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleConnectivityRequestSubTypeFilter = (e) => {
        setConnectivityRequestSubType(e.target.value);
        onConnectivityRequestSubTypeFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleFeedStructureTypeFilter = (e) => {
        setFeedStructureType(e.target.value);
        onFeedStructureTypeFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }


    const handleFeedDataTypeFilter = (e) => {
        setFeedDataType(e.target.value);
        onFeedDataTypeFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleConnectivityRequestIDFilter = (e) => {
        setConnectRequestID(e.target.value);
        onConnectivityRequestIDFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }


    const handlePartnerFilter = (e) => {
        setPartner(e.target.value);
        onPartnerFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }


    const handleConnectivityRequestPhaseFilter = (e, newValue) => {
        setConnectRequestPhase(e.target.value);
        onPhaseFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleIncludePMFFilter = (e, newValue) => {
        setIncludePMF(e.target.value);
        onIncludePMFFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }
    const handleAgingConnectivitiesFilter = (e, newValue) => {

        onSetAgingConnectivityFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }
    const handleMigrationTypeFilter = (e) => {
        setMigrationType(e.target.value);
        onMigrationFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleResponsiblePartyFilter = (e) => {
        onSetResponsiblePartyFilter(e.target.value);
        onSelectedPage(1);
        onPageSize(30);
    }

    const handleClearSearch = () => {
        setValue(0);
        setConnectRequestStatus("");
        onStatusFilter("");
        setGrpName("");
        onGroupNameFilter("");
        setPEOName("");
        onPEONameFilter("");
        setAssignedTo("");
        onAssignedToFilter("");
        setCarName("");
        onCarrierNameFilter("");

        setPartner("");
        onPartnerFilter("");
        onSelectedPartnerFilter([]);

        setFeedName("");
        onFeedNameFilter("");
        setFeedStructureType("");
        onFeedStructureTypeFilter("");
        setFeedDataType("");
        onFeedDataTypeFilter("");
        setFeedID("");
        onFeedIDFilter("");
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        setConnectRequestID("");
        onConnectivityRequestIDFilter("");
        setConnectivityRequestType("");
        onConnectivityRequestTypeFilter("");
        onConnectivityRequestSubTypeFilter("");
        setConnectRequestPhase("");
        onPhaseFilter("");
        setIncludePMF("");
        onIncludePMFFilter("");
        setCreatedFromDate(null);
        onCreatedFromDateFilter(null);
        setCreatedToDate(null);
        onCreatedToDateFilter(null);
        onSetIsPEOFilter("");
        onSetIsPEOAdvFilter("");
        onSetClientEffectiveDateFromFilter(null);
        setClientEffectiveDateFrom(null);
        setClientEffectiveDateTo(null);
        onSetClientEffectiveDateToFilter(null);
        onSelectedPage(1);
        setMigrationType("");
        onMigrationFilter("");
        onMigrationStartFromDateAdvFilter(null);
        onMigrationStartToDateAdvFilter(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        onSelectedMigrationPhasesAdvFilter([]);
        onSetStatusesFilterCard([]);
        onSelectedConnectivityRequestTypesFilter([]);
        onSelectedConnectivityRequestSubTypesFilter([]);
        onSetResponsiblePartyFilter("");
        onSetResponsiblePartyAdvFilter([]);
        onSetAgingConnectivityFilter("");
        onSetAgingConnectivityAdvFilter("");

    }

    const handleClearSearchReset = () => {
        setValue(0);
        setConnectRequestStatus("");
        onStatusFilter("");
        setGrpName("");
        onGroupNameFilter("");
        setPEOName("");
        onPEONameFilter("");
        setAssignedTo("");
        onAssignedToFilter("");
        setCarName("");
        onCarrierNameFilter("");
        setPartner("");
        onPartnerFilter("");
        onSelectedPartnerFilter([]);
        setFeedName("");
        onFeedNameFilter("");
        setFeedStructureType("");
        onFeedStructureTypeFilter("");
        onSelectedFeedStructureTypesFilter([]);
        setFeedDataType("");
        onFeedDataTypeFilter("");
        onSelectedFeedDataTypesFilter([]);
        setFeedID("");
        onFeedIDFilter("");
        onFeedIDAdvFilter("");
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        setConnectRequestID("");
        onConnectivityRequestIDFilter("");
        onConnectivityRequestIDAdvFilter("");
        setConnectivityRequestType("");
        onConnectivityRequestTypeFilter("");
        onConnectivityRequestSubTypeFilter("");
        onSetIsPEOFilter("");
        onSetIsPEOAdvFilter("");
        setConnectRequestPhase("");
        onPhaseFilter("");
        setIncludePMF("");
        onIncludePMFAdvFilter("");
        setCreatedFromDate(null);
        onCreatedFromDateAdvFilter(null);
        setCreatedToDate(null);
        onSetClientEffectiveDateToFilter(null);
        onSetClientEffectiveDateFromFilter(null);
        onCreatedToDateFilter(null);
        onCreatedFromDateFilter(null);
        onCreatedToDateAdvFilter(null);
        onSetClientEffectiveDateFromAdvFilter(null);
        setClientEffectiveDateFrom(null);
        setClientEffectiveDateTo(null);
        onSetClientEffectiveDateToAdvFilter(null);
        onSelectedPageAdv(1);
        onSelectedGroupsFilter([]);
        onSelectedPEOsFilter([]);
        onSelectedStatusesFilter([]);
        onSelectedCarriersFilter([]);
        onSelectedFeedsFilter([]);
        onSelectedAssignedToFilter([]);
        onSelectedPhasesFilter([]);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setAdvancedFiltersClicked(false);
        setMigrationType("");
        onMigrationFilter("");
        onMigrationAdvFilter([]);
        onMigrationStartFromDateAdvFilter(null);
        onMigrationStartToDateAdvFilter(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        onSelectedMigrationPhasesAdvFilter([]);
        onSetStatusesFilterCard([]);
        onSelectedConnectivityRequestTypesFilter([]);
        onSelectedConnectivityRequestSubTypesFilter([]);
        onSetResponsiblePartyFilter("");
        onSetResponsiblePartyAdvFilter([]);
        onSetAgingConnectivityFilter("");
        onSetAgingConnectivityAdvFilter("");
        onSelectedFeedDataTypesFilter("");
    }


    React.useEffect(() => {
        if (onPhaseDashboardChange) {

            onStatusFilter("");
            setConnectRequestStatus("");
            setValue(0);
            handleClearDashBordReset();
            onsetFilterCard(false);
        }
    }, [phaseFilter, onPhaseDashboardChange === true])
    React.useEffect(() => {
        if (onStatusDashboardChange) {

            onPhaseFilter("");
            setConnectRequestPhase("");
            handleClearDashBordReset();
            onsetFilterCard(false);
        }
    }, [statusFilter, onStatusDashboardChange === true])


    const handleClearDashBordReset = () => {
        setGrpName("");
        onGroupNameFilter("");
        setPEOName("");
        onPEONameFilter("");
        setAssignedTo("");
        onAssignedToFilter("");
        setCarName("");
        onCarrierNameFilter("");
        onSetIsPEOFilter("");
        onSetIsPEOAdvFilter("");

        setPartner("");
        onPartnerFilter("");
        onSelectedPartnerFilter([]);

        setFeedName("");
        onFeedNameFilter("");
        setFeedStructureType("");
        onFeedStructureTypeFilter("");
        setFeedDataType("");
        onFeedDataTypeFilter("");
        onSelectedFeedStructureTypesFilter([]);
        onMigrationAdvFilter([]);
        onIncludePMFAdvFilter("");
        setFeedID("");
        onFeedIDFilter("");
        onFeedIDAdvFilter("");
        setGrpRequestID("");
        onGroupRequestIDFilter("");
        setConnectRequestID("");
        onConnectivityRequestIDFilter("");
        setIncludePMF("");
        onIncludePMFFilter("");
        setCreatedFromDate(null);
        onCreatedFromDateFilter(null);
        setCreatedToDate(null);
        onCreatedToDateFilter(null);
        onSetClientEffectiveDateFromFilter(null);
        setClientEffectiveDateFrom(null);
        setClientEffectiveDateTo(null);
        onSetClientEffectiveDateToFilter(null);
        onSelectedPage(1);
        setMigrationType("");
        onMigrationFilter("");
        onMigrationStartFromDateAdvFilter(null);
        onMigrationStartToDateAdvFilter(null);
        onPlannedFirstProductionFromDateAdvFilter(null);
        onPlannedFirstProductionToDateAdvFilter(null);
        onSelectedMigrationPhasesAdvFilter([]);
        onSetResponsiblePartyAdvFilter([]);
        onConnectivityRequestIDAdvFilter("");
        onSelectedFeedDataTypesFilter("");
        onSelectedPhasesFilter([]);
        onSelectedPageAdv(1);
        onSelectedGroupsFilter([]);
        onSelectedPEOsFilter([]);
        onSelectedStatusesFilter([]);
        onSelectedCarriersFilter([]);
        onSelectedFeedsFilter([]);
        onSelectedAssignedToFilter([]);
        onSetResponsiblePartyFilter("");
        onSetAgingConnectivityFilter("");
        onSetAgingConnectivityAdvFilter("");
        onCreatedFromDateAdvFilter(null);
        onCreatedToDateAdvFilter(null);
        onSetClientEffectiveDateFromAdvFilter(null);
        setClientEffectiveDateFrom(null);
        setClientEffectiveDateTo(null);
        onSetClientEffectiveDateToAdvFilter(null);
        setConnectivityRequestType("");
        onConnectivityRequestTypeFilter("");
        onConnectivityRequestSubTypeFilter("");
        onSelectedConnectivityRequestTypesFilter([]);
        onSelectedConnectivityRequestSubTypesFilter([]);
        setAdvancedFiltersClicked(false);
        onSetOnStatusDashboardChange(false);
        onSetOnPhaseDashboardChange(false);

    }


    const handleApproveBulkPendingCRs = (c) => {
        const body = {
            'ConnectivityRequestsIDs': selectedCRs.map(scr => ({
                ConnectivityRequestID: scr?.ConnectivityRequestID || "",
                GroupName: scr?.GroupName || "",
                CarrierName: scr?.CarrierName || "",
                CreatedByEmail: scr?.CreatedByEmail || ""
            }))
        }


        openModal(0, () => handleApproveCRAPI(body), {
            title: "Please Confirm",
            question: `Are you sure you want to approve all selected connectivity requests?`,
        });
    }

    const handleApproveCRAPI = (body) => {
        openLoader()
        APIs.approvePendingBulckCRs(body).then((r) => {
            setRefreshClicked(true)
            if (r?.success === "0") {
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r?.msg,
                });
            }
            else {
                closeLoader();
                window.location.reload();
            }
        });
    }

    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);

    const isIsovedSuperAdminOrSuperAdmin = () => {
        return userActions?.includes("60") || userActions?.includes("20");
    }

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                {userActions?.includes("54") &&
                    <Button
                        className="blue-outlined-btn"
                        onClick={() => APIs.downloadConnectivityRequestsFullReport().then(r => r?.msg === "No data available" ?
                            FailureModal(dialog, {
                                title: 'Failure',
                                body: 'No Data Available',
                            }) : window.open(r?.url)
                        )}
                    >
                        <svg width="22" height="23" viewBox="0 0 22 23" >
                            <use xlinkHref={`${exportIconSVG}#exportIcon`}></use>
                        </svg>
                        <span className="ml-2">Export Full Report</span>
                    </Button>
                }
                {userActions?.includes("55") &&
                    <Button
                        className="blue-outlined-btn ml-2"
                        onClick={() => APIs.downloadMigrationsSummaryFileFullReport().then(r => r?.msg === "No data available" ? FailureModal(dialog, {
                            title: 'Failure',
                            body: 'No Data Available',
                        }) : window.open(r?.url)
                        )}
                    >
                        <svg width="22" height="23" viewBox="0 0 22 23" >
                            <use xlinkHref={`${exportIconSVG}#exportIcon`}></use>
                        </svg>
                        <span className="ml-2">Export Migration Summary Report</span>
                    </Button>
                }
                {/* 7 - superAdmin, 4 - adminNetwork, 2 - isolvedSuperAdmin */}
                {(userRoles.includes("7") || userRoles.includes("4") || userRoles.includes("2")) &&
                    <Button
                        className="blue-outlined-btn ml-2"
                        onClick={() => APIs.downloadNetworkPartnerFile().then(r => r?.msg === "No data available" ? FailureModal(dialog, {
                            title: 'Failure',
                            body: 'No Data Available',
                        }) : window.open(r?.url)
                        )}
                    >
                        <svg width="22" height="23" viewBox="0 0 22 23" >
                            <use xlinkHref={`${exportIconSVG}#exportIcon`}></use>
                        </svg>
                        <span className="ml-2">Export Pending Approval Report</span>
                    </Button>
                }
            </div>
            <div className="connectivity-status-tabs" style={{ minHeight: "50px" }} >
                {quickFiltersClicked && (
                    <MultipleTabsTitles style={{ maxWidth: "90%", overflow: "auto" }} tabTitle={connectivityRequestsStatuses} value={value} setValue={setValue} onChange={handleTabChange} />
                )}

            </div>

            <Paper className="content-card-paper">
                <div className="search-container">
                    <div className="row alignvh-center">
                        <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                        <Button
                            className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                            onClick={handleClickOpenDialog}
                            style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                        >
                            <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1" >
                                <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                            </svg>
                            Advanced Filters
                        </Button>
                        <React.Fragment>
                            {connectivityRequestIDFilter && <TruncatedChip body={`Connectivity Request ID: ${connectivityRequestIDFilter}`} />}
                            {connectivityRequestIDAdvFilter && <TruncatedChip body={`Connectivity Request ID: ${connectivityRequestIDAdvFilter}`} />}
                            {groupNameFilter && <TruncatedChip body={`Client: ${groupNameFilter}`} />}
                            {selectedGroupsFilter && selectedGroupsFilter?.length > 0 && <TruncatedChip body={`Clients: ${selectedGroupsFilter?.map(s => s.GroupName).join(" - ")}`} />}
                            {peoNameFilter && <TruncatedChip body={`PEO: ${peoNameFilter}`} />}
                            {selectedPEOsFilter && selectedPEOsFilter?.length > 0 && <TruncatedChip body={`PEOs: ${selectedPEOsFilter?.map(s => s.PeoName).join(" - ")}`} />}
                            {carrierNameFilter && <TruncatedChip body={`Carrier: ${carrierNameFilter}`} />}
                            {selectedCarriersFilter && selectedCarriersFilter?.length > 0 && <TruncatedChip body={`Carriers: ${selectedCarriersFilter?.map(s => s.CarrierName).join(" - ")}`} />}
                            {(isIsovedSuperAdminOrSuperAdmin() || userRoles.includes("4")) && quickFiltersClicked && partner && <TruncatedChip body={`Partner: ${partnerFilter}`} />}
                            {feedIDFilter && <TruncatedChip body={`Feed ID: ${feedIDFilter}`} />}
                            {feedIDAdvFilter && <TruncatedChip body={`Feed ID: ${feedIDAdvFilter}`} />}
                            {feedNameFilter && <TruncatedChip body={`Feed: ${feedNameFilter}`} />}
                            {feedDataTypeFilter && <TruncatedChip body={`Feed Data Type: ${feedDataTypeFilter === "Full" ? "Full File" : "Changes Only File"}`} />}
                            {selectedFeedDataTypesFilter && <TruncatedChip body={`Data Types: ${selectedFeedDataTypesFilter === "Full" ? "Full File" : "Changes Only File"}`} />}
                            {feedStructureTypeFilter && <TruncatedChip body={`Structure Type: ${structureTypes?.filter(s => s.StructureTypeID == feedStructureTypeFilter)[0].StructureTypeName}`} />}
                            {selectedFeedStructureTypesFilter && selectedFeedStructureTypesFilter?.length > 0 && <TruncatedChip body={`Structure Types: ${selectedFeedStructureTypesFilter?.map(s => s.StructureTypeName).join(" - ")}`} />}
                            {responsiblePartyFilter && <TruncatedChip body={`Responsible Party: ${responsiblePartiesList.filter(s => s.Id == responsiblePartyFilter)[0].Name}`} />}
                            {responsiblePartyAdvFilter.length > 0 && <TruncatedChip body={`Responsible Party: ${responsiblePartyAdvFilter.map(s => s.Name).join(" - ")}`} />}
                            {selectedFeedsFilter && selectedFeedsFilter?.length > 0 && <TruncatedChip body={`Feeds: ${selectedFeedsFilter?.map(s => s.FeedName).join(" - ")}`} />}
                            {quickFiltersClicked && createdFromDateFilter && createdToDateFilter && <TruncatedChip body={`Created Date: From: ${Moment(createdFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(createdToDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && createdFromDateFilter && !createdToDateFilter && <TruncatedChip body={`Created Date: From: ${Moment(createdFromDateFilter).format("MM/DD/YYYY")}`} />}
                            {quickFiltersClicked && !createdFromDateFilter && createdToDateFilter && <TruncatedChip body={`Created Date: To: ${Moment(createdToDateFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && createdFromDateAdvFilter && createdToDateAdvFilter && <TruncatedChip body={`Created Date: From: ${Moment(createdFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(createdToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && createdFromDateAdvFilter && !createdToDateAdvFilter && <TruncatedChip body={`Created Date: From: ${Moment(createdFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && !createdFromDateAdvFilter && createdToDateAdvFilter && <TruncatedChip body={`Created Date: To: ${Moment(createdToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {assignedToFilter && <TruncatedChip body={`Assigned To: ${assignedToFilter.FirstName} ${assignedToFilter.LastName}`} />}
                            {selectedAssignedToFilter && selectedAssignedToFilter?.length > 0 && <TruncatedChip body={`Assigned To: ${selectedAssignedToFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}
                            {phaseFilter && <TruncatedChip body={`Connectivity Request Phase: ${connectivityRequestsPhase?.filter(r => r.ConnectivityRequestPhaseID == phaseFilter)[0].ConnectivityRequestPhasesName}`} />}
                            {selectedPhasesFilter && selectedPhasesFilter?.length > 0 && <TruncatedChip body={`Connectivity Request Phase: ${selectedPhasesFilter?.map(s => s.ConnectivityRequestPhasesName).join(" - ")}`} />}
                            {statusFilter && <TruncatedChip body={`Connectivity Request Status: ${connectivityRequestsStatus?.filter(s => s.ConnectivityRequestStatusID == statusFilter)[0].ConnectivityRequestStatusName}`} />}
                            {selectedStatusesFilter && selectedStatusesFilter?.length > 0 && <TruncatedChip body={`Connectivity Request Status: ${selectedStatusesFilter?.map(s => s.ConnectivityRequestStatusName).join(" - ")}`} />}
                            {includePMFFilter && includePMFFilter !== "null" && <TruncatedChip body={`Include Pre-Mapping Form: ${includePMFFilter === "1" ? "Yes" : includePMFFilter === "2" ? "No" : ""}`} />}
                            {includePMFAdvFilter && includePMFAdvFilter !== "null" && <TruncatedChip body={`Include Pre-Mapping Form: ${includePMFAdvFilter === "1" ? "Yes" : includePMFAdvFilter === "2" ? "No" : ""}`} />}
                            {migrationFilter && <TruncatedChip body={`Migration Type: ${migrationFilter}`} />}
                            {migrationAdvFilter && migrationAdvFilter.length > 0 && <TruncatedChip body={`Migration Type: ${migrationAdvFilter.map(s => s).join(" - ")}`} />}
                            {connectivityRequestTypeFilter && <TruncatedChip body={`Connectivity Request Type: ${connectivityRequestTypeFilter}`} />}
                            {selectedConnectivityRequestTypesFilter && selectedConnectivityRequestTypesFilter?.length > 0 && <TruncatedChip body={`Connectivity Request Types: ${selectedConnectivityRequestTypesFilter?.join(" - ")}`} />}
                            {connectivityRequestSubTypeFilter && <TruncatedChip body={`Connectivity Request Sub Type: ${subTypeList?.filter(s => s.Id == connectivityRequestSubTypeFilter)[0].Name}`} />}
                            {selectedConnectivityRequestSubTypesFilter && selectedConnectivityRequestSubTypesFilter?.length > 0 && <TruncatedChip body={`Connectivity Request Sub Types: ${selectedConnectivityRequestSubTypesFilter?.map((n, ni) => subTypeList?.filter(s => s.Id == n)[0].Name).join(" - ")}`} />}

                            {advancedFiltersClicked && migrationStartDateFromDateAdvFilter && migrationStartDateToDateAdvFilter && <TruncatedChip body={`Migration Start Date: From: ${Moment(migrationStartDateFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(migrationStartDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && migrationStartDateFromDateAdvFilter && !migrationStartDateToDateAdvFilter && <TruncatedChip body={`Migration Start Date: From: ${Moment(migrationStartDateFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && !migrationStartDateFromDateAdvFilter && migrationStartDateToDateAdvFilter && <TruncatedChip body={`Migration Start Date: To: ${Moment(migrationStartDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                            {advancedFiltersClicked && plannedFirstProductionDateFromDateAdvFilter && plannedFirstProductionDateToDateAdvFilter && <TruncatedChip body={`Planned First Production Date: From: ${Moment(plannedFirstProductionDateFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(plannedFirstProductionDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && plannedFirstProductionDateFromDateAdvFilter && !plannedFirstProductionDateToDateAdvFilter && <TruncatedChip body={`Planned First Production Date: From: ${Moment(plannedFirstProductionDateFromDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && !plannedFirstProductionDateFromDateAdvFilter && plannedFirstProductionDateToDateAdvFilter && <TruncatedChip body={`Planned First Production Date: To: ${Moment(plannedFirstProductionDateToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && migrationPhasesAdvFilter && migrationPhasesAdvFilter.length > 0 && <TruncatedChip body={`Migration Phase: ${migrationPhasesAdvFilter?.map(s => s.Name).join(" - ")}`} />}
                            {/* statusesFilterCard */}
                            {statusesFilterCard && statusesFilterCard?.length > 0 && <TruncatedChip body={`Connectivity Request Status: ${statusesFilterCard?.map((n, ni) => connectivityRequestsStatus?.filter(s => s.ConnectivityRequestStatusID == n)[0].ConnectivityRequestStatusName).join(" - ")}`} />}
                            {agingConnectivityFilter && agingConnectivityFilter !== "null" && <TruncatedChip body={`Include Aging: ${agingConnectivityFilter === "1" ? "Yes" : ""}`} />}
                            {agingConnectivityAdvFilter && agingConnectivityAdvFilter !== "null" && <TruncatedChip body={`Include Aging: ${agingConnectivityAdvFilter === "1" ? "Yes" : ""}`} />}
                            {/* client Effective Date */}
                            {clientEffectiveDateFromFilter && clientEffectiveDateToFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Client Effective Date: From: ${Moment(clientEffectiveDateFromFilter).format("MM/DD/YYYY")} To: ${Moment(clientEffectiveDateToFilter).format("MM/DD/YYYY")}`} />}
                            {clientEffectiveDateFromFilter && !clientEffectiveDateToFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Client Effective Date: From: ${Moment(clientEffectiveDateFromFilter).format("MM/DD/YYYY")}`} />}
                            {!clientEffectiveDateFromFilter && clientEffectiveDateToFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Client Effective Date: To: ${Moment(clientEffectiveDateToFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && clientEffectiveDateFromAdvFilter && clientEffectiveDateToAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Client Effective Date: From: ${Moment(clientEffectiveDateFromAdvFilter).format("MM/DD/YYYY")} To: ${Moment(clientEffectiveDateToAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && clientEffectiveDateFromAdvFilter && !clientEffectiveDateToAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Client Effective Date: From: ${Moment(clientEffectiveDateFromAdvFilter).format("MM/DD/YYYY")}`} />}
                            {advancedFiltersClicked && !clientEffectiveDateFromAdvFilter && clientEffectiveDateToAdvFilter && <TruncatedChip className="mt-1 mr-2 mb-1" body={`Client Effective Date: To: ${Moment(clientEffectiveDateToAdvFilter).format("MM/DD/YYYY")}`} />}
                            {isPEOFilter && <TruncatedChip body={`Include PEO:  ${isPEOFilter == 1 ? "Yes" : "No"}`} />}
                            {isPEOAdvFilter && <TruncatedChip body={`Include PEO:  ${isPEOAdvFilter == 1 ? "Yes" : "No"}`} />}

                            {isIsovedSuperAdminOrSuperAdmin() && advancedFiltersClicked && selectedPartnerFilter?.length > 0 && <TruncatedChip body={`Partner: ${selectedPartnerFilter?.map(s => s).join(" - ")}`} />}
                        </React.Fragment>
                    </div>
                    {quickFiltersClicked && (
                        (userActions?.includes("43") || userActions?.includes("20")) ? (
                            <div className="row">
                                <div className="col-xl-3 mt-3">
                                    <span>Connectivity Request ID:</span>
                                    <br />
                                    <input type="text" className="search-input w-100" value={connectRequestID} onChange={handleConnectivityRequestIDFilter} />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Client Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={groupsList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={grpNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.GroupName}
                                        onChange={(e, value) => {
                                            setGrpName(value?.GroupName ?? "");
                                            onGroupNameFilter(value?.GroupName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.GroupName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setGrpNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>PEO Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={peosList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={peoNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.PeoName}
                                        onChange={(e, value) => {
                                            setPEOName(value?.PeoName ?? "");
                                            onPEONameFilter(value?.PeoName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.PeoName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setPEONameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Carrier Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={carriersList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={carNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.CarrierName}
                                        onChange={(e, value) => {
                                            setCarName(value?.CarrierName ?? "");
                                            onCarrierNameFilter(value?.CarrierName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.CarrierName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setCarNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                {isIsovedSuperAdminOrSuperAdmin() &&
                                    <div className="col-xl-3 mt-3">
                                        <span>Partner:</span>
                                        <br />
                                        <FormControl className="search-dropdown w-100">
                                            <NativeSelect
                                                value={partner}
                                                // multiple={true}
                                                onChange={handlePartnerFilter}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value="" />
                                                {/* <option aria-label="Direct" value="Direct">Direct</option> */}
                                                {partnerNames?.map((s, si) => (
                                                    <option key={`partner-name-${si}`} value={s?.PartnerName}>{s?.PartnerName}</option>
                                                ))}
                                                {/* <option aria-label="Network" value="2">Network</option> */}
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                }
                                <div className="col-xl-3 mt-3">
                                    <span>Feed ID:</span>
                                    <br />
                                    <input type="text" className="search-input w-100" value={feedID} onChange={handleFeedIDFilter} />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Feed Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={feedsListFilter}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={feedNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.FeedName}
                                        onChange={(e, value) => {
                                            setFeedName(value?.FeedName ?? "");
                                            onFeedNameFilter(value?.FeedName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.FeedName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setFeedNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Feed Structure Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={feedStructureTypeFilter ?? ""}
                                            onChange={handleFeedStructureTypeFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            {structureTypes?.map((s, si) => (
                                                <option key={`structure-type-${si}`} value={s?.StructureTypeID}>{s?.StructureTypeName}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Feed Data Type:</span>
                                    <br />
                                    {/* {isIsovedSuperAdminOrSuperAdmin() && < br />} */}
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={feedDataTypeFilter ?? ""}
                                            onChange={handleFeedDataTypeFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            <option aria-label="full" value="Full">Full File</option>
                                            <option aria-label="changes" value="Changes">Changes Only File</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Assigned To:</span>
                                    <br />
                                    <Autocomplete
                                        options={usersList?.sort((s1, s2) => {
                                            return s2.IsEnabled - s1.IsEnabled;
                                        })}
                                        groupBy={(option) => option.IsEnabled}
                                        renderGroup={(params) => (
                                            <li key={params.key}>
                                                <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                                <GroupItems>{params.children}</GroupItems>
                                            </li>
                                        )}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={assignedToAutoValue ?? ""}
                                        getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                        onChange={(e, newValue) => {
                                            setAssignedTo(newValue ?? "");
                                            onAssignedToFilter(newValue ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {`${option.FirstName} ${option.LastName}`}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setAssignedToAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Created Date:</span>
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdFromDate}
                                                onChange={handleCreatedDateChange("fromDate")}
                                                placeholder="From Date"
                                                KeyboardButtonProps={{ "aria-label": "change date", }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdToDate}
                                                onChange={handleCreatedDateChange("toDate")}
                                                placeholder="To Date"
                                                KeyboardButtonProps={{ "aria-label": "change date", }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Phase:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={phaseFilter ?? ""}
                                            onChange={handleConnectivityRequestPhaseFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            {connectivityRequestsPhases?.map((p, pi) => (
                                                <option key={`connectivity-request-phase-${pi}`} value={p.ConnectivityRequestPhaseID}>{p.ConnectivityRequestPhasesName}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Migration:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={migrationType ?? ""}
                                            onChange={handleMigrationTypeFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="No">No</option>
                                            <option value="eBenefits Network">eBenefits Network</option>
                                            <option value="Everything Benefits">Everything Benefits</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Include Pre-Mapping Form:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={includePMF ?? ""}
                                            onChange={handleIncludePMFFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="null" />
                                            <option value="1">Yes</option>
                                            <option value="2">No</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Connectivity Request Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={connectivityRequestTypeFilter ?? ""}
                                            onChange={handleConnectivityRequestTypeFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="EDI">EDI</option>
                                            <option value="Forms">Forms</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Connectivity Request Sub Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={connectivityRequestSubTypeFilter ?? ""}
                                            onChange={handleConnectivityRequestSubTypeFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            {subTypeList?.map((s, si) => (

                                                <option key={`CR-EDI-SubType-${si}`} value={s?.Id} >{s.Name}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Responsible Party:</span>
                                    {isIsovedSuperAdminOrSuperAdmin() && <br />}
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={responsiblePartyFilter}
                                            onChange={handleResponsiblePartyFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            {responsiblePartiesList?.map((p, pi) => (
                                                <option key={`responsible-parties-list-${pi}`} value={p.Id}>{p.Name}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Include Aging:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={agingConnectivityFilter ?? ""}
                                            onChange={handleAgingConnectivitiesFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="null" />
                                            <option value="1">Yes</option>
                                        </NativeSelect >
                                    </FormControl >
                                </div >
                                <div className="col-xl-3 mt-3">
                                    <span>Include PEO:</span>
                                    <br />
                                    {/* {!isIsovedSuperAdminOrSuperAdmin() && <br />} */}
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={isPEOFilter ?? ""}
                                            onChange={handelIsPEOFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </NativeSelect >
                                    </FormControl >
                                </div >
                                <div className="col-xl-3 mt-3">
                                    <span>Client Effective Date:</span>
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={clientEffectiveDateFrom}
                                                onChange={handleClientEffectiveDateChange("fromDate")}
                                                placeholder="From Date"
                                                KeyboardButtonProps={{ "aria-label": "change date", }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={clientEffectiveDateTo}
                                                onChange={handleClientEffectiveDateChange("toDate")}
                                                placeholder="To Date"
                                                KeyboardButtonProps={{ "aria-label": "change date", }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div >
                        ) : (
                            <div className="row">
                                <div className="col-xl-3 mt-3">
                                    <span>Client Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={groupsList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={grpNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.GroupName}
                                        onChange={(e, value) => {
                                            setGrpName(value?.GroupName ?? "");
                                            onGroupNameFilter(value?.GroupName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.GroupName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setGrpNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>PEO Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={peosList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={peoNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.PeoName}
                                        onChange={(e, value) => {
                                            setPEOName(value?.PeoName ?? "");
                                            onPEONameFilter(value?.PeoName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.PeoName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setPEONameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Carrier Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={carriersList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={carNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.CarrierName}
                                        onChange={(e, value) => {
                                            setCarName(value?.CarrierName ?? "");
                                            onCarrierNameFilter(value?.CarrierName ?? "");
                                            onSelectedPage(1);
                                            onPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.CarrierName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setCarNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Connectivity Request Sub Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={connectivityRequestSubTypeFilter ?? ""}
                                            onChange={handleConnectivityRequestSubTypeFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            {subTypeList?.map((s, si) => (

                                                <option key={`CR-EDI-SubType-${si}`} value={s?.Id} >{s.Name}</option>
                                            ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                {(isIsovedSuperAdminOrSuperAdmin() || userRoles.includes("4")) &&
                                    <div className="col-xl-3 mt-3">
                                        <span>Partner:</span>
                                        <br />
                                        <FormControl className="search-dropdown w-100">
                                            <NativeSelect
                                                value={partner}
                                                onChange={handlePartnerFilter}
                                                input={<BootstrapInput />}
                                            >
                                                <option aria-label="None" value="" />
                                                {partnerNames?.map((s, si) => (
                                                    <option key={`partner-name-${si}`} value={s?.PartnerName}>{s?.PartnerName}</option>
                                                ))}
                                            </NativeSelect>
                                        </FormControl>
                                    </div>
                                }
                                <div className="col-xl-3 mt-3">
                                    <span>Created Date:</span>
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdFromDate}
                                                onChange={handleCreatedDateChange("fromDate")}
                                                placeholder="From Date"
                                                KeyboardButtonProps={{ "aria-label": "change date", }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={createdToDate}
                                                onChange={handleCreatedDateChange("toDate")}
                                                placeholder="To Date"
                                                KeyboardButtonProps={{ "aria-label": "change date", }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16">
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Migration:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={migrationType ?? ""}
                                            onChange={handleMigrationTypeFilter}
                                            input={<BootstrapInput />}
                                            multiple={false}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="No">No</option>
                                            <option value="eBenefits Network">eBenefits Network</option>
                                            <option value="Everything Benefits">Everything Benefits</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                            </div >
                        ))
                    }
                    <div className="col-xl-12 mt-4">
                        <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                            <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                                <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                            </svg>
                            <span className="ml-1">Refresh List</span>
                        </Button>
                        <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                            <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                            </svg> Clear Search
                        </Button>
                    </div>

                    {/*  actions */}
                    {(userRoles.includes("7") || userRoles.includes("11") || userRoles.includes("2")) &&
                        <div className="col-xl-12 mt-4" style={{ textAlign: "end" }}>
                            <CustomTooltip title={selectedCRs.length < 1? "Approve selected pending partners' connectivity requests":"Approve selected pending partners' connectivity requests"} arrow>
                            <span>
                                <Button
                                    className=" blue-outlined-btn"
                                    disabled={selectedCRs.length < 1}
                                    onClick={handleApproveBulkPendingCRs}
                                    >
                                    <svg width="15" height="20" viewBox="0 0 24 24" className="mt--1" >
                                        <use xlinkHref={`${verifyCarrierSVG}#shield-check`}></use>
                                    </svg>
                                    <span className="ml-2">Approve</span>
                                </Button>
                            </span>

                            </CustomTooltip>
                        </div>
                    }



                    <ConnectivityRequestsTable tableRef={tableRef} advancedFiltersClicked={advancedFiltersClicked} quickFiltersClicked={quickFiltersClicked} connectivityRequestsList={connectivityRequestsList} setConnectivityRequestsList={setConnectivityRequestsList} selectedPage={selectedPage} onSelectedPage={onSelectedPage} pageSize={pageSize} onPageSize={onPageSize} selectedPageAdv={selectedPageAdv} onSelectedPageAdv={onSelectedPageAdv} pageSizeAdv={pageSizeAdv} onPageSizeAdv={onPageSizeAdv} totalCount={totalCount} onSortFieldFilter={onSortFieldFilter} onSortOrderFilter={onSortOrderFilter} onSortFieldAdvFilter={onSortFieldAdvFilter} onSortOrderAdvFilter={onSortOrderAdvFilter} selectedCRs={selectedCRs} setSelectedCRs={setSelectedCRs} />
                </div >
            </Paper >

            <ConnectivityRequestsFilterModalBody
                setTotalCount={setTotalCount}
                advancedFiltersClicked={advancedFiltersClicked}
                setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
                onPageSizeAdv={onPageSizeAdv}
                onSelectedPageAdv={onSelectedPageAdv}
                setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                onConnectivityRequestIDAdvFilter={onConnectivityRequestIDAdvFilter}
                onSelectedGroupsFilter={onSelectedGroupsFilter}
                onSelectedPEOsFilter={onSelectedPEOsFilter}
                onSelectedCarriersFilter={onSelectedCarriersFilter}
                onSelectedPartnerFilter={onSelectedPartnerFilter}
                onFeedIDAdvFilter={onFeedIDAdvFilter}
                onIncludePMFAdvFilter={onIncludePMFAdvFilter}
                onSelectedFeedsFilter={onSelectedFeedsFilter}
                onCreatedFromDateAdvFilter={onCreatedFromDateAdvFilter}
                onCreatedToDateAdvFilter={onCreatedToDateAdvFilter}
                onSelectedAssignedToFilter={onSelectedAssignedToFilter}
                onSelectedPhasesFilter={onSelectedPhasesFilter}
                onSelectedStatusesFilter={onSelectedStatusesFilter}
                onCloseClearReset={handleClearSearchReset}
                onMigrationAdvFilter={onMigrationAdvFilter}
                onSelectedFeedDataTypesFilter={onSelectedFeedDataTypesFilter}
                onSelectedFeedStructureTypesFilter={onSelectedFeedStructureTypesFilter}
                onMigrationStartFromDateAdvFilter={onMigrationStartFromDateAdvFilter}
                onMigrationStartToDateAdvFilter={onMigrationStartToDateAdvFilter}
                onPlannedFirstProductionFromDateAdvFilter={onPlannedFirstProductionFromDateAdvFilter}
                onPlannedFirstProductionToDateAdvFilter={onPlannedFirstProductionToDateAdvFilter}
                onSelectedMigrationPhasesAdvFilter={onSelectedMigrationPhasesAdvFilter}
                onSelectedConnectivityRequestTypesFilter={onSelectedConnectivityRequestTypesFilter}
                onSelectedConnectivityRequestSubTypesFilter={onSelectedConnectivityRequestSubTypesFilter}
                onSetResponsiblePartyAdvFilter={onSetResponsiblePartyAdvFilter}
                onSetResponsiblePartyFilter={onSetResponsiblePartyFilter}
                onSetAgingConnectivityAdvFilter={onSetAgingConnectivityAdvFilter}
                onSetAgingConnectivityFilter={onSetAgingConnectivityFilter}
                clientEffectiveDateFromAdvFilter={clientEffectiveDateFromAdvFilter}
                onSetClientEffectiveDateFromAdvFilter={onSetClientEffectiveDateFromAdvFilter}
                clientEffectiveDateToAdvFilter={clientEffectiveDateToAdvFilter}
                onSetClientEffectiveDateToAdvFilter={onSetClientEffectiveDateToAdvFilter}
                onSetIsPEOAdvFilter={onSetIsPEOAdvFilter}
                isIsovedSuperAdminOrSuperAdmin={isIsovedSuperAdminOrSuperAdmin}
                subTypeList={subTypeList}
            />


        </div >
    )
}

export default ConnectivityRequestsListView;
