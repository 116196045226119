import React from 'react'
import * as APIs from "../../../Data/APIs";
import { FormControl, Divider, Paper, Button, Select, MenuItem, TextField } from "@material-ui/core";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import dateTimeSVG from "../../../Images/dateTime.svg";
import DateFnsUtils from '@date-io/date-fns';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import Moment from 'moment';
import { useAuthentication } from "../../../Data/Authentication";
import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import Autocomplete from "@material-ui/lab/Autocomplete";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useLoader } from '../../Layout/Loader';
import TruncatedChip from "../../Layout/TruncatedChip";
import HomeOERequestsTable from './HomeOERequestsTable';
import HomeOERequestsModalBodyFilter from './HomeOERequestsFilterModalBody';
import RefreshIcon from '@material-ui/icons/Refresh';
import { useModalDialog } from "../../Layout/ModalDialogCustomized";
import { styled } from '@mui/system';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { FailureModal } from '../../Modals/FailureModal';
import { SuccessModal } from '../../Modals/SuccessModal';
import refreshSVG from "../../../Icons/refresh.svg";
import cancelIconSVG from "../../../Icons/CancelIcon.svg";
import advancedFilterIconSVG from "../../../Icons/advancedFilter.svg";
import calendarIconSVG from "../../../Icons/fal-calendar-alt.svg";
import oeDataReadyEnabled from "../../../Icons/oeDataReadyEnabled.svg";


const todayDate = new Date();
todayDate.setHours(0, 0, 0, 0);

const monthDate = new Date();
monthDate.setHours(0, 0, 0, 0)
monthDate.setMonth(todayDate.getMonth() - 1);

const sixMonthDate = new Date();
sixMonthDate.setHours(0, 0, 0, 0)
sixMonthDate.setMonth(todayDate.getMonth() - 6);


const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '0 10px',
    color: "var(--grey2-secondary-color)",
    backgroundColor: "var(--grey1)",
    fontSize: "12px",
}));

const GroupItems = styled('ul')({
    padding: 0,
});

function HomeViewOERequestsList({
    oERequestsList,
    setOERequestsList,
    refreshClicked,
    setRefreshClicked,
    quickFiltersClicked,
    setQuickFiltersClicked,
    advancedFiltersClicked,
    setAdvancedFiltersClicked,
    advancedFiltersAppliedClicked,
    setAdvancedFiltersAppliedClicked,
    selectedPlanYearStartFromDateFilter,
    setSelectedPlanYearStartFromDateFilter,
    selectedPlanYearStartToDateFilter,
    setSelectedPlanYearStartToDateFilter,
    selectedExpectedFromDateFilter,
    setSelectedExpectedFromDateFilter,
    selectedExpectedToDateFilter,
    setSelectedExpectedToDateFilter,
    iSolvedContact,
    setISolvedContact,
    iSolvedContactAutoValue,
    setISolvedContactAutoValue,
    updatedGS,
    setUpdatedGS,
    draft,
    setDraft,
    oERequestIDFilter,
    setOERequestIDFilter,
    clientsList,
    setClientsList,
    peosList,
    setPEOsList,
    carriersList,
    setCarriersList,
    clientName,
    setClientName,
    clientNameAutoValue,
    setClientNameAutoValue,
    peoName,
    setPEOName,
    peoNameAutoValue,
    setPEONameAutoValue,
    carName,
    setCarName,
    carNameAutoValue,
    setCarNameAutoValue,
    clientSelectedFilter,
    setClientSelectedFilter,
    peoSelectedFilter,
    setPEOSelectedFilter,
    carrierSelectedFilter,
    setCarrierSelectedFilter,
    selectedOEFileSubmissionFromDateFilter,
    setSelectedOEFileSubmissionFromDateFilter,
    selectedOEFileSubmissionToDateFilter,
    setSelectedOEFileSubmissionToDateFilter,
    requestType,
    setRequestType,
    requestTypes,
    setRequestTypes,
    // adv
    selectedPlanYearStartFromDateAdvFilter,
    setSelectedPlanYearStartFromDateAdvFilter,
    selectedPlanYearStartToDateAdvFilter,
    setSelectedPlanYearStartToDateAdvFilter,
    selectedExpectedFromDateAdvFilter,
    setSelectedExpectedFromDateAdvFilter,
    selectedExpectedToDateAdvFilter,
    setSelectedExpectedToDateAdvFilter,
    iSolvedContactAdvFilter,
    setISolvedContactAdvFilter,
    oERequestIDAdvFilter,
    setOERequestIDAdvFilter,
    sortFieldAdvFilter,
    setSortFieldAdvFilter,
    sortOrderAdvFilter,
    setSortOrderAdvFilter,
    sortOrderFilter,
    setSortOrderFilter,
    sortFieldFilter,
    setSortFieldFilter,
    selectedISolvedContactFilter,
    setSelectedISolvedContactFilter,
    updatedGSAdvFilter,
    setUpdatedGSAdvFilter,
    draftAdvFilter,
    setDraftAdvFilter,
    selectedClientsFilter,
    setSelectedClientsFilter,
    selectedPEOsFilter,
    setSelectedPEOsFilter,
    selectedCarriersFilter,
    setSelectedCarriersFilter,
    selectedOEFileSubmissionFromDateAdvFilter,
    setSelectedOEFileSubmissionFromDateAdvFilter,
    selectedOEFileSubmissionToDateAdvFilter,
    setSelectedOEFileSubmissionToDateAdvFilter,
    selectedPage,
    setSelectedPage,
    pageSize,
    setPageSize,
    totalCount,
    setTotalCount,
    selectedPageAdv,
    setSelectedPageAdv,
    pageSizeAdv,
    setPageSizeAdv,
    usersList,
    setUsersList,
    groupsAndOEContactsList,
    setGroupsAndOEContactsList,
    contactsList,
    setContactsList,
    getOERequestsQuickData,
    getOERequestsAdvancedData,
    requestTypeAdvFilter,
    setRequestTypeAdvFilter,
    ...props
}) {

    // style
    const BootstrapInput = withStyles((theme) => ({

        input: {
            borderRadius: 4,
            padding: '14px 12px'
        },
    }))(InputBase);
    const { authUser } = useAuthentication();
    const dialog = useModalDialog(0);
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const { openLoader, closeLoader } = useLoader();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [selectedOEs, setSelectedOEs] = React.useState([]);
    const [oesClicked, setoesClicked] = React.useState(false);


    React.useEffect(() => {
        setISolvedContactAutoValue(`${iSolvedContact?.FirstName ?? ""} ${iSolvedContact?.LastName ?? ""}`);
    }, [iSolvedContact])

    React.useEffect(() => {
        openLoader();
        if (!userRoles?.includes("6")) {
            APIs.getGroupsListByName({ GroupName: clientName ?? "" }).then((r) => !(r?.data?.length) ? setClientsList([]) : setClientsList(r?.data));
            APIs.getPEOsListByName({ PeoName: peoName ?? "" }).then((r) => !(r?.data?.length) ? setPEOsList([]) : setPEOsList(r?.data));
        }
        APIs.getCarriersListByName({ CarrierName: carName ?? "" }).then((r) => !(r?.data?.length) ? setCarriersList([]) : setCarriersList(r?.data));
    }, [])

    React.useEffect(() => {
        setClientNameAutoValue(clientName);
    }, [clientName])

    React.useEffect(() => {
        setPEONameAutoValue(peoName);
    }, [peoName])

    React.useEffect(() => {
        setCarNameAutoValue(carName);
    }, [carName])

    //    handle functions 

    const handleOERequestIDFilter = (e) => {
        setOERequestIDFilter(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleOEFileSubmissionDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedOEFileSubmissionFromDateFilter(date);
        }
        else {
            setSelectedOEFileSubmissionToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };
    const handleExpectedDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedExpectedFromDateFilter(date);
        }
        else {
            setSelectedExpectedToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handlePlanYearStartDateChange = (controlName) => (date) => {
        if (date)
            date.setHours(0, 0, 0, 0);
        if (controlName == "fromDate") {
            setSelectedPlanYearStartFromDateFilter(date);
        }
        else {
            setSelectedPlanYearStartToDateFilter(date);
        }
        setSelectedPage(1);
        setPageSize(30);
    };

    const handleUpdatedGSFilter = (e, newValue) => {
        setUpdatedGS(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleRequestTypeFilter = (e, newValue) => {
        setRequestType(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleDraftFilter = (e, newValue) => {
        setDraft(e.target.value);
        setSelectedPage(1);
        setPageSize(30);
    }

    const handleClearSearch = () => {
        setOERequestIDFilter("");
        setUpdatedGS("");
        setDraft("");
        setISolvedContact("");
        setRequestType("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedPage(1);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
    }
    const handleClearSearchReset = () => {
        setUpdatedGS("");
        setUpdatedGSAdvFilter("");
        setDraft("");
        setDraftAdvFilter("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setISolvedContact("");
        setOERequestIDFilter("");
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedOEFileSubmissionFromDateAdvFilter(null);
        setSelectedOEFileSubmissionToDateAdvFilter(null);
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setISolvedContactAdvFilter("");
        setSelectedPlanYearStartFromDateAdvFilter(null);
        setSelectedPlanYearStartToDateAdvFilter(null);
        setSelectedExpectedFromDateAdvFilter(null);
        setSelectedExpectedToDateAdvFilter(null);
        setOERequestIDAdvFilter("");
        setSelectedPage(1);
        setSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setSelectedISolvedContactFilter([]);
        setAdvancedFiltersClicked(false);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
    }
    const handleClearDashBordReset = () => {
        setUpdatedGS("");
        setDraft("");
        setOERequestIDFilter("");
        setOERequestIDAdvFilter("");
        setUpdatedGSAdvFilter("");
        setDraftAdvFilter("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setISolvedContact("");
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setSelectedOEFileSubmissionFromDateAdvFilter(null);
        setSelectedOEFileSubmissionToDateAdvFilter(null);
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setISolvedContactAdvFilter("");
        setSelectedExpectedToDateAdvFilter(null);
        setSelectedPlanYearStartToDateAdvFilter(null);
        setSelectedExpectedFromDateAdvFilter(null);
        setSelectedExpectedToDateAdvFilter(null);
        setSelectedPage(1);
        setSelectedPageAdv(1);
        setQuickFiltersClicked(true);
        setAdvancedFiltersAppliedClicked(false);
        setSelectedISolvedContactFilter([]);
        setAdvancedFiltersClicked(false);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
    }
    const handleClickOpenDialog = () => {
        setOERequestIDFilter("");
        setUpdatedGS("");
        setDraft("");
        setISolvedContact("");
        setRequestType("");
        setRequestTypeAdvFilter("");
        setSelectedPlanYearStartFromDateFilter(null);
        setSelectedPlanYearStartToDateFilter(null);
        setSelectedExpectedFromDateFilter(null);
        setSelectedExpectedToDateFilter(null);
        setSelectedOEFileSubmissionFromDateFilter(null);
        setSelectedOEFileSubmissionToDateFilter(null);
        setOpenDialog(true);
        setAdvancedFiltersClicked(true);
        setQuickFiltersClicked(false);
        setCarName("");
        setCarrierSelectedFilter("");
        setClientName("");
        setClientSelectedFilter("");
        setPEOName("");
        setPEOSelectedFilter("");
    };

    const handleDataIsReady = () => {
        openLoader();
        let transmitOes = {

            OERequestIDs: selectedOEs?.map(oe => (

                oe.Id

            ))
        }

        APIs.sendOEFile(transmitOes).then((r) => {
            openLoader();
            if (r.success === "1") {
                closeLoader();
                window.location.reload()
            }

            else {
                closeLoader();
                setSelectedOEs([]);
                FailureModal(dialog, {
                    title: 'Failure',
                    body: r.msg,
                }
                )
            }
        })
    };

    const isIsovedSuperAdminOrSuperAdmin = () => {
        //attached action ID to isolvedSuperAdmin
        return userActions?.includes("57") || userActions?.includes("20");
    }



    return (
        <div id="view-action-items">
            {/* client can't see filters */}

            <Paper className="content-card-paper search-container">
                {!userRoles?.includes("6") && (
                    <>
                        <div className="row alignvh-center">
                            <h6 className="text-nowrap fw-500 mr-3">Filter by:</h6>
                            <Button
                                className={!advancedFiltersClicked ? "mr-3 MuiButton-btnWithText" : "blue-btn"}
                                onClick={handleClickOpenDialog}
                                style={!advancedFiltersClicked ? { marginBottom: "10px" } : { border: "1px solid var(--blue-main-color)", marginBottom: "10px" }}
                            >
                                <svg width="18" height="18" viewBox="0 0 18 18" className="mt--4 mr-1">
                                    <use xlinkHref={`${advancedFilterIconSVG}#advancedFilterIcon`}></use>
                                </svg>
                                Advanced Filters
                            </Button>
                            <React.Fragment>
                                {oERequestIDFilter && <TruncatedChip body={`OE Request ID: ${oERequestIDFilter}`} />}
                                {oERequestIDAdvFilter && <TruncatedChip body={`OE Request ID: ${oERequestIDAdvFilter}`} />}

                                {clientSelectedFilter && <TruncatedChip body={`Client: ${clientSelectedFilter?.GroupName}`} />}
                                {selectedClientsFilter && selectedClientsFilter?.length > 0 && <TruncatedChip body={`Clients: ${selectedClientsFilter?.map(s => s.GroupName).join(" - ")}`} />}

                                {peoSelectedFilter && <TruncatedChip body={`PEO: ${peoSelectedFilter?.PeoName}`} />}
                                {selectedPEOsFilter && selectedPEOsFilter?.length > 0 && <TruncatedChip body={`PEOs: ${selectedPEOsFilter?.map(s => s.PeoName).join(" - ")}`} />}

                                {carrierSelectedFilter && <TruncatedChip body={`Carrier: ${carrierSelectedFilter?.CarrierName}`} />}
                                {selectedCarriersFilter && selectedCarriersFilter?.length > 0 && <TruncatedChip body={`Carriers: ${selectedCarriersFilter?.map(s => s.CarrierName).join(" - ")}`} />}

                                {iSolvedContact && <TruncatedChip body={`isolved Contact: ${iSolvedContact.FirstName} ${iSolvedContact.LastName}`} />}
                                {iSolvedContactAdvFilter && <TruncatedChip body={`isolved Contact: ${iSolvedContactAdvFilter.FirstName} ${iSolvedContactAdvFilter.LastName}`} />}
                                {selectedISolvedContactFilter && selectedISolvedContactFilter?.length > 0 && <TruncatedChip body={`isolved Contact: ${selectedISolvedContactFilter?.map(s => `${s.FirstName} ${s.LastName}`).join(" - ")}`} />}

                                {updatedGS && updatedGS !== "" && <TruncatedChip body={`Updated Group Structure: ${updatedGS === "1" ? "Yes" : updatedGS === "0" ? "No" : ""}`} />}
                                {updatedGSAdvFilter && updatedGSAdvFilter !== "" && <TruncatedChip body={`Updated Group Structure: ${updatedGSAdvFilter === "1" ? "Yes" : updatedGSAdvFilter === "0" ? "No" : ""}`} />}

                                {draft && draft !== "" && <TruncatedChip body={`Draft: ${draft === "1" ? "Yes" : draft === "0" ? "No" : ""}`} />}
                                {draftAdvFilter && draftAdvFilter !== "" && <TruncatedChip body={`Draft: ${draftAdvFilter === "1" ? "Yes" : draftAdvFilter === "0" ? "No" : ""}`} />}

                                {requestType && requestType !== "" && <TruncatedChip body={`Request Type: ${requestTypes?.filter(s => s.RequestTypeId == requestType)[0]?.RequestTypeName}`} />}
                                {requestTypeAdvFilter && requestTypeAdvFilter !== "" && <TruncatedChip body={`Request Type: ${requestTypes?.filter(s => s.RequestTypeId == requestTypeAdvFilter)[0]?.RequestTypeName}`} />}

                                {quickFiltersClicked && selectedExpectedFromDateFilter && selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedExpectedToDateFilter).format("MM/DD/YYYY")}`} />}
                                {quickFiltersClicked && selectedExpectedFromDateFilter && !selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateFilter).format("MM/DD/YYYY")} `} />}
                                {quickFiltersClicked && !selectedExpectedFromDateFilter && selectedExpectedToDateFilter && <TruncatedChip body={`Client's Expected Data Ready Date: To: ${Moment(selectedExpectedToDateFilter).format("MM/DD/YYYY")}`} />}

                                {quickFiltersClicked && selectedPlanYearStartFromDateFilter && selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Year Start Date: From: ${Moment(selectedPlanYearStartFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedPlanYearStartToDateFilter).format("MM/DD/YYYY")}`} />}
                                {quickFiltersClicked && selectedPlanYearStartFromDateFilter && !selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Year Start Date: From: ${Moment(selectedPlanYearStartFromDateFilter).format("MM/DD/YYYY")} `} />}
                                {quickFiltersClicked && !selectedPlanYearStartFromDateFilter && selectedPlanYearStartToDateFilter && <TruncatedChip body={`Plan Year Start Date: To: ${Moment(selectedPlanYearStartToDateFilter).format("MM/DD/YYYY")}`} />}


                                {advancedFiltersClicked && selectedPlanYearStartFromDateAdvFilter && selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Year Start Date: From: ${Moment(selectedPlanYearStartFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedPlanYearStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                                {advancedFiltersClicked && selectedPlanYearStartFromDateAdvFilter && !selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Year Start Date: From: ${Moment(selectedPlanYearStartFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                                {advancedFiltersClicked && !selectedPlanYearStartFromDateAdvFilter && selectedPlanYearStartToDateAdvFilter && <TruncatedChip body={`Plan Year Start Date: To: ${Moment(selectedPlanYearStartToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                                {advancedFiltersClicked && selectedExpectedFromDateAdvFilter && selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedExpectedToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                                {advancedFiltersClicked && selectedExpectedFromDateAdvFilter && !selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: From: ${Moment(selectedExpectedFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                                {advancedFiltersClicked && !selectedExpectedFromDateAdvFilter && selectedExpectedToDateAdvFilter && <TruncatedChip body={`Client's Expected Data Ready Date: To: ${Moment(selectedExpectedToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                                {quickFiltersClicked && selectedOEFileSubmissionFromDateFilter && selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateFilter).format("MM/DD/YYYY")} To: ${Moment(selectedOEFileSubmissionToDateFilter).format("MM/DD/YYYY")}`} />}
                                {quickFiltersClicked && selectedOEFileSubmissionFromDateFilter && !selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateFilter).format("MM/DD/YYYY")} `} />}
                                {quickFiltersClicked && !selectedOEFileSubmissionFromDateFilter && selectedOEFileSubmissionToDateFilter && <TruncatedChip body={`OE File Transmission Date: To: ${Moment(selectedOEFileSubmissionToDateFilter).format("MM/DD/YYYY")}`} />}

                                {advancedFiltersClicked && selectedOEFileSubmissionFromDateAdvFilter && selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateAdvFilter).format("MM/DD/YYYY")} To: ${Moment(selectedOEFileSubmissionToDateAdvFilter).format("MM/DD/YYYY")}`} />}
                                {advancedFiltersClicked && selectedOEFileSubmissionFromDateAdvFilter && !selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: From: ${Moment(selectedOEFileSubmissionFromDateAdvFilter).format("MM/DD/YYYY")} `} />}
                                {advancedFiltersClicked && !selectedOEFileSubmissionFromDateAdvFilter && selectedOEFileSubmissionToDateAdvFilter && <TruncatedChip body={`OE File Transmission Date: To: ${Moment(selectedOEFileSubmissionToDateAdvFilter).format("MM/DD/YYYY")}`} />}

                            </React.Fragment>
                        </div>
                        {quickFiltersClicked && (
                            <div className="row">
                                <div className="col-xl-3 mt-3">
                                    <span>OE Request ID:</span>
                                    <br />
                                    <input type="text" className="search-input w-100" value={oERequestIDFilter} onChange={handleOERequestIDFilter} />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Client Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={clientsList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={clientNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.GroupName}
                                        onChange={(e, value) => {
                                            setClientName(value?.GroupName ?? "");
                                            setClientSelectedFilter(value ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.GroupName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setClientNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>PEO Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={peosList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={peoNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.PeoName}
                                        onChange={(e, value) => {
                                            setPEOName(value?.PeoName ?? "");
                                            setPEOSelectedFilter(value ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.PeoName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setPEONameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Carrier Name:</span>
                                    <br />
                                    <Autocomplete
                                        options={carriersList}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={carNameAutoValue ?? ""}
                                        getOptionLabel={(option) => option?.CarrierName}
                                        onChange={(e, value) => {
                                            setCarName(value?.CarrierName ?? "");
                                            setCarrierSelectedFilter(value ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {option?.CarrierName}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setCarNameAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'off', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Updated Group Structure:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={updatedGS ?? ""}
                                            onChange={handleUpdatedGSFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>isolved Contact:</span>
                                    <br />
                                    <Autocomplete
                                        options={groupsAndOEContactsList?.sort((s1, s2) => {
                                            return s2.IsEnabled - s1.IsEnabled
                                        })}
                                        groupBy={(option) => option.IsEnabled}
                                        renderGroup={(params) => (
                                            <li key={params.key}>
                                                <GroupHeader>{params.group === 1 ? "Enabled" : "Disabled"}</GroupHeader>
                                                <GroupItems>{params.children}</GroupItems>
                                            </li>
                                        )}
                                        autoHighlight
                                        className="search-dropdown w-100"
                                        inputValue={iSolvedContactAutoValue ?? ""}
                                        getOptionLabel={(option) => `${option.FirstName} ${option.LastName}`}
                                        onChange={(e, newValue) => {
                                            setISolvedContact(newValue ?? "");
                                            setSelectedPage(1);
                                            setPageSize(30);
                                        }}
                                        getOptionSelected={(option, value) => `${option.FirstName} ${option.LastName}` === `${value.FirstName} ${value.LastName}`}
                                        renderOption={(option) => (
                                            <React.Fragment>
                                                {`${option.FirstName} ${option.LastName}`}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                onChange={e => setISolvedContactAutoValue(e.target.value)}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Draft:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={draft ?? ""}
                                            onChange={handleDraftFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </NativeSelect>
                                    </FormControl>
                                </div>
                                {/* <div className="col-xl-3 mt-3"></div> */}

                                <div className="col-xl-3 mt-3">
                                    <span>Request Type:</span>
                                    <br />
                                    <FormControl className="search-dropdown w-100">
                                        <NativeSelect
                                            value={requestType ?? ""}
                                            onChange={handleRequestTypeFilter}
                                            input={<BootstrapInput />}
                                        >
                                            <option aria-label="None" value="" />
                                            {requestTypes?.map((p, pi) => (
                                            <option key={`oe-request-types-list-${pi}`} value={p.RequestTypeId}>{p.RequestTypeName}</option>
                                        ))}
                                        </NativeSelect>
                                    </FormControl>
                                </div>

                                <div className="col-xl-3 mt-3">
                                <span>Plan Year Start Date:</span>
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedPlanYearStartFromDateFilter}
                                            onChange={handlePlanYearStartDateChange("fromDate")}
                                            placeholder="From Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className="col-xl-3 mt-3">
                                <br />
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <div className="date-div alignvh-center w-100">
                                        <KeyboardDatePicker
                                            autoOk={true}
                                            className="ml-xl-1 col-12"
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            id="date-picker-inline"
                                            value={selectedPlanYearStartToDateFilter}
                                            onChange={handlePlanYearStartDateChange("toDate")}
                                            placeholder="To Date"
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                            keyboardIcon={
                                                <svg width="14" height="16" viewBox="0 0 14 16">
                                                    <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                </svg>
                                            }
                                        />
                                    </div>
                                </MuiPickersUtilsProvider>
                            </div>
                                <div className="col-xl-3 mt-3">
                                    <span>Client's Expected Data Ready Date:</span>
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100">
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={selectedExpectedFromDateFilter}
                                                onChange={handleExpectedDateChange("fromDate")}
                                                placeholder='From Date'
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16" >
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100" >
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={selectedExpectedToDateFilter}
                                                onChange={handleExpectedDateChange("toDate")}
                                                placeholder='To Date'
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16" >
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <span>OE File Transmission Date:</span>
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100" >
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={selectedOEFileSubmissionFromDateFilter}
                                                onChange={handleOEFileSubmissionDateChange("fromDate")}
                                                placeholder='From Date'
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16" >
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className="col-xl-3 mt-3">
                                    <br />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <div className="date-div alignvh-center w-100" >
                                            <KeyboardDatePicker
                                                autoOk={true}
                                                className="ml-xl-1 col-12"
                                                disableToolbar
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                id="date-picker-inline"
                                                value={selectedOEFileSubmissionToDateFilter}
                                                onChange={handleOEFileSubmissionDateChange("toDate")}
                                                placeholder='To Date'
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                                keyboardIcon={
                                                    <svg width="14" height="16" viewBox="0 0 14 16" >
                                                        <use xlinkHref={`${calendarIconSVG}#calendarIcon`}></use>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </MuiPickersUtilsProvider>
                                </div>
                            </div>
                        )}
                        <div className="col-xl-12 mt-4">
                            <Button className="blue-outlined-btn" onClick={() => setRefreshClicked(true)}>
                                <svg width="22" height="23" viewBox="0 0 22 23" className="mt--2">
                                    <use xlinkHref={`${refreshSVG}#refreshIcon`}></use>
                                </svg>
                                <span className="ml-1">Refresh List</span>
                            </Button>
                            <Button className="blue-outlined-btn ml-2" onClick={handleClearSearchReset}>
                                <svg width="14" height="13" viewBox="0 0 14 13" className="mt--2 mr-2" >
                                    <use xlinkHref={`${cancelIconSVG}#cancelIcon`}></use>
                                </svg> Clear Search
                            </Button>
                        </div>
                    </>
                )}

                {isIsovedSuperAdminOrSuperAdmin() &&
                    <div className="col-xl-12 mt-4" style={{ textAlign: "end" }}>
                        <Button className=" blue-outlined-btn" disabled={selectedOEs.length < 1}
                            onClick={(e) => {
                                setoesClicked(true)
                                handleDataIsReady()

                            }}
                        >
                            <svg width="16" height="13" viewBox="0 0 16 13" >
                                <use xlinkHref={`${oeDataReadyEnabled}#oeDataReadyEnabled`}></use>
                            </svg>
                            <span className="ml-2">Data is ready</span>
                        </Button>
                    </div>
                }

                <HomeOERequestsTable
                    advancedFiltersClicked={advancedFiltersClicked}
                    quickFiltersClicked={quickFiltersClicked}
                    oERequestsList={oERequestsList}
                    setOERequestsList={setOERequestsList}
                    selectedPage={selectedPage}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageSize={setPageSize}
                    setSelectedPage={setSelectedPage}
                    onSortOrderFilter={(s) => setSortOrderFilter(s)}
                    onSortFieldFilter={(s) => setSortFieldFilter(s)}
                    selectedPageAdv={selectedPageAdv}
                    onSetSelectedPageAdv={setSelectedPageAdv}
                    pageSizeAdv={pageSizeAdv}
                    onSetPageSizeAdv={setPageSizeAdv}
                    onSortFieldAdvFilter={setSortFieldAdvFilter}
                    onSortOrderAdvFilter={setSortOrderAdvFilter}
                    getOERequestsQuickData={getOERequestsQuickData}
                    getOERequestsAdvancedData={getOERequestsAdvancedData}
                    groupsAndOEContactsList={groupsAndOEContactsList}
                    selectedOEs={selectedOEs}
                    setSelectedOEs={(s) => setSelectedOEs(s)}
                />
            </Paper>


            {!userRoles?.includes("6") &&
                <HomeOERequestsModalBodyFilter
                    setTotalCount={setTotalCount}
                    advancedFiltersClicked={advancedFiltersClicked}
                    setAdvancedFiltersClicked={setAdvancedFiltersClicked}
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    onSetPageSizeAdv={(s) => setPageSizeAdv(s)}
                    onSetSelectedPageAdv={setSelectedPageAdv}
                    setAdvancedFiltersAppliedClicked={setAdvancedFiltersAppliedClicked}
                    setOERequestIDFilterAdvFilter={setOERequestIDAdvFilter}
                    setSelectedPlanYearStartFromDateFilterAdvFilter={setSelectedPlanYearStartFromDateAdvFilter}
                    setSelectedPlanYearStartToDateFilterAdvFilter={setSelectedPlanYearStartToDateAdvFilter}
                    setSelectedExpectedFromDateFilterAdvFilter={setSelectedExpectedFromDateAdvFilter}
                    setSelectedExpectedToDateFilterAdvFilter={setSelectedExpectedToDateAdvFilter}
                    setUpdatedGSAdvFilter={setUpdatedGSAdvFilter}
                    setDraftAdvFilter={setDraftAdvFilter}
                    setISolvedContactAdvFilter={setISolvedContactAdvFilter}
                    onSelectedISolvedContactFilter={setSelectedISolvedContactFilter}
                    selectedISolvedContactFilter={selectedISolvedContactFilter}
                    onCloseClearReset={handleClearSearchReset}
                    onSelectedClientsFilter={(s) => setSelectedClientsFilter(s)}
                    selectedClientsFilter={selectedClientsFilter}
                    onSelectedPEOsFilter={(s) => setSelectedPEOsFilter(s)}
                    selectedPEOsFilter={selectedPEOsFilter}
                    onSelectedCarriersFilter={(s) => setSelectedCarriersFilter(s)}
                    selectedCarriersFilter={selectedCarriersFilter}
                    setSelectedOEFileSubmissionFromDateFilterAdvFilter={setSelectedOEFileSubmissionFromDateAdvFilter}
                    setSelectedOEFileSubmissionToDateFilterAdvFilter={setSelectedOEFileSubmissionToDateAdvFilter}
                    setRequestTypeAdvFilter={setRequestTypeAdvFilter}
        
                />
            }
        </div>
    )
}

export default HomeViewOERequestsList
