import { Link } from "@material-ui/core";
import React from "react";
import ContactsRow from "./ContactsRow";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useAuthentication } from "../../../Data/Authentication";

function ConnectivityRow({
    connectivityKey,
    connectivity,
    addConnectivityClicked,
    groupReq,
    setGroupReq,
    setSelectedSubgroupsView,
    setAddConnectivityClicked,
    setConnectivitiesList,
    carrierContactTypes,
    ...props }) {

    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");

    const [contacts, setContacts] = React.useState([]);
    const [status, setStatus] = React.useState("");

    React.useEffect(() => {
        if (!connectivity) return;
        setContacts(connectivity.ConnectivityRequestContacts);
    }, [connectivity]);

    React.useEffect(() => {
        if (!connectivity) return;
        else if (connectivity.ConnectivityRequestStatusName === "In Progress") setStatus("inProgress");
        else if (connectivity.ConnectivityRequestStatusName === "On Hold") setStatus("onHold");
        else if (connectivity.ConnectivityRequestStatusName === "Blocked") setStatus("blocked");
        else if (connectivity.ConnectivityRequestStatusName === "Cancelled") setStatus("cancelled");
        else if (connectivity.ConnectivityRequestStatusName === "Not Started") setStatus("notStarted");
        else if (connectivity.ConnectivityRequestStatusName === "Production") setStatus("production");
        else if (connectivity.ConnectivityRequestStatusName === "Production Disabled") setStatus("productionDisabled");
        else if (connectivity.ConnectivityRequestStatusName === "Pending") setStatus("pendingg");
        else return;
    }, [connectivity.ConnectivityRequestStatusName])

    return (
        <div className="row table-row">
            <div className="col table-cell">
                <a style={{ cursor: "pointer" }} href={`/ConnectivityRequests/ViewConnectivityRequest/${connectivity?.ConnectivityRequestID}`}>
                    {connectivity.ConnectivityRequestID}
                </a>
            </div>
            <div className="col table-cell">
                <a style={{ cursor: "pointer" }} href={`/ConnectivityRequests/ViewConnectivityRequest/${connectivity?.ConnectivityRequestID}`}>
                    {connectivity.IsolvedCarrierName === "Third Party" ? `${connectivity.IsolvedCarrierName} (${connectivity.ThirdPartyName})` : connectivity.IsolvedCarrierName}
                </a>
            </div>
            <div className="col table-cell">
                {connectivity.IsolvedCarrierCode}
            </div>
            <div className="col table-cell">
                {connectivity.ConnectivityRequestType}
            </div>
            <div className="col table-cell">
                {connectivity.PlanTypes ?
                    connectivity.PlanTypes?.map((p) => (p.PlanTypeName)).join(" , ") :
                    <CustomTooltip TransitionComponent={Zoom} title="You can add plans from the Connectivity Request page">
                        <Link href={`/ConnectivityRequests/ViewConnectivityRequest/${connectivity?.ConnectivityRequestID}`} className="missing-data-warning">No plans added</Link>
                    </CustomTooltip>
                }
            </div>
            <div className="col table-cell">
                <div className={`status-div ${status}`}>
                    {connectivity.ConnectivityRequestStatusName}
                </div>
            </div>

            <div className="col-7 px-0">
                <div className="table-row row h-100">

                    {connectivity?.ConnectivityRequestContacts?.length > 0 ? connectivity?.ConnectivityRequestContacts?.map((o, oi) => (
                        <ContactsRow
                            key={`contact-row-${oi}`}
                            contactKey={oi}
                            contactItem={o}
                            connectivity={connectivity}
                            contacts={contacts}
                            setContacts={setContacts}
                            groupReq={groupReq}
                            setGroupReq={setGroupReq}
                            setSelectedSubgroupsView={setSelectedSubgroupsView}
                            carrierContactTypes={carrierContactTypes}
                        />
                    )) :
                        <div className="row col-12 table-cell">
                            {(userActions?.includes("23") ||
                                userActions?.includes("20")) ? (
                                <CustomTooltip TransitionComponent={Zoom} title="You can add contacts from the Connectivity Request page">
                                    <Link href={`/ConnectivityRequests/ViewConnectivityRequest/${connectivity?.ConnectivityRequestID}`} className="missing-data-warning">No contacts added</Link>
                                </CustomTooltip>
                            ) : (
                                <div className="missing-data-warning">No contacts added</div>
                            )}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ConnectivityRow;