import React from "react";
import Moment from 'moment';
import Pagination from "../../Layout/Pagination";
import { CustomTooltip } from "../../Layout/Tooltip";
import Zoom from '@material-ui/core/Zoom';
import { useAuthentication } from "../../../Data/Authentication";
import AddBoxIcon from '@mui/icons-material/AddBox';
import GenericTable from "../../Layout/GenericTable";
import addOESVG from "../../../Icons/addActionItem.svg";




function GroupRequestsTable({ tableRef, advancedFiltersClicked, quickFiltersClicked, groupRequestsList, setGroupRequestsList, selectedPage, onSelectedPage, pageSize, onPageSize, selectedPageAdv, onSelectedPageAdv, pageSizeAdv, onPageSizeAdv, totalCount, onSortFieldFilter, onSortOrderFilter, onSortFieldAdvFilter, onSortOrderAdvFilter, ...props }) {
    const { authUser } = useAuthentication();
    const userActions = authUser?.signInUserSession?.idToken?.payload["userActions"]?.slice(0, -1).split(",");
    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");
    const [sortOrder, setSortOrder] = React.useState("ASC");


    const handleSort = (column, sortDirection, type) => {
        setSortOrder(sortDirection?.toUpperCase());

        if (type === "quick") {
            onSortFieldFilter(column?.sortingkey);
            onSortOrderFilter(sortDirection?.toUpperCase());
        } else if (type === 'adv') {
            onSortFieldAdvFilter(column?.sortingkey);
            onSortOrderAdvFilter(sortDirection?.toUpperCase());
        }
    };

    const baseColumnsArray = [
        // {
        //     name: 'Group Request ID',
        //     sortable: true,
        //     sortingkey: 'GroupID',
        //     id: 'GroupID',
        //     selector: row => row?.GroupID,
        // },
        {
            name: 'Client Number',
            width: '120px',
            sortable: true,
            sortingkey: 'GroupNumber',
            selector: row => <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${row?.GroupRequestID}`}>{row?.IsPEO == 1 ? "PEO" : row?.GroupNumber}</a>,
        },
        {
            name: 'Client Name',
            sortable: true,
            sortingkey: 'GroupName',
            selector: g => <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${g?.GroupRequestID}`}>{g?.PeoName ? g?.PeoName : g?.GroupName}</a>
            ,
        },
        {
            name: 'PEO',
            sortable: true,
            sortingkey: 'IsPEO',
            width: '80px',
            selector: row => row?.IsPEO == 1 ? "Yes" : "No"
        },
        {
            name: 'Submission Date',
            sortable: true,
            width: '120px',
            sortingkey: 'SubmissionDate',
            selector: row => row?.SubmissionDate ? Moment(row?.SubmissionDate).format('MM/DD/YYYY') : null
        },
        {
            name: 'Status',
            sortable: true,
            sortingkey: 'GroupRequestStatusID',
            selector: row => (<div className={`status-div ${row?.GroupRequestStatusName === "Active" ? `active` : row?.GroupRequestStatusName === "Cancelled" ? `cancelled` : row?.GroupRequestStatusName === "Change Requested" ? `changeRequested` : `draft`}`}>
                {row?.GroupRequestStatusName}
            </div>),
        },
        {
            name: 'phase',
            sortable: true,
            sortingkey: 'GroupRequestPhaseID',
            selector: row => (<div className={`phase-div ${row?.GroupRequestPhaseName === "Not Started" ? `notStarted` : row?.GroupRequestPhaseName === "Cancelled" ? `cancelled` : row?.GroupRequestPhaseName === "Production" ? `production` : row?.GroupRequestPhaseName === "On Hold" ? `onHold` : `inProgress`}`}>
                {row?.GroupRequestPhaseName}
            </div>)
        },
        {
            name: 'Migration',
            sortable: true,
            sortingkey: 'MigrationGroupRequest',
            selector: row => row?.MigrationGroupRequest
        },
    ];

    const pushToColumnsBasedOnUserAction = () => {
        let checkif43or20 = (userActions?.includes("43") || userActions?.includes("20"))

        const columnsToUnshift = [
            {
                name: checkif43or20 ? 'Group Request ID' : 'ID',
                width: '80px',
                sortable: true,
                sortingkey: checkif43or20 ? 'GroupRequestID' : 'GroupID',
                id: checkif43or20 ? 'GroupRequestID' : 'GroupID',
                selector: g =>
                    checkif43or20 ?
                        <a className="link-style" href={`/GroupRequests/ViewGroupRequest/${g?.GroupRequestID}`}>{g?.GroupRequestID}</a>
                        : (g?.PeoID ? g?.PeoID : g?.GroupID)
            },
        ]

        const addOEColunm = {
            name: 'Add OE Request',
            sortable: false,
            width: '110px',
            id: 'addOE',
            selector: row =>
                <a href={row?.GroupRequestStatusName === "Cancelled" ? undefined : `/OERequests/CreateOERequests/${row?.GroupRequestID}`} >
                    <CustomTooltip TransitionComponent={Zoom} title={row?.GroupRequestStatusName === "Cancelled" ? "No OE Requests to be submitted for a Cancelled Feed" : "Add OE Request"}>
                        <svg width="20" height="20" viewBox="0 0 20 20" >
                            <use xlinkHref={`${addOESVG}#addAIIcon`}></use>
                        </svg>
                    </CustomTooltip>
                </a>
        }

        // Create a copy of the base columns array
        let finalColumnsArray = [...baseColumnsArray];
        finalColumnsArray.unshift(...columnsToUnshift)
        if (!userRoles?.includes("1") || userRoles?.includes("7")) {
            finalColumnsArray.push(addOEColunm);
        }

        return finalColumnsArray;
    };


    const FilteredMainData = () => {
        if (!Array.isArray(groupRequestsList)) {
            return [];
        }

        return groupRequestsList
    }
    return (
        <div id="connectivity-group-requests-table" className="mt-5" ref={tableRef}>
            <GenericTable
                columns={pushToColumnsBasedOnUserAction()}
                data={FilteredMainData()}
                onSort={(column, sortDirection) => column?.sortable ? handleSort(column, sortDirection, quickFiltersClicked ? 'quick' : 'adv') : () => { }}
            />
            <div>
                <Pagination
                    stateLimit={true}
                    pageSize={quickFiltersClicked ? pageSize : advancedFiltersClicked ? pageSizeAdv : ""}
                    page={quickFiltersClicked ? selectedPage : advancedFiltersClicked ? selectedPageAdv : ""}
                    onChange={(t, v) => {
                        if (quickFiltersClicked) {
                            if (t === 1) onPageSize(v);
                            else if (t === 2) onSelectedPage(v);
                        } else if (advancedFiltersClicked) {
                            if (t === 1) onPageSizeAdv(v);
                            else if (t === 2) onSelectedPageAdv(v);
                        }
                    }}
                    count={totalCount}
                />
            </div>
        </div >
    );
}

export default GroupRequestsTable;
