import React, { useEffect, useState } from 'react'
import CarrierTabsCarrierContactTableRow from './CarrierTabsCarrierContactTableRow'
import { Fragment } from 'react';
import * as APIs from '../../../../../Data/APIs'
import { useLoader } from '../../../../Layout/Loader';
import { CustomTooltip } from '../../../../Layout/Tooltip';
import { Zoom } from '@material-ui/core';

const CarrierTabsCarrierContactTable = ({ carrierContacts, ...props }) => {
    const { openLoader, closeLoader } = useLoader()

    const [categories, setCategories] = useState([])
    const [types, setTypes] = useState([])

    useEffect(() => {
        openLoader()
        Promise.all([
            APIs.getCarrierContactCategories(),
            APIs.getCarrierContactTypes()
        ])
            .then(([categoriesResponse, typesResponse]) => {
                setCategories(categoriesResponse?.data);
                setTypes(typesResponse?.data);
            })
            .catch((error) => {
                console.log("Error fetching data:", error);
            }).finally(() => closeLoader())
    }, [])


    return (
        <div>
            <div className="row table-header">
                <div className="col-1 table-cell first-cell">
                    First Name
                </div>
                <div className="col-1 table-cell">
                    Last Name
                </div>
                <div className="col-2 table-cell">
                    Email
                </div>
                <div className="col-2 table-cell">
                    Phone Number
                </div>
                <div className="col-2 table-cell">
                    <CustomTooltip TransitionComponent={Zoom}
                        title='The Carrier Contact Category is the step that the carrier contact will help resolve'>
                        <span>
                            Category
                        </span>
                    </CustomTooltip>
                </div>
                <div className="col-2 table-cell">
                    Type
                </div>
                <div className="col-2 table-cell">
                    <CustomTooltip TransitionComponent={Zoom}
                        title='When checked, this carrier contact will display on the Connectivity Requests for the carrier'>
                        <span>
                            Display Carrier Contacts on CRs
                        </span>
                    </CustomTooltip>
                </div>
                {/* <div className="col table-cell">
                    Actions
                </div> */}
            </div>

            {carrierContacts?.length > 0 ?
                carrierContacts?.map((cc, cci) =>
                    <Fragment key={cci}>
                        <CarrierTabsCarrierContactTableRow carrierContact={cc} categories={categories} types={types} />
                    </Fragment>) :
                <div className="no-data-alert">
                    <h6>No Carrier Contacts Added!</h6>
                </div>
            }
        </div>
    )
}

export default CarrierTabsCarrierContactTable
