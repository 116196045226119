import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import homeLight from "../../IconsSidebar/home-light.svg";
import homeSolid from "../../IconsSidebar/home-solid.svg";
import layerGroupLight from "../../IconsSidebar/layer-group-light.svg";
import layerGroupSolid from "../../IconsSidebar/layer-group-solid.svg";
import arrowDownUpAcrossLineLight from "../../IconsSidebar/arrow-down-up-across-line-light.svg";
import arrowDownUpAcrossLineSolid from "../../IconsSidebar/arrow-down-up-across-line-solid.svg";
import filesLight from "../../IconsSidebar/files-light.svg";
import filesSolid from "../../IconsSidebar/files-solid.svg";
import feedLight from "../../IconsSidebar/feed-light.svg";
import feedSolid from "../../IconsSidebar/feed-solid.svg";
import layerPlusLight from "../../IconsSidebar/layer-plus-light.svg";
import layerPlusSolid from "../../IconsSidebar/layer-plus-solid.svg";
import listDropdownLight from "../../IconsSidebar/list-dropdown-light.svg";
import listDropdownSolid from "../../IconsSidebar/list-dropdown-solid.svg";
import buildingsLight from "../../IconsSidebar/buildings-light.svg";
import buildingsSolid from "../../IconsSidebar/buildings-solid.svg";
import partnersIconFilled from "../../IconsSidebar/partners-icon-filled.svg";
import partnersIconOutlined from "../../IconsSidebar/partnersIconOutlined.svg";
import userGroupLight from "../../IconsSidebar/user-group-light.svg";
import userGroupSolid from "../../IconsSidebar/user-group-solid.svg";
import squareListLight from "../../IconsSidebar/square-list-light.svg";
import squareListSolid from "../../IconsSidebar/square-list-solid.svg";
import chartLineLight from "../../IconsSidebar/chart-line-light.svg";
import chartLineSolid from "../../IconsSidebar/chart-line-solid.svg";
import fileLinesLight from "../../IconsSidebar/file-lines-light.svg";
import fileLinesSolid from "../../IconsSidebar/file-lines-solid.svg";
import listTreeLight from "../../IconsSidebar/list-tree-light.svg";
import listTreeSolid from "../../IconsSidebar/list-tree-solid.svg";
import { NavLink, useLocation } from 'react-router-dom';
import { useAuthentication } from '../../Data/Authentication';

const drawerWidth = 110;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const listItems = [
    {
        name: 'Home',
        startsWithUrl: '/',
        icon: <svg width="27" height="24" viewBox="0 0 27 24"><use xlinkHref={`${homeLight}#home-light`}></use></svg>,
        iconSelected: <svg width="27" height="25" viewBox="0 0 27 25"><use xlinkHref={`${homeSolid}#home-solid`}></use></svg>,
        navigateUrl: "/",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'Group Requests',
        startsWithUrl: '/grouprequests',
        icon: <svg width="24" height="24" viewBox="0 0 24 24"><use xlinkHref={`${layerGroupLight}#layer-group-light`}></use></svg>,
        iconSelected: <svg width="24" height="24" viewBox="0 0 24 24"><use xlinkHref={`${layerGroupSolid}#layer-group-solid`}></use></svg>,
        navigateUrl: "/GroupRequests/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'EDI Transmissions',
        startsWithUrl: '/editransmissions',
        icon: <svg width="27" height="25" viewBox="0 0 27 25"><use xlinkHref={`${arrowDownUpAcrossLineLight}#arrow-down-up-across-line-light`}></use></svg>,
        iconSelected: <svg width="27" height="25" viewBox="0 0 27 25"><use xlinkHref={`${arrowDownUpAcrossLineSolid}#arrow-down-up-across-line-solid`}></use></svg>,
        navigateUrl: "/EDITransmissions/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'Form Transmissions',
        startsWithUrl: '/formstransmissions',
        icon: <svg width="21" height="24" viewBox="0 0 21 24"><use xlinkHref={`${filesLight}#files-light`}></use></svg>,
        iconSelected: <svg width="21" height="24" viewBox="0 0 21 24"><use xlinkHref={`${filesSolid}#files-solid`}></use></svg>,
        navigateUrl: "/FormsTransmissions/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'Feeds',
        startsWithUrl: '/feeds',
        icon: <svg width="22" height="21" viewBox="0 0 22 21"><use xlinkHref={`${feedLight}#feed-light`}></use></svg>,
        iconSelected: <svg width="21" height="22" viewBox="0 0 21 22"><use xlinkHref={`${feedSolid}#feed-solid`}></use></svg>,
        navigateUrl: "/Feeds/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'Connectivity Requests',
        startsWithUrl: '/connectivityrequests',
        icon: <svg width="24" height="24" viewBox="0 0 24 24"><use xlinkHref={`${layerPlusLight}#layer-plus-light`}></use></svg>,
        iconSelected: <svg width="24" height="25" viewBox="0 0 24 25"><use xlinkHref={`${layerPlusSolid}#layer-plus-solid`}></use></svg>,
        navigateUrl: "/ConnectivityRequests/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'Action Items',
        startsWithUrl: '/actionitems',
        icon: <svg width="24" height="21" viewBox="0 0 24 21"><use xlinkHref={`${listDropdownLight}#list-dropdown-light`}></use></svg>,
        iconSelected: <svg width="24" height="21" viewBox="0 0 24 21"><use xlinkHref={`${listDropdownSolid}#list-dropdown-solid`}></use></svg>,
        navigateUrl: "/ActionItems/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'OE Requests',
        startsWithUrl: '/oerequests',
        icon: <svg width="21" height="21" viewBox="0 0 21 21"><use xlinkHref={`${squareListLight}#square-list-light`}></use></svg>,
        iconSelected: <svg width="21" height="21" viewBox="0 0 21 21"><use xlinkHref={`${squareListSolid}#square-list-solid`}></use></svg>,
        navigateUrl: "/OERequests/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'Maps',
        startsWithUrl: '/maps',
        icon: <svg width="24" height="21" viewBox="0 0 24 21"><use xlinkHref={`${chartLineLight}#chart-line-light`}></use></svg>,
        iconSelected: <svg width="24" height="21" viewBox="0 0 24 21"><use xlinkHref={`${chartLineSolid}#chart-line-solid`}></use></svg>,
        navigateUrl: "/Maps/Dashboard",
        allowedRoles: [1, 7]
    },
    {
        name: 'Forms',
        startsWithUrl: '/forms',
        icon: <svg width="18" height="24" viewBox="0 0 18 24"><use xlinkHref={`${fileLinesLight}#file-lines-light`}></use></svg>,
        iconSelected: <svg width="18" height="24" viewBox="0 0 18 24"><use xlinkHref={`${fileLinesSolid}#file-lines-solid`}></use></svg>,
        navigateUrl: "/Forms/Dashboard",
        allowedRoles: [1, 7]
    },
    {
        name: 'Structures',
        startsWithUrl: '/structures',
        icon: <svg width="24" height="20" viewBox="0 0 24 20"><use xlinkHref={`${listTreeLight}#list-tree-light`}></use></svg>,
        iconSelected: <svg width="24" height="20" viewBox="0 0 24 20"><use xlinkHref={`${listTreeSolid}#list-tree-solid`}></use></svg>,
        navigateUrl: "/Structures/Dashboard",
        allowedRoles: [1, 7]
    },
    {
        name: 'Carriers',
        startsWithUrl: '/carriers',
        icon: <svg width="24" height="24" viewBox="0 0 24 24"><use xlinkHref={`${buildingsLight}#buildings-light`}></use></svg>,
        iconSelected: <svg width="24" height="24" viewBox="0 0 24 24"><use xlinkHref={`${buildingsSolid}#buildings-solid`}></use></svg>,
        navigateUrl: "/Carriers/Dashboard",
        allowedRoles: [1, 2, 3, 4, 5, 7, 8]
    },
    {
        name: 'Partners',
        startsWithUrl: '/partners',
        icon: <svg width="31" height="25" viewBox="0 0 31 25"><use xlinkHref={`${partnersIconOutlined}#partnersIconOutlined`}></use></svg>,
        iconSelected: <svg width="26" height="20" viewBox="0 0 26 20"><use xlinkHref={`${partnersIconFilled}#partners-icon-filled`}></use></svg>,
        navigateUrl: "/Partners/Dashboard",
        allowedRoles: [1, 2, 4, 7, 11]
    },
    {
        name: 'Users Management',
        startsWithUrl: '/users',
        icon: <svg width="30" height="24" viewBox="0 0 30 24"><use xlinkHref={`${userGroupLight}#user-group-light`}></use></svg>,
        iconSelected: <svg width="30" height="24" viewBox="0 0 30 24"><use xlinkHref={`${userGroupSolid}#user-group-solid`}></use></svg>,
        navigateUrl: "/Users/Dashboard",
        allowedRoles: [2, 3, 4, 5, 7, 8]
    }
];

function Sidebar({ open, onOpen }) {

    const theme = useTheme();
    const { pathname } = useLocation();
    const { authUser } = useAuthentication();

    const userRoles = authUser?.signInUserSession?.idToken?.payload["userRoles"]?.slice(0, -1).split(",");

    const handleDrawerClose = () => {
        onOpen(false);
    };

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader className='drawer-header'>
                <IconButton onClick={handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            </DrawerHeader>
            <Divider />
            <List className='sidebar'>
                {listItems.map((item, index) => {
                    const isSelected = (pathname === "/" && item.name === "Home") ||
                        (pathname !== "/" && item.name !== "Home" && pathname.toLowerCase().startsWith(item.startsWithUrl.toLowerCase()) &&
                            (pathname.length === item.startsWithUrl.length || pathname[item.startsWithUrl.length] === '/'));

                    return userRoles.some(u => item.allowedRoles.includes(parseInt(u))) && (
                        <ListItem
                            style={{ background: '#f3f3f3' }}
                            key={index}
                            disablePadding
                            sx={{ display: 'block' }}
                        >
                            <Tooltip title={!open ? item.name : ''} placement="right">
                                <ListItemButton
                                    component={NavLink}
                                    to={item.navigateUrl}
                                    exact
                                    selected={isSelected}
                                    className='list-item-btn'
                                    activeClassName="Mui-selected"
                                    style={open ? { paddingLeft: '10px', paddingRight: '10px' } : {}}
                                    sx={{
                                        whiteSpace: open ? 'pre-wrap' : 'unset',
                                        display: open ? 'grid' : '',
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        style={{ margin: 'auto', position: 'relative' }}
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {isSelected ? item.iconSelected : item.icon}
                                    </ListItemIcon>
                                    <ListItemText className={open ? 'list-item-text' : ''} primary={item.name} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </Tooltip>
                        </ListItem>
                    );
                })}
            </List>
        </Drawer>
    );
}

export default Sidebar;
